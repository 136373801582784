import React from "react";
import Chart from "react-apexcharts";
import styled from "styled-components/macro";

export default function SingleChart(props) {
	const state = {
		series: [
			{
				name: props.name.deviceName,
				data: props.chartData.data,
			},
		],
		options: {
			chart: {
				id: props.chartData.name,
				height: 350,
				type: "line",
			},
			colors: ["#2503CA"],
			stroke: {
				width: 7,
				curve: "smooth",
			},
			tooltip: {
				x: {
					format: "dd MMM yyyy HH:mm",
				},
				y: {
					formatter: function (val) {
						return val + props.chartData.sing;
					},
				},
			},
			xaxis: {
				type: "datetime",
				categories: props.date,
				labels: { datetimeUTC: false },
			},
			title: {
				text: props.chartData.name,
				align: "left",
				style: {
					fontSize: "16px",
					color: "#666",
				},
			},
			fill: {
				type:
					props.chartData.data.every((val) => val == props.chartData.data[0]) == true
						? "straight"
						: "gradient",
				gradient: {
					shade: "dark",
					shadeIntensity: 1,
					type: "vertical",
					opacityFrom: 0.1,
					opacityTo: 0.9,
					colorStops: [
						{
							offset: 0,
							color: "#E60606",
							opacity: 1,
						},
						{
							offset: 20,
							color: "#FAD375",
							opacity: 1,
						},
						{
							offset: 60,
							color: "#61DBC3",
							opacity: 1,
						},
						{
							offset: 100,
							color: "#2503CA",
							opacity: 1,
						},
					],
				},
			},
			markers: {
				size: 4,
				colors: ["#FFA41B"],
				strokeColors: "#fff",
				strokeWidth: 2,
				hover: {
					size: 7,
				},
			},
			yaxis: {
				min: props.ymin,
				max: props.ymax,
			},
		},
	};

	const stateBrush = {
		series: [
			{
				data: props.chartData.data,
			},
		],
		options: {
			chart: {
				height: 130,
				type: "area",
				brush: {
					target: props.chartData.name,
					autoScaleYaxis: true,
					enabled: true,
				},
				selection: {
					xaxis: {
						min: props.date[0],
						max: props.date[props.date.length - 1],
					},
				},
			},
		},
	};

	return <ChartStyle options={state.options} series={state.series} type="line" height={350} />;
}

const ChartStyle = styled(Chart)`
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 2px 1px -1px rgba(0, 0, 0, 0.12);
	margin: 20px 0 20px;
	padding: 20px;
	text {
		font-family: "Source Sans Pro" !important;
	}

	@media (max-width: 576px) {
		padding: 20px 0;
	}
`;
