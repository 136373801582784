import React, { useState } from "react";
import styled, { withTheme } from "styled-components/macro";
import { Switch } from "@material-ui/core";
import { WbSunnyOutlined, Brightness2Outlined } from "@material-ui/icons";
import { colors } from "assets/config/theme";

function ThemedSwitcher(props) {
	const [themeSwitchState, setThemeSwitchState] = useState(props.theme.activeTheme.name === "light" ? true : false);

	const switchTheme = (event) => {
		setThemeSwitchState(!themeSwitchState);
		props.theme.toggleTheme();
	};

	return (
		<div className="d-flex align-items-center mr-2">
			<MyBrightness2Outlined />
			<MySwitch checked={themeSwitchState} onChange={switchTheme} />
			<MyWbSunnyOutlined />
		</div>
	);
}

let MyWbSunnyOutlined = styled(WbSunnyOutlined)`
	color: ${colors.action};
`;

let MyBrightness2Outlined = styled(Brightness2Outlined)`
	color: ${colors.actionPrimaryLight};
`;

let MySwitch = styled((props) => (
	<Switch
		classes={{
			switchBase: "switchBase",
			thumb: "thumb",
			checked: "checked",
			track: "track",
		}}
		{...props}
	/>
))`
	& .switchBase {
		color: ${colors.actionPrimaryLight};

		&.checked {
			color: ${colors.action};
		}
		&.checked + .track {
			background-color: ${colors.action};
		}
	}
`;
export const ThemeSwitcher = withTheme(ThemedSwitcher);
