class Store {
	constructor() {
		this.state = {};
		this.dispatch = () => {};
	}

	getStore() {
		return [this.state, this.dispatch];
	}

	setState(state) {
		this.state = state;
	}

	setDispatch(dispatch) {
		this.dispatch = dispatch;
	}
}

export let globalStore = new Store();
