import React from "react";
import styled from "styled-components/macro";
import { IconButton } from "@material-ui/core";
import { constants } from "assets/config/constants";
import { globalStore } from "global/store";
import actionTypes from "global/actionTypes";

/**
 *
 * @param {{menuState:number, abracadabra:string}} props - receives tratratrat
 */
export default function BurgerMenu(props) {
	const toggleMenu = () => {
		// setTimeout(() => {
		// 	console.log("object");
		// 	changeUrl(`${props.location.pathname}`, props.history)();
		// }, 200);
		props.menuState == constants.menuState.toggled
			? globalStore.dispatch({ type: actionTypes.openMenu })
			: globalStore.dispatch({ type: actionTypes.closeMenu });
	};

	return (
		<IconButton className="text-white" aria-label="Menu" onClick={toggleMenu}>
			<MenuHamburger className={props.menuState == constants.menuState.open ? "open" : null}>
				<div className="hamburger-box">
					<div className="hamburger-inner" />
				</div>
			</MenuHamburger>
		</IconButton>
	);
}

let MenuHamburger = styled("div")`
	& .hamburger-box {
		position: relative;
		/* display: inline-block; */
		width: 30px;
		height: 25px;
	}

	& .hamburger-inner,
	& .hamburger-inner:after,
	& .hamburger-inner:before {
		position: absolute;
		width: 30px;
		height: 4px;
		transition-timing-function: ease;
		transition-duration: 0.55s;
		transition-property: transform;
		border-radius: 4px;
		background-color: ${(prop) => prop.theme.activeTheme.IconButton.color};
	}

	& .hamburger-inner {
		top: 50%;
		margin-top: -2px;
	}

	& .hamburger-inner:after,
	& .hamburger-inner:before {
		display: block;
		content: "";
	}

	& .hamburger-inner:before {
		top: -8px;
	}

	&.open .hamburger-inner::before {
		transform: translate3d(-7px, 2px, 0) rotate(-45deg) scaleX(0.6);
	}

	& .hamburger-inner:after {
		bottom: -8px;
	}

	&.open .hamburger-inner::after {
		transform: translate3d(-7px, -2px, 0) rotate(45deg) scaleX(0.6);
	}
`;
