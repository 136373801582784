import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CardActions, CardContent, FormControl, CircularProgress, useTheme } from "@material-ui/core";
import { MyButton } from "components/Functional/material-ui-styled-Components";
import { MyCard, MyCardHeader } from "components/Functional/material-ui-styled-Components";
import { useSnackbar } from "notistack";
import { URLs } from "assets/config/config";
import NoDataFound from "components/pages/common/NoDataFound";
import Forecast from "./Forecast";
import Autocomplete from "react-autocomplete";

export default function ClimatePage() {
	const [t, i18n] = useTranslation(["climate page"]);
	const { enqueueSnackbar } = useSnackbar();
	const [tokenLoading, setTokenLoading] = useState(false);
	const [locationLoading, setLocationLoading] = useState(false);
	const [forecastLoading, setForecastLoading] = useState(false);
	const [autoCompleteLoading, setAutoCompleteLoading] = useState(false);
	const [inputValue, setInputValue] = useState("Baku");
	const [token, setToken] = useState("");
	const [selectedCityName, setSelectedCityName] = useState("");
	const [locations, setLocations] = useState([]);
	const [dailyForecast, setDailyForecast] = useState([]);
	const [hourlyForecast, setHourlyForecast] = useState([]);
	const [cityNameList, setCityNameList] = useState([]);
	const theme = useTheme();

	const handleInputValue = (e) => {
		const value = e.target.value;
		setInputValue(value);
	};

	const getToken = useCallback(() => {
		setTokenLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
				"Content-type": "application/json",
			},
		};

		fetch(URLs.main + `Weather/GetToken`, requestOptions)
			.then(async (response) => {
				const data = await response.json();
				setTokenLoading(false);
				setToken(data.value.access_token);
			})
			.catch((error) => {
				setTokenLoading(false);
				enqueueSnackbar(error.message, { variant: "error" });
				console.log("Request failed", error);
			});
	}, []);

	const getLocation = useCallback(() => {
		setLocationLoading(true);

		const requestOptions = {
			method: "GET",
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		};

		fetch(`https://pfa.foreca.com/api/v1/location/search/${inputValue}`, requestOptions)
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				setLocationLoading(false);
				setLocations(data.locations);
			})
			.catch((error) => {
				setLocationLoading(false);
				enqueueSnackbar(error.message, { variant: "error" });
				console.log("Request failed", error);
			});
	}, [token, inputValue]);

	const getDailyForecast = useCallback(() => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		};

		fetch(`https://pfa.foreca.com/api/v1/forecast/daily/${locations[0].id}?periods=14&dataset=full`, requestOptions)
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				setDailyForecast(data.forecast);
			})
			.catch((error) => {
				enqueueSnackbar(error.message, { variant: "error" });
				console.log("Request failed", error);
			});
	}, [token, locations]);

	const getHourlyForecast = useCallback(() => {
		setForecastLoading(true);

		const requestOptions = {
			method: "GET",
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		};

		fetch(`https://pfa.foreca.com/api/v1/forecast/hourly/${locations[0].id}?dataset=full`, requestOptions)
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				setForecastLoading(false);
				setHourlyForecast(data.forecast);
			})
			.catch((error) => {
				setForecastLoading(false);
				enqueueSnackbar(error.message, { variant: "error" });
				console.log("Request failed", error);
			});
	}, [token, locations]);

	const sendButtonClicked = useCallback(() => {
		getLocation();
	}, [inputValue, token]);

	useEffect(() => {
		getToken();
	}, []);

	useEffect(() => {
		if (token) {
			getLocation();
		}
	}, [token]);

	useEffect(() => {
		if (locations.length > 0) {
			getHourlyForecast();
		}
	}, [locations]);

	useEffect(() => {
		if (hourlyForecast.length > 0) {
			const timer = setTimeout(() => {
				getDailyForecast();
			}, 600);
			return () => clearTimeout(timer);
		}
	}, [hourlyForecast]);

	// Autocomplete
	const getCityNames = useCallback(() => {
		setLocationLoading(true);

		const requestOptions = {
			method: "GET",
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		};

		fetch(`https://pfa.foreca.com/api/v1/location/search/${inputValue}`, requestOptions)
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				setLocationLoading(false);
				const tempData = data.locations.filter((item, index) => index < 4);
				setCityNameList(tempData);
			})
			.catch((error) => {
				setLocationLoading(false);
				enqueueSnackbar(error.message, { variant: "error" });
				console.log("Request failed", error);
			});
	}, [token, inputValue]);

	const styles = {
		borderRadius: "none",
		height: "40px",
		width: "100%",
		padding: 0,
		margin: 0,
		paddingLeft: "10px",
		paddingTop: "8px",
		fontSize: "16px",
	};

	const inputProps = {
		style: {
			width: "100%",
			height: "55px",
			paddingLeft: "0.75rem",
			border: "none",
			borderBottom: `2px solid #66BB6A`,
			borderRadius: "15px 15px 0 0",
			outline: "none",
			boxShadow: "none",
			color: "black",
			overflow: "none",
		},
		placeholder: `${t("climate page:Enter city name")}`,
	};

	const getItemValue = (item) => {
		return `${item.name}`;
	};

	const renderItem = (item, isHighlighted) => {
		return (
			<div
				keys={item.id}
				style={{
					color: isHighlighted ? "white" : "#66BB6A",
					backgroundColor: isHighlighted ? "#66BB6A" : "white",
					...styles,
				}}
			>
				{item.name}
			</div>
		);
	};

	const onSelect = (val) => {
		setInputValue(val);
		setSelectedCityName(val);
	};

	useEffect(() => {
		if (selectedCityName) {
			getLocation();
		}
	}, [selectedCityName]);

	useEffect(() => {
		setAutoCompleteLoading(true);
		if (token && inputValue.length <= 2) {
			setCityNameList([]);
			setAutoCompleteLoading(false);
		}
		if (token && inputValue.length > 2) {
			const timer = setTimeout(() => {
				setCityNameList([]);
				getCityNames();
				setAutoCompleteLoading(false);
			}, 2000);
			return () => clearTimeout(timer);
		}
	}, [inputValue]);

	return (
		<>
			<MyCard className="mb-3" wide="100%">
				<MyCardHeader title={t("climate page:Weather information")} />
				<div className="d-flex align-items-start">
					<CardContent className="pt-0 flex-grow-1">
						<FormControl className="w-50">
							<Autocomplete
								onChange={handleInputValue}
								value={inputValue}
								getItemValue={getItemValue}
								items={cityNameList}
								renderItem={renderItem}
								onSelect={onSelect}
								inputProps={inputProps}
							/>
						</FormControl>
					</CardContent>

					<CardActions className="pr-3">
						<MyButton
							id="search-btn"
							variant="contained"
							component="span"
							onClick={sendButtonClicked}
							disabled={
								!inputValue || tokenLoading || locationLoading || forecastLoading || autoCompleteLoading
							}
						>
							{t("climate page:Search")}
						</MyButton>
					</CardActions>
				</div>
			</MyCard>
			{tokenLoading ? (
				<div className="d-flex h-50 justify-content-center align-items-center">
					<CircularProgress size="5rem" />
				</div>
			) : token ? (
				locationLoading ? (
					<div className="d-flex h-50 justify-content-center align-items-center">
						<CircularProgress size="5rem" />
					</div>
				) : locations.length > 0 ? (
					forecastLoading ? (
						<div className="d-flex h-50 justify-content-center align-items-center">
							<CircularProgress size="5rem" />
						</div>
					) : (
						<Forecast
							location={locations[0]}
							dailyForecast={dailyForecast}
							hourlyForecast={hourlyForecast}
						/>
					)
				) : (
					<NoDataFound text={t("climate page:Location was not found")} />
				)
			) : (
				<NoDataFound text={t("login page:Something went error")} />
			)}
		</>
	);
}
