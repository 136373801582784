import React from "react";
import { DialogTitle, DialogContent } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { TransitionUp } from "components/pages/common/TransitionUp";
import { MyIconButton, MyDialog } from "components/Functional/material-ui-styled-Components";
import ReactHlsPlayer from "react-hls-player";

const CameraDialog = (props) => {
	const [t] = useTranslation(["device page", "translation"]);

	const closeDialog = () => {
		props.setCameraDialogState((oldState) => {
			return { ...oldState, open: false };
		});
	};

	return (
		<MyDialog
			disableBackdropClick
			open={props.cameraDialogState.open}
			onClose={closeDialog}
			TransitionComponent={TransitionUp}
			aria-labelledby="camera"
			fullWidth
		>
			<DialogTitle id="settings">
				<div className="d-flex justify-content-between align-items-center">
					<span>
						{props.deviceData.deviceName} - {t("device page:Live stream")}
					</span>
					<MyIconButton aria-label="Close" onClick={closeDialog}>
						<Close />
					</MyIconButton>
				</div>
			</DialogTitle>
			<DialogContent>
				<Player />
			</DialogContent>
		</MyDialog>
	);
};

const Player = React.memo(function MyComponent(props) {
	return (
		<ReactHlsPlayer
			// src="https://www.aqro.iqlime.az/stream/camera1/index.m3u8"
			src="http://192.168.5.1:8083/stream/camera1/index.m3u8"
			// src="http://192.168.3.1:8083/stream/0b33abbc-ddbe-49cb-aefb-d1de18485433/index.m3u8"
			autoPlay={true}
			controls={true}
			width="100%"
			height="auto"
		/>
	);
});

export default React.memo(CameraDialog);
