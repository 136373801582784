import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { layout } from "assets/config/theme";
import { constants } from "assets/config/constants";
import { availablePages } from "assets/config/config";
import { globalStore } from "global/store";
import actionTypes from "global/actionTypes";
import logo from "assets/img/icons/iqlime_logo_grey.svg";
import { Box } from "@material-ui/core";
import logoKibrit from "assets/img/icons/Product_by_kibrit_logo.svg";

export default function MainMenuSidebar(props) {
	const [t, i18n] = useTranslation("menu");
	const [globalState, globalDispatch] = globalStore.getStore();

	const handleClick = (e) => {
		if (globalState.isFetching) e.preventDefault();
		globalStore.dispatch({ type: actionTypes.closeMenu });
	};

	return (
		<Nav className={props.menuState == constants.menuState.toggled ? "toggled" : null}>
			<ul className="d-flex flex-column align-items-start h-100 py-3 p-0 m-0">
				<>
					<StyledNavLink exact to={availablePages[0]} onClick={handleClick}>
						<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
							<path d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346625 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9937 5.88022 15.1488 3.84906 13.6499 2.35014C12.1509 0.851219 10.1198 0.00633091 8 0V0ZM8 10C7.60444 10 7.21776 9.8827 6.88886 9.66294C6.55997 9.44318 6.30362 9.13082 6.15224 8.76537C6.00087 8.39991 5.96126 7.99778 6.03843 7.60982C6.1156 7.22186 6.30609 6.86549 6.58579 6.58579C6.8655 6.30608 7.22186 6.1156 7.60982 6.03843C7.99779 5.96126 8.39992 6.00087 8.76537 6.15224C9.13082 6.30362 9.44318 6.55996 9.66294 6.88886C9.88271 7.21776 10 7.60444 10 8C9.99842 8.52995 9.7872 9.03773 9.41247 9.41247C9.03774 9.7872 8.52995 9.99842 8 10ZM8 2C8.90138 1.99646 9.79133 2.20183 10.6 2.6L9.044 4.156C8.36306 3.94899 7.63595 3.94899 6.955 4.156L5.4 2.6C6.20868 2.20183 7.09862 1.99646 8 2ZM2 8C1.99646 7.09862 2.20183 6.20868 2.6 5.4L4.156 6.956C3.94899 7.63695 3.94899 8.36405 4.156 9.045L2.6 10.6C2.20183 9.79132 1.99646 8.90138 2 8ZM8 14C7.09862 14.0035 6.20868 13.7982 5.4 13.4L6.956 11.844C7.63695 12.051 8.36406 12.051 9.045 11.844L10.6 13.4C9.79133 13.7982 8.90138 14.0035 8 14ZM13.4 10.6L11.844 9.044C12.051 8.36305 12.051 7.63595 11.844 6.955L13.4 5.4C13.7948 6.20986 14 7.09901 14 8C14 8.90098 13.7948 9.79014 13.4 10.6Z" />
						</svg>

						<span>{t("Devices")}</span>
					</StyledNavLink>
					{/* <StyledNavLink to={availablePages[1]} onClick={handleClick}>
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
							<defs>
								<clipPath id="clip-path">
									<rect width="16" height="16" fill="none" />
								</clipPath>
							</defs>
							<g id="icon_dashboard" clip-path="url(#clip-path)">
								<path
									id="Union_8"
									data-name="Union 8"
									d="M13,16a.945.945,0,0,1-1-1V6a.945.945,0,0,1,1-1h2a.945.945,0,0,1,1,1v9a.945.945,0,0,1-1,1ZM7,16a.944.944,0,0,1-1-1V1A.945.945,0,0,1,7,0H9a.945.945,0,0,1,1,1V15a.944.944,0,0,1-1,1ZM1,16a.945.945,0,0,1-1-1V11a.945.945,0,0,1,1-1H3a.946.946,0,0,1,1,1v4a.945.945,0,0,1-1,1Z"
									transform="translate(0 0)"
								/>
							</g>
						</svg>
						<span>{t("Groups")}</span>
					</StyledNavLink> */}
					<StyledNavLink to={availablePages[2]} onClick={handleClick}>
						<svg
							id="icon_thermometer"
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							viewBox="0 0 16 16"
						>
							<path
								id="Path_409"
								data-name="Path 409"
								d="M16.345,5.615a.911.911,0,0,1,1.3,0,.936.936,0,0,1,0,1.313l-6.491,6.564a.91.91,0,0,1-1.3,0,.936.936,0,0,1,0-1.313Z"
								transform="translate(-4.416 -2.84)"
							/>
							<path
								id="Path_410"
								data-name="Path 410"
								d="M6.735,14.246a3.973,3.973,0,0,0,5-.505l5.617-5.617a3.972,3.972,0,0,0-5.617-5.617L6.118,8.124a3.973,3.973,0,0,0-.506,5L2.747,15.987a.794.794,0,0,0,1.123,1.123Zm3.876-1.629L16.228,7a2.383,2.383,0,0,0-3.37-3.37L7.241,9.247a2.383,2.383,0,0,0,3.37,3.37Z"
								transform="translate(-2.515 -1.343)"
								fill-rule="evenodd"
							/>
						</svg>
						<span>{t("Climate")}</span>
					</StyledNavLink>
					{/* <StyledNavLink to={availablePages[3]} onClick={handleClick}>
						<svg
							id="icon_Invoices"
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							viewBox="0 0 14 16"
						>
							<path
								id="Path_102"
								data-name="Path 102"
								d="M14,0H2A.945.945,0,0,0,1,1V16l3-2,2,2,2-2,2,2,2-2,3,2V1A.945.945,0,0,0,14,0ZM12,10H4V8h8Zm0-4H4V4h8Z"
								transform="translate(-1)"
							/>
						</svg>
						<span>{t("Payments")}</span>
					</StyledNavLink> */}
					<StyledNavLink to={availablePages[4]} onClick={handleClick}>
						<svg width="16" height="16" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M2.99999 5.99999V15H15V5.99999H2.99999ZM14 2.99999H16C16.1334 2.99197 16.267 3.01235 16.392 3.05976C16.5169 3.10718 16.6304 3.18057 16.7249 3.27508C16.8194 3.36958 16.8928 3.48306 16.9402 3.60802C16.9876 3.73298 17.008 3.86658 17 3.99999V16C17.008 16.1334 16.9876 16.267 16.9402 16.392C16.8928 16.5169 16.8194 16.6304 16.7249 16.7249C16.6304 16.8194 16.5169 16.8928 16.392 16.9402C16.267 16.9876 16.1334 17.008 16 17H1.99999C1.86658 17.008 1.73298 16.9876 1.60802 16.9402C1.48306 16.8928 1.36958 16.8194 1.27508 16.7249C1.18057 16.6304 1.10718 16.5169 1.05976 16.392C1.01235 16.267 0.991974 16.1334 0.999994 16V3.99999C0.991974 3.86658 1.01235 3.73298 1.05976 3.60802C1.10718 3.48306 1.18057 3.36958 1.27508 3.27508C1.36958 3.18057 1.48306 3.10718 1.60802 3.05976C1.73298 3.01235 1.86658 2.99197 1.99999 2.99999H3.99999V1.99999C3.99197 1.86658 4.01235 1.73298 4.05976 1.60802C4.10718 1.48306 4.18057 1.36958 4.27508 1.27508C4.36958 1.18057 4.48306 1.10718 4.60802 1.05976C4.73298 1.01235 4.86658 0.991974 4.99999 0.999994C5.13341 0.991974 5.267 1.01235 5.39196 1.05976C5.51692 1.10718 5.6304 1.18057 5.72491 1.27508C5.81942 1.36958 5.89281 1.48306 5.94022 1.60802C5.98764 1.73298 6.00801 1.86658 5.99999 1.99999V2.99999H12V1.99999C12 1.73478 12.1054 1.48042 12.2929 1.29289C12.4804 1.10535 12.7348 0.999994 13 0.999994C13.2652 0.999994 13.5196 1.10535 13.7071 1.29289C13.8946 1.48042 14 1.73478 14 1.99999V2.99999ZM13 13H11V11H13V13ZM9.99999 13H7.99999V11H9.99999V13ZM13 9.99999H11V7.99999H13V9.99999ZM9.99999 9.99999H7.99999V7.99999H9.99999V9.99999ZM6.99999 13H4.99999V11H6.99999V13Z"
							/>
						</svg>

						<span>{t("History")}</span>
					</StyledNavLink>
					<StyledNavLink to={availablePages[5]} onClick={handleClick}>
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12 16">
							<path
								id="Path_419"
								data-name="Path 419"
								d="M11.5,2H4V18H16V6.8ZM13,14.8H7V13.2h6Zm0-3.2H7V10h6Zm-2.25-4V3.2l4.125,4.4Z"
								transform="translate(-4 -2)"
							/>
						</svg>
						<span>{t("Howto")}</span>
					</StyledNavLink>
					<StyledNavLink to={availablePages[6]} onClick={handleClick}>
						<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
							<path d="M10 13H5L6.828 11H13C13.2652 11 13.5196 10.8946 13.7071 10.7071C13.8946 10.5196 14 10.2652 14 10V4H15C15.2652 4 15.5196 4.10537 15.7071 4.29291C15.8946 4.48044 16 4.73478 16 5V12C16 12.2652 15.8946 12.5196 15.7071 12.7071C15.5196 12.8946 15.2652 13 15 13H13V16L10 13ZM3 9H1C0.734784 9 0.48043 8.89463 0.292893 8.70709C0.105357 8.51956 0 8.26522 0 8V1C0 0.734784 0.105357 0.480444 0.292893 0.292908C0.48043 0.105371 0.734784 0 1 0H11C11.2652 0 11.5196 0.105371 11.7071 0.292908C11.8946 0.480444 12 0.734784 12 1V8C12 8.26522 11.8946 8.51956 11.7071 8.70709C11.5196 8.89463 11.2652 9 11 9H6L3 12V9Z" />
						</svg>

						<span>{t("Contacts")}</span>
					</StyledNavLink>
				</>
				<SpanStyle>
					<a rel="noopener noreferrer" target="_blank" href="https://kibrit.tech/">
						<img style={{ width: "120%" }} src={logoKibrit} alt="Logo Kibrit" />
					</a>
				</SpanStyle>
			</ul>
		</Nav>
	);
}

let Nav = styled("nav")`
	display: flex;
	flex-direction: column;
	position: fixed;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 9998;
	width: ${layout.mainMenu.widthOpen};
	height: 100%;
	padding-top: ${layout.header.height};
	background: ${(props) => props.theme.activeTheme.menu.backgroundColor};
	border-right: ${(prop) => prop.theme.activeTheme.menu.borderRight};
	transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

	&.toggled {
		transform: translateX(-${layout.mainMenu.widthToggled});
		@media (max-width: 768px) {
			transform: translateX(-${layout.mainMenu.widthOpen});
		}
	}

	& > ul,
	& span {
		transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	}

	& span {
		margin-left: 1rem;
	}

	&.toggled > ul,
	&.toggled span {
		transform: translateX(${layout.mainMenu.widthToggled});
		@media (max-width: 768px) {
			transform: translateX(${layout.mainMenu.widthOpen});
		}
	}

	& a {
		text-decoration: none;
	}
`;

const StyledNavLink = styled(NavLink)`
	height: 3rem;
	display: flex;
	align-items: center;
	font-size: 0.94rem;
	color: ${(prop) => prop.theme.activeTheme.NavLink.color};
	fill: ${(prop) => prop.theme.activeTheme.NavLink.color};
	border-left: 4px solid transparent;
	padding: 0.3rem 1rem;
	/* & svg {
		width: 1rem !important;
		height: 1rem !important;
	} */
	&:hover {
		color: ${(prop) => prop.theme.activeTheme.NavLink.activeColor};
		fill: ${(prop) => prop.theme.activeTheme.NavLink.activeColor};
	}
	&.active {
		color: ${(prop) => prop.theme.activeTheme.NavLink.activeColor};
		fill: ${(prop) => prop.theme.activeTheme.NavLink.activeColor};
		border-left: 4px solid ${(prop) => prop.theme.activeTheme.NavLink.activeBorderLeft};
	}
`;

let SpanStyle = styled("span")`
	width: 100px;
	margin-top: auto;
	&:hover {
		text-decoration: none;
	}
	img {
		width: 100%;
	}
`;
