import { yellow } from "@material-ui/core/colors";

export const colors = {
	black: "black",
	blackOpacity50: "#00000050",
	blackOpacity40: "#00000040",
	darkPrimary: "#3a4149",
	darkSecondary: "#2f353a",
	blackTransparent: "#00000075",

	grayLight: "lightgray",
	grayLightMain: "#ebebeb",
	grayLightSecond: "#E9E9F0",
	grayLightThird: "#4d4f5c80",
	grayLightFourth: "#969696",
	grayLightFifth: "#BCBCCB",
	grayLightSixth: "#4d4f5c",
	grayLightSeventh: "#A3A6B4",
	grayLightNineth: "#F1F1F3",
	gray: "#ffffff80",
	grayMain: "#808495",
	grayDisable: "#CECFD0",
	grayDark: "#8a8a8a",

	green: "#66BB6A",
	greenPressed: "#489F4C",

	actionPrimaryLight: "#12a4f5",
	actionPrimary: "#1265f5",
	actionPrimaryDark: "#082bcc",

	actionLight: "#f7ad00",
	action: "#e67a07",
	actionDark: "#c97100",

	info: "yellowgreen",
	infoDark: "#8ec13f",

	warning: "#FF4E4E",

	yellow: "yellow",
	pink: "#f50057",

	white: "white",
	red: "#FF4141",
	redDark: "ba000d",
	transparent: "transparent",
	orange: "#ffc06a",
	blue: "#3B86FF",
};

export const layout = {
	header: {
		height: "4rem",
	},
	mainMenu: {
		widthOpen: "13rem",
		widthToggled: "9rem",
	},
	logo: {
		headerLogoHeight: "50px",
	},
};

const themes = {
	light: {
		name: "light",
		body: {
			backgroundColor: colors.white,
		},
		header: {
			backgroundColor: colors.white,
			borderBottom: `1px solid ${colors.grayLightMain}`,
		},
		menu: {
			backgroundColor: colors.white,
			borderRight: `1px solid ${colors.grayLightMain}`,
		},
		main: {
			backgroundColor: colors.grayLightMain,
		},
		NavLink: {
			color: colors.grayMain,
			activeColor: colors.green,
			hoveredBackgroundColor: colors.white,
			activeBorderLeft: colors.green,
			borderLeft: colors.darkPrimary,
		},
		Tabs: {
			backgroundColor: colors.warning,
		},
		Tab: {
			color: colors.white,
		},
		Box: {
			border: "1px solid " + colors.grayLightMain,
			backgroundColor: colors.white,
		},
		Card: {
			backgroundColor: colors.darkPrimary,
		},
		CardHeader: {
			titleColor: colors.grayLightSixth,
			subheaderColor: colors.grayLightThird,
			avatar: colors.grayMain,
		},
		InputLabel: {
			rootColor: colors.black,
			focusedColor: colors.green,
			disabledColor: colors.grayDark,
			errorColor: colors.warning,
		},
		FilledInput: {
			rootColor: colors.black,
			rootBackgroundColor: colors.transparent,
			errorColor: colors.warning,
			disabledColor: colors.grayDark,
			underlineColor: colors.green,
			underlineColorDefault: colors.grayLightSecond,
		},
		Fab: {
			primaryColor: colors.blue,
			hoveredPrimaryColor: colors.actionPrimaryDark,
			disabledBackgroundColor: colors.grayDisable,
			disabledColor: colors.white,
		},
		IconButton: {
			color: colors.grayMain,
			colorHovered: colors.actionDark,
			primaryColor: colors.actionPrimary,
			primaryColorHovered: colors.actionPrimaryDark,
			// dangerColor: colors.pink,
			// colorHovered: colors.actionDark,
		},
		Button: {
			actionColor: colors.green,
			color: colors.white,
			backgroundColor: colors.green,
			actionBackgroundColor: colors.white,
			disabledBackgroundColor: colors.grayDisable,
			hoveredBackgroundColor: colors.greenPressed,
			hoveredActionBackgroundColor: colors.green,
		},
		Tooltip: {
			backgroundColor: colors.white,
			textColor: colors.grayMain,
		},
		InfoName: {
			textColor: colors.grayDark,
		},
		TextDiv: {
			textColor: colors.white,
			textColorAlter: colors.black,
		},
		Avatar: {
			backgroundColor: colors.grayLightFifth,
		},
		Divider: {
			bgColor: colors.grayLightFifth,
		},
		CircularProgress: {
			color: colors.actionPrimary,
		},
		MenuList: {
			color: colors.grayMain,
			hoveredColor: colors.grayMain,
		},
		SelectArrowIcon: {
			color: colors.black,
			inverseColor: colors.white,
		},
		ListItemText: {
			titleColor: colors.white,
			subheaderColor: colors.grayDark,
		},
	},
	dark: {
		name: "dark",
		body: {
			backgroundColor: colors.darkSecondary,
		},
		header: {
			backgroundColor: colors.darkPrimary,
			borderBottom: "1px solid black",
		},
		menu: {
			backgroundColor: colors.darkPrimary,
			borderRight: "1px solid black",
		},
		main: {
			backgroundColor: colors.darkSecondary,
		},
		NavLink: {
			color: colors.grayLight,
			activeColor: colors.white,
			hoveredBackgroundColor: colors.darkSecondary,
			activeBorderLeft: colors.actionPrimary,
			borderLeft: colors.darkPrimary,
		},
		Tabs: {
			backgroundColor: colors.info,
		},
		Tab: {
			color: colors.white,
		},
		Box: {
			border: "2px solid" + colors.darkPrimary,
			backgroundColor: colors.darkPrimary,
		},
		Card: {
			backgroundColor: colors.darkPrimary,
		},
		CardHeader: {
			titleColor: colors.white,
			subheaderColor: colors.grayDark,
		},
		InputLabel: {
			rootColor: colors.grayLight,
			focusedColor: colors.action,
			disabledColor: colors.grayDark,
			errorColor: colors.warning,
		},
		FilledInput: {
			rootColor: colors.white,
			errorColor: colors.warning,
			disabledColor: colors.grayDark,
			underlineColor: colors.action,
		},
		Fab: {
			primaryColor: colors.actionPrimary,
			hoveredPrimaryColor: colors.actionPrimaryDark,
			disabledBackgroundColor: colors.darkSecondary,
			disabledColor: colors.grayDark,
		},
		IconButton: {
			color: colors.action,
			colorHovered: colors.actionDark,
			primaryColor: colors.actionPrimary,
			primaryColorHovered: colors.actionPrimaryDark,
		},
		Button: {
			actionColor: colors.action,
			color: colors.white,
			backgroundColor: colors.action,
			actionBackgroundColor: colors.info,
			hoveredBackgroundColor: colors.actionDark,
			hoveredActionBackgroundColor: colors.infoDark,
		},
		Tooltip: {
			backgroundColor: colors.blackTransparent,
			textColor: colors.white,
		},
		InfoName: {
			textColor: colors.grayDark,
		},
		TextDiv: {
			textColor: colors.white,
			textColorAlter: colors.black,
		},
		Avatar: {
			backgroundColor: colors.info,
		},
		Divider: {
			bgColor: colors.gray,
		},
		CircularProgress: {
			color: colors.actionPrimary,
		},
		MenuList: {
			color: colors.grayLight,
			hoveredColor: colors.white,
		},
		SelectArrowIcon: {
			color: colors.black,
			inverseColor: colors.white,
		},
		ListItemText: {
			titleColor: colors.white,
			subheaderColor: colors.grayDark,
		},
	},
};

export default themes;
