import React, { useState, useCallback, useEffect } from "react";
import { findIndex } from "lodash";
import { DialogTitle, DialogContent, DialogActions, Button, FormControl } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { TransitionUp } from "components/pages/common/TransitionUp";
import {
	MyIconButton,
	MyFilledInput,
	MyInputLabel,
	MyDialog,
	MyButton,
} from "components/Functional/material-ui-styled-Components";
import { fetchData } from "global/networking";
import { URLs } from "assets/config/config";
import actionTypes from "global/actionTypes";
import { useSnackbar } from "notistack";
import { globalStore } from "global/store";

const EditDialog = (props) => {
	const [t] = useTranslation(["device page", "translation"]);
	const [globalState, globalDispatch] = globalStore.getStore();
	const { enqueueSnackbar } = useSnackbar();
	const initialState = { deviceName: props.deviceData.deviceName };
	const [inputValue, setInputValue] = useState(initialState);
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);

	const closeDialog = () => {
		props.setEditDialogState(false);
	};

	useEffect(() => {
		setInputValue(initialState);
	}, [props.editDialogState]);

	const handleInputValue = (e) => {
		const value = e.target.value;
		setInputValue({
			[e.target.name]: value,
		});
		setIsButtonDisabled(false);
	};

	const editButtonClicked = useCallback(() => {
		const token = `Bearer ${localStorage.getItem("token")}`;
		const requestBody = {
			deviceId: props.deviceData.deviceId,
			isActive: true,
			deviceName: inputValue.deviceName,
			device: {
				pin: props.deviceData.pin,
			},
		};
		const promise = fetchData(URLs.main + "CusDevice/save", {
			method: "POST",
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
			body: JSON.stringify(requestBody),
		})[0];
		promise
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.success) {
					const updateIndex = findIndex(globalState.devicesData, { id: props.deviceData.id });
					const newDevicesData = [
						...globalState.devicesData.slice(0, updateIndex),
						{ ...props.deviceData, deviceName: inputValue.deviceName },
						...globalState.devicesData.slice(updateIndex + 1, globalState.devicesData.length),
					];
					globalDispatch({
						type: actionTypes.fetchDevices,
						payload: newDevicesData,
					});
					enqueueSnackbar(`${t("device page:Device name updated successfully")}`, { variant: "success" });
					closeDialog();
				} else {
					enqueueSnackbar(data.errors[0].errorMsg, { variant: "error" });
				}
			})
			.catch((error) => {
				enqueueSnackbar(error.message, { variant: "error" });
				console.log("Request failed", error);
			});
	}, [inputValue, globalState.devicesData]);

	return (
		<MyDialog
			disableBackdropClick
			open={props.editDialogState}
			onClose={closeDialog}
			TransitionComponent={TransitionUp}
			aria-labelledby="edit-device"
			fullWidth
		>
			<DialogTitle id="edit-device">
				<div className="d-flex justify-content-between align-items-center">
					<span>{t("translation:Edit")}</span>
					<MyIconButton aria-label="Close" onClick={closeDialog}>
						<Close />
					</MyIconButton>
				</div>
			</DialogTitle>
			<DialogContent>
				<FormControl className="w-100 mt-4">
					<MyInputLabel htmlFor="device-name">{t("device page:Device name")}</MyInputLabel>
					<MyFilledInput
						required
						id="device-name"
						type="text"
						name="deviceName"
						value={inputValue.deviceName}
						onChange={handleInputValue}
						className="text-dark"
					/>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<MyButton
					className="mr-3 mb-2"
					style={{ fontSize: "0.7rem" }}
					variant="contained"
					onClick={editButtonClicked}
					disabled={globalState.isFetching || isButtonDisabled}
				>
					{t("translation:Save")}
				</MyButton>
			</DialogActions>
		</MyDialog>
	);
};

export default React.memo(EditDialog);
