import React from "react";
import { MyCircularProgress } from "components/Functional/material-ui-styled-Components";

export default function LoadingComponent(props) {
	return (
		<div className={props.className}>
			<MyCircularProgress />
		</div>
	);
}
