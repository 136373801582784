import React, { useState, useEffect } from "react";
import { CircularProgress, Typography, Button } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { colors } from "assets/config/theme";
import styled from "styled-components/macro";
import { constants } from "assets/config/constants";

export default function PaymentHistory(props) {
	const [t, i18n] = useTranslation("translation, payment page");

	const tableOptions = {
		filter: true,
		responsive: "vertical",
		selectableRows: "none",
		serverSide: true,
		search: false,
		viewColumns: false,
		filter: false,
		download: false,
		print: false,
		rowsPerPage: 10,
		count: 0,
		page: 0,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					if (!props.isLoadingHistory) {
						props.fetchPaymentHistory(tableState.page + 1, tableState.rowsPerPage);
					}
					break;
				case "changeRowsPerPage":
					if (!props.isLoadingHistory) {
						props.fetchPaymentHistory(tableState.page + 1, tableState.rowsPerPage);
					}
					break;
				case "sort":
					if (!props.isLoadingHistory) {
						props.fetchPaymentHistory(tableState.page + 1, tableState.rowsPerPage, tableState.sortOrder);
					}
					break;
			}
		},
		onChangePage: (currentPage) => {
			setOptions((prevOptions) => {
				return {
					...prevOptions,
					page: currentPage,
				};
			});
		},
		setRowProps: (row, dataIndex) => {
			if (dataIndex % 2 === 0) {
				return {
					style: { background: colors.grayLightMain },
				};
			}
		},
	};

	const [options, setOptions] = useState(tableOptions);

	useEffect(() => {
		setOptions((prevOptions) => {
			return {
				...prevOptions,
				count: props.historyPageOptions.count,
			};
		});
	}, [props.historyPageOptions.count]);

	const tableColumns = [
		{
			name: "date",
			label: t("history page:Date"),
			options: {
				customBodyRender: (value, tableMeta, updateValue) => (
					<div>{moment(value).format("DD-MM-YYYY HH:mm")}</div>
				),
			},
		},
		{
			name: "amount",
			label: t("payment page:Amount"),
			options: {
				customBodyRender: (value, tableMeta, updateValue) => {
					return props.paymentHistory[tableMeta.rowIndex].type === 1 ? (
						<span className="text-success">{`+${value} AZN`}</span>
					) : (
						<span className="text-danger">{`-${value} AZN`}</span>
					);
				},
			},
		},
		{
			name: "type",
			label: t("payment page:Operation"),
			options: {
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return value === 1 ? (
						<span className="text-success">{t("payment page:Balance increase")}</span>
					) : (
						<span className="text-danger">{t("payment page:Monthly subscription renewal")}</span>
					);
				},
			},
		},
		{
			name: "transactionId",
			label: t("payment page:Transaction"),
			options: {
				sort: false,
			},
		},
		{
			name: "terminalSource",
			label: t("payment page:Information"),
			options: {
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					switch (props.paymentHistory[tableMeta.rowIndex].terminalSource) {
						case "EMN":
							return <div>eManat</div>;
							break;
						case "MLO":
							return <div>MilliÖN</div>;
							break;
						case "CIB":
							return <div>CİB</div>;
							break;
						case "EXP":
							return <div>ExpressPay</div>;
							break;
						case "PMN":
							return <div>Portmanat</div>;
							break;
						case "AZC":
							return <div>AzeriCard</div>;
							break;
						case "EPL":
							return <div>E-Pul</div>;
							break;
						case "KSM":
							return <div>KASSAM.AZ</div>;
							break;
						case "PBM":
							return <div>PASHA Bank Mobile</div>;
							break;
						case "BRP":
							return <div>BirBank</div>;
							break;
						default:
							return (
								<div>
									{`${t("payment page:Device")} ID ${
										props.paymentHistory[tableMeta.rowIndex].cusDeviceId
									}`}
								</div>
							);
					}
				},
			},
		},
	];

	return (
		<div className="mt-5">
			<MUIDataTableStyle
				className="mt-4 p-2 p-sm-4"
				title={
					<Typography style={{ fontSize: "1.2rem", color: colors.grayLightSixth }}>
						{t("payment page:Payment history")}
						{props.isLoadingHistory && (
							<CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
						)}
					</Typography>
				}
				data={props.paymentHistory}
				columns={tableColumns}
				options={options}
			/>
		</div>
	);
}

const MUIDataTableStyle = styled(MUIDataTable)`
	@media (max-width: ${constants.responsiveSizes.sm}px) {
		.MuiTableCell-root {
			padding: 9px;
		}
		div,
		span {
			font-size: 13px;
		}
	}
`;
