import React, { useState, useCallback } from "react";
import { DialogTitle, DialogContent, DialogActions, Button, FormControl } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { TransitionUp } from "components/pages/common/TransitionUp";
import {
	MyIconButton,
	MyFilledInput,
	MyInputLabel,
	MyDialog,
	MyButton,
} from "components/Functional/material-ui-styled-Components";
import { fetchData } from "global/networking";
import { URLs } from "assets/config/config";
import { useSnackbar } from "notistack";
import { globalStore } from "global/store";
import actionTypes from "global/actionTypes";

const defaultInputValues = {
	deviceName: "",
	deviceId: "",
	pin: "",
};

const AddDialog = (props) => {
	const [t] = useTranslation(["device page", "translation"]);
	const { enqueueSnackbar } = useSnackbar();
	const [inputValue, setInputValue] = React.useState(defaultInputValues);
	const [globalState, globalDispatch] = globalStore.getStore();

	const closeDialog = () => {
		setInputValue(defaultInputValues);
		props.setAddDialogState(false);
	};

	const handleInputValue = (e) => {
		const value = e.target.value;
		setInputValue({
			...inputValue,
			[e.target.name]: value,
		});
	};

	const addButtonClicked = useCallback(() => {
		const token = `Bearer ${localStorage.getItem("token")}`;

		const requestBody = {
			deviceId: inputValue.deviceId,
			deviceName: inputValue.deviceName,
			device: {
				pin: inputValue.pin,
			},
		};
		const promise = fetchData(URLs.main + "CusDevice/save", {
			method: "POST",
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
			body: JSON.stringify(requestBody),
		})[0];
		promise
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.success) {
					props.fetchDeviceList();
					enqueueSnackbar(`${t("device page:Device added successfully")}`, { variant: "success" });
					closeDialog();
				} else {
					switch (data.errors[0].error) {
						case "device_not_exist_by_this_pin_and_id":
							enqueueSnackbar(`${t("device page:The entered ID and PIN are not exist")}`, {
								variant: "error",
							});
							break;
						case "insufficient_balance":
							enqueueSnackbar(`${t("device page:Insufficient balance")}`, { variant: "error" });
							break;
						case "busy_device":
							enqueueSnackbar(`${t("device page:The entered ID and PIN are already used")}`, {
								variant: "error",
							});
							break;
						case "is_not_ascii":
							enqueueSnackbar(`${t("device page:Use only English letters")}`, { variant: "error" });
							break;
						default:
						// is not handled
					}
				}
			})
			.catch((error) => {
				enqueueSnackbar(error.message, { variant: "error" });
				console.log("Request failed", error);
			});
	}, [inputValue, globalState.devicesData]);

	return (
		<MyDialog
			disableBackdropClick
			open={props.addDialogState}
			onClose={closeDialog}
			TransitionComponent={TransitionUp}
			aria-labelledby="add-new-device"
			fullWidth
		>
			<DialogTitle id="add-new-device">
				<div className="d-flex justify-content-between align-items-center">
					<span>{t("device page:Add new device")}</span>
					<MyIconButton aria-label="Close" onClick={closeDialog}>
						<Close />
					</MyIconButton>
				</div>
			</DialogTitle>
			<DialogContent>
				<FormControl className="w-100 mt-4">
					<MyInputLabel htmlFor="device-name">{t("device page:Device name")}</MyInputLabel>
					<MyFilledInput
						required
						id="device-name"
						type="text"
						name="deviceName"
						value={inputValue.deviceName}
						onChange={handleInputValue}
						className="text-dark"
					/>
				</FormControl>
				<FormControl className="w-100 mt-4">
					<MyInputLabel htmlFor="device-id">{t("device page:Device id number")}</MyInputLabel>
					<MyFilledInput
						id="device-id"
						type="number"
						name="deviceId"
						value={inputValue.deviceId}
						onChange={handleInputValue}
						className="text-dark"
					/>
				</FormControl>
				<FormControl className="w-100 mt-4">
					<MyInputLabel htmlFor="device-pin">{t("device page:Device pin code")}</MyInputLabel>
					<MyFilledInput
						id="device-pin"
						type="text"
						name="pin"
						value={inputValue.pin}
						onChange={handleInputValue}
						className="text-dark"
					/>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<MyButton
					className="mr-5 mb-2"
					style={{ fontSize: "0.7rem" }}
					variant="contained"
					onClick={addButtonClicked}
				>
					{t("translation:Add")}
				</MyButton>
			</DialogActions>
		</MyDialog>
	);
};

export default React.memo(AddDialog);
