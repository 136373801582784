import React, { useState, useEffect } from "react";
import { Typography, TableCell, TableRow } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { constants } from "assets/config/constants";
import styled from "styled-components";
import { colors } from "assets/config/theme";

const SingleGroupCard = (props) => {
	const [t, i18n] = useTranslation(["translation", "groups page", "device page"]);
	const [checkDeviceReceivesInformation, setCheckDeviceReceivesInformation] = useState(true);

	const dangerRow = (tempStatus, humStatus) => {
		return tempStatus === 2 || humStatus === 2 ? "text-danger" : "";
	};

	const isDeviceReceivesInformation = () => {
		let TEN_MINUTE = 10 * 60 * 1000; /* ms */
		const aTenMinAgo = Date.now() - TEN_MINUTE;
		let lastSentDate = new Date(props.row.lastSentDate);
		setCheckDeviceReceivesInformation(lastSentDate > aTenMinAgo);
	};

	useEffect(() => {
		isDeviceReceivesInformation();
	}, []);

	return (
		<TableRow
			style={{
				backgroundColor: `${props.order % 2 === 0 ? colors.grayLightMain : null}`,
				height: document.body.clientWidth > constants.responsiveSizes.sm ? "4.8rem" : null,
			}}
		>
			<TableCellStyle className={`${dangerRow(props.row.temperaturStatus, props.row.humidityStatus)}`}>
				{props.row.deviceName}
			</TableCellStyle>
			<TableCellStyle className={`${dangerRow(props.row.temperaturStatus)}`}>
				{checkDeviceReceivesInformation || props.row.deactivatedDt != null
					? `${props.row.currTemperature}°C`
					: "-"}
			</TableCellStyle>
			<TableCellStyle className={`${dangerRow(props.row.humidityStatus)}`}>
				{checkDeviceReceivesInformation || props.row.deactivatedDt != null ? `${props.row.currHumidity}%` : "-"}
			</TableCellStyle>
			<TableCellStyle>
				{checkDeviceReceivesInformation || props.row.deactivatedDt != null ? (
					<>
						<Typography
							className={`${dangerRow(props.row.temperaturStatus)}`}
							variant="caption"
							display="block"
							gutterBottom
						>
							{props.row.temperaturStatus === 2
								? t("device page:Critical temperature")
								: t("device page:Normal temperature")}
							&nbsp;
						</Typography>
						<Typography
							className={`${dangerRow(props.row.humidityStatus)}`}
							variant="caption"
							display="block"
							gutterBottom
						>
							{props.row.humidityStatus === 2
								? t("device page:Critical humidity")
								: t("device page:Normal humidity")}
							&nbsp;
						</Typography>
					</>
				) : (
					t("device page:No information")
				)}
			</TableCellStyle>
		</TableRow>
	);
};

export default React.memo(SingleGroupCard);

const TableCellStyle = styled(TableCell)`
	@media (max-width: ${constants.responsiveSizes.sm}px) {
		padding-top: 5px;
		padding-bottom: 5px;
	}
`;
