import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { MyFab } from "components/Functional/material-ui-styled-Components";
import styled from "styled-components";
import { CircularProgress, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import NoDataFound from "components/pages/common/NoDataFound";
import { globalStore } from "global/store";
import { URLs } from "assets/config/config";
import { useSnackbar } from "notistack";
import actionTypes from "global/actionTypes";
import { fetchData } from "global/networking";
import GroupCard from "./GroupCard";
import AddGroupDialog from "./AddGroupDialog";
import Masonry from "react-masonry-css";
import { colors } from "assets/config/theme";

const GroupsPage = () => {
	const [t, i18n] = useTranslation("translation");
	const { enqueueSnackbar } = useSnackbar();
	const [addDialogState, setAddDialogState] = useState(false);
	const [globalState, globalDispatch] = globalStore.getStore();

	const openAddDialog = () => {
		setAddDialogState(true);
	};

	const fetchGroupList = useCallback(() => {
		const token = `Bearer ${localStorage.getItem("token")}`;
		const requestBody = {
			Pager: {
				CurrentPage: 1,
				PageSize: 100,
			},
		};
		const promise = fetchData(URLs.main + "group/GetCustomerGroups", {
			method: "POST",
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
			body: JSON.stringify(requestBody),
		})[0];
		promise
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.success) {
					globalDispatch({ type: actionTypes.fetchGroups, payload: data.list });
				} else {
					enqueueSnackbar(data.errors[0].errorMsg, { variant: "error" });
				}
			})
			.catch((error) => {
				enqueueSnackbar(error.message, { variant: "error" });
				console.log("Request failed", error);
			});
	}, []);

	useEffect(() => {
		fetchGroupList();
		let interval = setInterval(() => {
			fetchGroupList();
		}, 1000 * 60);
		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
		<>
			<Tooltip title={t("groups page:Add new group")}>
				<MyAddButton aria-label="add" onClick={openAddDialog}>
					<AddIcon />
				</MyAddButton>
			</Tooltip>

			<AddGroupDialog
				fetchGroupList={fetchGroupList}
				setAddDialogState={setAddDialogState}
				addDialogState={addDialogState}
			/>

			{globalState.isFetching ? (
				<div className="d-flex h-100 justify-content-center align-items-center">
					<CircularProgress size="5rem" />
				</div>
			) : globalState.groupsData.length > 0 ? (
				<Masonry
					breakpointCols={{
						default: 2,
						1200: 1,
					}}
					className="my-masonry-grid"
					columnClassName="my-masonry-grid_column"
				>
					{globalState.groupsData.map((item, key) => (
						<GroupCard key={key} groupData={item} fetchGroupList={fetchGroupList} />
					))}
				</Masonry>
			) : (
				<NoDataFound text={t("translation:No data was found")} />
			)}
		</>
	);
};

export default GroupsPage;

const MyAddButton = styled(MyFab)`
	position: fixed;
	bottom: 3rem;
	right: 3rem;
	z-index: 2;
	color: ${(prop) => (prop.theme.activeTheme.name === "dark" ? colors.black : colors.grayMain)};
	background-color: ${(prop) => (prop.theme.activeTheme.name === "dark" ? colors.white : colors.white)};
`;
