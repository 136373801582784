import {
	Battery30,
	Battery60,
	BatteryChargingFull,
	BatteryFull,
	SignalWifi1Bar,
	SignalWifi2Bar,
	SignalWifi3Bar,
	SignalWifi4Bar,
} from "@material-ui/icons";

import { constants } from "assets/config/constants";
import React from "react";
import { colors } from "assets/config/theme";
import { Box, Button, Menu, MenuItem, Tooltip } from "@material-ui/core";
import airTempHumIcon from "assets/img/icons/sensor.svg";
import soilHumIcon from "assets/img/icons/soilHumidity.svg";
import videoCameraIcon from "assets/img/icons/video-camera.svg";
import AlertGreyIcon from "assets/img/icons/icon_alert-circle_grey.svg";
import AlertRedIcon from "assets/img/icons/icon_alert-circle-exc.svg";
import { MyButton, MyIconButton } from "components/Functional/material-ui-styled-Components";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import CircleRemove from "assets/img/icons/circle-remove.svg";
import IconEdit from "assets/img/icons/icon_edit.svg";
import IconSetting from "assets/img/icons/icon_setting.svg";
import BatteryEmpty from "assets/img/icons/icon_battery_aler.svg";
import SignalWifiEmpty from "assets/img/icons/icon_wifi_empty.svg";

function SensorIcon({ sensorType }) {
	switch (sensorType) {
		case constants.sensorTypes.airTempHum:
			return <img src={airTempHumIcon} width="16" />;
		case constants.sensorTypes.camera:
			return <img src={videoCameraIcon} width="16" />;
		case constants.sensorTypes.soilHum:
			return <img src={soilHumIcon} width="22" />;
		default:
			return <img src={airTempHumIcon} width="16" />;
	}
}

export default function Sensor(props) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [t, i18n] = useTranslation();
	const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
	const [settingDialogState, setSettingDialogState] = React.useState(false);
	const [editDialogState, setEditDialogState] = React.useState(false);
	const [deleteDialogState, setDeleteDialogState] = React.useState(false);

	function sensorName(type) {
		switch (type) {
			case constants.sensorTypes.airTempHum:
				return t("sensors:Air sensor");
				break;
			case constants.sensorTypes.soilHum:
				return t("sensors:Soil sensor");
				break;
			default:
				return t("sensors:Camera");
		}
	}

	// right menu open
	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
		setIsButtonDisabled(true);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const openEditDialog = () => {
		setEditDialogState(true);
		handleMenuClose(false);
	};

	const openDeleteDialog = () => {
		setDeleteDialogState(true);
		handleMenuClose(false);
	};

	return (
		<Box borderBottom="1px solid #EFEFF4" display="flex" alignItems="center" mb={1.5} width="100%">
			<Box
				display={{ xs: "none", md: "block" }}
				fontSize="13px"
				width="25px"
				mx={1}
				textOverflow="ellipsis"
				overflow="hidden"
				whiteSpace="nowrap"
			>
				{props.sensor.humidityStatus == 2 || props.sensor.temperaturStatus == 2 ? (
					<img src={AlertRedIcon} width="16" />
				) : (
					<img src={AlertGreyIcon} width="16" />
				)}
			</Box>
			<Box
				fontSize="14px"
				width={{ xs: "75px", md: "120px" }}
				textOverflow="ellipsis"
				overflow="hidden"
				whiteSpace="nowrap"
			>
				{sensorName(props.sensor.type)}
			</Box>
			<Box width="20px" textAlign="center">
				<SensorIcon sensorType={props.sensor.type} />
			</Box>

			<Box
				display="flex"
				flex={1}
				justifyContent="space-evenly"
				alignItems="center"
				fontSize={{ xs: "16px", md: "20px" }}
			>
				{props.sensor.type !== constants.sensorTypes.camera ? (
					<>
						{props.sensor.type === constants.sensorTypes.airTempHum && (
							<Tooltip title={t("device page:Temperature")}>
								<Box
									fontWeight="700"
									color={props.sensor.temperaturStatus === 2 ? "error.main" : "primary.main"}
								>
									{props.sensor.currTemperature || typeof props.sensor.currTemperature === "number"
										? `${props.sensor.currTemperature}°C`
										: "No data"}
								</Box>
							</Tooltip>
						)}
						<Tooltip
							title={t(
								props?.sensor?.type === constants.sensorTypes.airTempHum
									? "device page:Humidity"
									: "device page:Soil Humidity"
							)}
						>
							<Box
								fontWeight="700"
								color={props.sensor.humidityStatus === 2 ? "error.main" : "primary.main"}
							>
								{props.sensor.currHumidity || typeof props.sensor.currTemperature === "number"
									? `${props.sensor.currHumidity}%`
									: "No data"}
							</Box>
						</Tooltip>
					</>
				) : (
					<MyButton variant="contained" color="primary" onClick={props.openCameraDialog}>
						<svg width="10" height="11" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M6.28754 3.25902C6.61062 3.45332 6.61061 3.92168 6.28754 4.11598L1.00769 7.29131C0.674437 7.49174 0.25 7.25171 0.25 6.86283L0.25 0.512166C0.25 0.123287 0.674437 -0.116735 1.00769 0.0836861L6.28754 3.25902Z"
								fill="white"
							/>
						</svg>
					</MyButton>
				)}
			</Box>
			<Box width={{ xs: "80px", md: "135px" }} display="flex" justifyContent="flex-end" fontSize="13px">
				{props?.sensor?.type !== constants.sensorTypes.camera && (
					<>
						<Box display="flex" justifyContent="center" alignItems="center" mr={1}>
							<Box display={{ xs: "none", md: "block" }}>
								{props.sensor.electricity === 101 ? "100%" : `${props.sensor.electricity}%`}
							</Box>
							<Box textAlign="center">
								{props.sensor.electricity === 101 ? (
									<BatteryChargingFull fontSize="small" style={{ color: colors.grayMain }} />
								) : props.sensor.electricity > 74 ? (
									<BatteryFull fontSize="small" style={{ color: colors.grayMain }} />
								) : props.sensor.electricity > 49 ? (
									<Battery60 fontSize="small" style={{ color: colors.grayMain }} />
								) : props.sensor.electricity > 10 ? (
									<Battery30 fontSize="small" style={{ color: colors.grayMain }} />
								) : (
									<img src={BatteryEmpty} alt="BatteryEmpty" className="mt-1" />
								)}
								<Box display={{ xs: "block", md: "none" }} fontSize="9px">
									{props.sensor.electricity === 101 ? "100%" : `${props.sensor.electricity}%`}
								</Box>
							</Box>
						</Box>
						<Box display="flex" justifyContent="center" alignItems="center">
							<Box display={{ xs: "none", md: "block" }}>
								{props.sensor.mobileSignal === 4
									? "100%"
									: props.sensor.mobileSignal === 3
									? "75%"
									: props.sensor.mobileSignal === 2
									? "50%"
									: props.sensor.mobileSignal === 1
									? "25%"
									: "0%"}
							</Box>
							<Box textAlign="center">
								{props.sensor.mobileSignal === 4 ? (
									<SignalWifi4Bar fontSize="small" style={{ color: colors.grayMain }} />
								) : props.sensor.mobileSignal === 3 ? (
									<SignalWifi3Bar fontSize="small" style={{ color: colors.grayMain }} />
								) : props.sensor.mobileSignal === 2 ? (
									<SignalWifi2Bar fontSize="small" style={{ color: colors.grayMain }} />
								) : props.sensor.mobileSignal === 1 ? (
									<SignalWifi1Bar fontSize="small" style={{ color: colors.grayMain }} />
								) : (
									<img src={SignalWifiEmpty} alt="No mobile signal" />
								)}
								<Box display={{ xs: "block", md: "none" }} fontSize="9px">
									{props.sensor.mobileSignal === 4
										? "100%"
										: props.sensor.mobileSignal === 3
										? "75%"
										: props.sensor.mobileSignal === 2
										? "50%"
										: props.sensor.mobileSignal === 1
										? "25%"
										: "0%"}
								</Box>
							</Box>
						</Box>
					</>
				)}
				<div className="">
					<MyIconButton aria-haspopup="true" onClick={handleMenuClick} className="p-1">
						<MoreVertIcon style={{ color: colors.orange }} />
					</MyIconButton>
				</div>
				<Menu
					id={`menu-${props.sensor.deviceId}`}
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleMenuClose}
					className="positionMenuItemForDeviceCard"
				>
					{props?.sensor?.type !== constants.sensorTypes.camera && (
						<MenuItemForDevice
							onClick={() => {
								props.openSensorSettingDialog();
								handleMenuClose();
							}}
						>
							<img src={IconSetting} />
							{t("translation:Settings")}
						</MenuItemForDevice>
					)}
					<MenuItemForDevice disabled onClick={openEditDialog}>
						<img src={IconEdit} />
						{t("translation:Edit")}
					</MenuItemForDevice>
					<MenuItemForDevice disabled onClick={openDeleteDialog}>
						<img src={CircleRemove} />
						{t("translation:Delete")}
					</MenuItemForDevice>
				</Menu>
			</Box>
		</Box>
	);
}

const Div = styled.div`
	width: 50px;
`;
const MenuItemForDevice = styled(MenuItem)`
	margin-left: 30px;
	margin-right: 30px;
	border-bottom: 1px solid ${colors.grayLightNineth};
	padding-top: 10px;
	padding-bottom: 10px;
	color: ${colors.grayLightSixth};
	:first-child {
		margin-top: 10px;
	}
	:last-child {
		margin-bottom: 10px;
		border-bottom: none;
	}
	img {
		margin-right: 10px;
	}
`;
