import React, { useState } from "react";
import { MyCard, MyIconButton } from "components/Functional/material-ui-styled-Components";
import styled from "styled-components";
import {
	CardContent,
	Menu,
	Typography,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CircleRemove from "assets/img/icons/circle-remove.svg";
import IconEdit from "assets/img/icons/icon_edit.svg";
import EditGroupDialog from "./EditGroupDialog";
import DeleteDialog from "./DeleteGroupDialog";
import SingleGroupCard from "./SingleGroupCard";
import { colors } from "assets/config/theme";

const GroupCard = (props) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [editDialogState, setEditDialogState] = useState(false);
	const [deleteDialogState, setDeleteDialogState] = useState(false);
	const [t, i18n] = useTranslation(["translation", "groups page", "device page"]);

	// right menu open
	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	// right menu close
	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	// delete dialog open
	const openDeleteDialog = () => {
		setDeleteDialogState(true);
		handleMenuClose(false);
	};

	// edit dialog open
	const openEditDialog = () => {
		setEditDialogState(true);
		handleMenuClose(false);
	};

	// average calculation
	let TEN_MINUTE = 10 * 60 * 1000; /* ms */
	const aTenMinAgo = Date.now() - TEN_MINUTE;

	const filterActiveDevice = props.groupData.cusDevices.filter((item) => new Date(item.lastSentDate) > aTenMinAgo);

	var avg = Array.from(
		filterActiveDevice.reduce(
			(acc, obj) =>
				Object.keys(obj).reduce(
					(acc, key) =>
						typeof obj[key] == "number" ? acc.set(key, (acc.get(key) || []).concat(obj[key])) : acc,
					acc
				),
			new Map()
		)
	).reduce(
		(acc, [name, values]) => Object.assign(acc, { [name]: values.reduce((a, b) => a + b) / values.length }),
		{}
	);

	return (
		<>
			<EditGroupDialog
				setEditDialogState={setEditDialogState}
				editDialogState={editDialogState}
				groupData={props.groupData}
				fetchGroupList={props.fetchGroupList}
			/>
			<DeleteDialog
				setDeleteDialogState={setDeleteDialogState}
				deleteDialogState={deleteDialogState}
				groupData={props.groupData}
			/>

			<MyCard wide="100%" style={{ overflow: "visible" }}>
				<div className="px-3 py-2 d-flex align-items-center justify-content-between">
					<h5 className="text-dark m-0">{props.groupData.name}</h5>
					<div>
						<MyIconButton aria-haspopup="true" onClick={handleMenuClick}>
							<MoreVertIcon />
						</MyIconButton>

						<Menu
							id={`menu-${props.groupData.id}`}
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={handleMenuClose}
							className="positionMenuItemForDeviceCard"
						>
							<MenuItemForDevice onClick={openEditDialog}>
								<img src={IconEdit} />
								{t("translation:Edit")}
							</MenuItemForDevice>
							<MenuItemForDevice onClick={openDeleteDialog}>
								<img src={CircleRemove} />
								{t("translation:Delete")}
							</MenuItemForDevice>
						</Menu>
					</div>
				</div>
				<CardContent className="pt-0 pb-0 flex-grow-1 d-flex flex-column">
					<TableContainer>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell
										style={{
											backgroundColor: colors.white,
											color: colors.grayLightSeventh,
										}}
									>
										{t("device page:Device name")}
									</TableCell>
									<TableCell
										style={{
											backgroundColor: colors.white,
											color: colors.grayLightSeventh,
											padding: "3px",
										}}
									>
										{t("device page:Temperature")}
									</TableCell>
									<TableCell
										style={{
											backgroundColor: colors.white,
											color: colors.grayLightSeventh,
											padding: "3px",
										}}
									>
										{t("device page:Humidity")}
									</TableCell>
									<TableCell
										style={{
											backgroundColor: colors.white,
											color: colors.grayLightSeventh,
										}}
									>
										{t("device page:Status")}
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{props.groupData.cusDevices.map((row, key) => (
									<SingleGroupCard key={key} row={row} order={key} />
								))}
							</TableBody>
						</Table>
					</TableContainer>

					<Typography
						variant="subtitle2"
						gutterBottom
						className="my-3 pt-2 pb-2"
						style={{ color: colors.blackOpacity40 }}
					>
						{t("groups page:Temperature Average")}:{" "}
						{avg.currTemperature ? avg.currTemperature.toFixed(2) : 0}°C{<br />}
						{t("groups page:Humidity Average")}: {avg.currHumidity ? avg.currHumidity.toFixed(2) : 0}%
					</Typography>
				</CardContent>
			</MyCard>
		</>
	);
};

const MenuItemForDevice = styled(MenuItem)`
	margin-left: 30px;
	margin-right: 30px;
	border-bottom: 1px solid ${colors.grayLightNineth};
	padding-top: 10px;
	padding-bottom: 10px;
	color: ${colors.grayLightSixth};
	:first-child {
		margin-top: 10px;
	}
	:last-child {
		margin-bottom: 10px;
		border-bottom: none;
	}
	img {
		margin-right: 10px;
	}
`;

export default React.memo(GroupCard);
