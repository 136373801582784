import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { MyCard, MyCardHeader, MyFilledInput, MyButton } from "components/Functional/material-ui-styled-Components";
import { CardContent, FormControl, CardActions, InputAdornment, FormHelperText, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { globalStore } from "global/store";
import { fetchData } from "global/networking";
import { URLs } from "assets/config/config";
import { useSnackbar } from "notistack";

export default function ForgetPsswordPage(props) {
	const [t, i18n] = useTranslation(["translation", "login page"]);
	const [globalState, globalDispatch] = globalStore.getStore();
	const [inputedNumber, setInputedNumber] = useState("");
	const [inputIncorrectLength, setInputIncorrectLength] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const getPassword = () => {
		if (inputedNumber.length === 9) {
			const promise = fetchData(URLs.main + "resetPassword", {
				method: "POST",
				headers: {
					"Content-type": "application/json",
				},
				body: JSON.stringify({
					msisdn: inputedNumber,
				}),
			})[0];
			promise
				.then((res) => {
					return res.json();
				})
				.then((data) => {
					if (data.success) {
						setInputedNumber("");
						enqueueSnackbar(`${t("login page:Password sent")}`, { variant: "success" });
					} else {
						enqueueSnackbar(`${t("login page:Message is not sent")}`, { variant: "error" });
						setInputedNumber("");
					}
				});
		} else {
			enqueueSnackbar(`${t("login page:Message is not sent")}`, { variant: "error" });
		}
	};

	useEffect(() => {
		inputedNumber.length === 9 ? setInputIncorrectLength(false) : setInputIncorrectLength(true);
	}, [inputedNumber]);

	const handleInputNumberValue = (e) => {
		setInputedNumber(e.target.value);
	};

	return (
		<ForgetPsswordContainer className="mx-auto">
			<MyCardHeader title={t("login page:Forget password")} subheader={t("login page:Enter phone number")} />
			<CardContent className="pt-0">
				<form onSubmit={getPassword} action="/" method="POST" autoComplete="off">
					<FormControl error={inputIncorrectLength} className="w-100 mt-2">
						<MyFilledInput
							startAdornment={
								<InputAdornment classes={{ root: "MuiInputAdornment-black" }} position="start">
									+994
								</InputAdornment>
							}
							autoFocus
							onChange={handleInputNumberValue}
							id="number-input"
							name="number"
							inputProps={{ maxLength: 9, minLength: 9 }}
							value={inputedNumber}
							aria-describedby="login-error-text"
							disabled={globalState.isFetching}
							type="number"
						/>
						{inputIncorrectLength ? (
							<FormHelperText id="login-error-text">{t("login page:Wrong phone number")}</FormHelperText>
						) : null}
					</FormControl>
				</form>
			</CardContent>
			<CardActions className="px-3 d-flex justify-content-between">
				<Link className="text-decoration-none" disabled={globalState.isFetching} to="/login">
					<MyButton
						id="goback-btn"
						variant="outlined"
						color="actionBackground"
						component="span"
						disabled={globalState.isFetching}
					>
						{t("login page:Back")}
					</MyButton>
				</Link>
				<MyButton
					id="send-btn"
					variant="contained"
					component="span"
					onClick={getPassword}
					disabled={globalState.isFetching || inputIncorrectLength}
				>
					{t("login page:Send")}
				</MyButton>
			</CardActions>
		</ForgetPsswordContainer>
	);
}

const ForgetPsswordContainer = styled(MyCard)`
	max-width: 30rem;
	padding: 3rem;

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type="number"] {
		-moz-appearance: textfield;
	}
	@media (max-width: 768px) {
		padding: 2rem 1rem;
	}
`;
