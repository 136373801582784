import React, { useState, useCallback } from "react";
import { DialogTitle, DialogContent, DialogActions, Button, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { TransitionUp } from "components/pages/common/TransitionUp";
import { MyIconButton, MyDialog } from "components/Functional/material-ui-styled-Components";
import { fetchData } from "global/networking";
import { URLs } from "assets/config/config";
import actionTypes from "global/actionTypes";
import { useSnackbar } from "notistack";
import { globalStore } from "global/store";

const DeleteGroupDialog = (props) => {
	const [t] = useTranslation(["device page", "translation"]);
	const [globalState, globalDispatch] = globalStore.getStore();
	const { enqueueSnackbar } = useSnackbar();

	const closeDialog = () => {
		props.setDeleteDialogState(false);
	};

	const deleteButtonClicked = useCallback(() => {
		const token = `Bearer ${localStorage.getItem("token")}`;
		const promise = fetchData(URLs.main + `Group/Delete/${props.groupData.id}`, {
			method: "GET",
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
		})[0];
		promise
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.success) {
					globalDispatch({
						type: actionTypes.fetchGroups,
						payload: globalState.groupsData.filter((x) => x.id !== props.groupData.id),
					});
					enqueueSnackbar(`${t("groups page:Group deleted successfully")}`, { variant: "success" });
					closeDialog();
				} else {
					enqueueSnackbar(data.errors[0].errorMsg, { variant: "error" });
				}
			})
			.catch((error) => {
				enqueueSnackbar(error.message, { variant: "error" });
				console.log("Request failed", error);
			});
	}, [globalState.groupsData]);

	return (
		<MyDialog
			disableBackdropClick
			open={props.deleteDialogState}
			onClose={closeDialog}
			maxWidth="xs"
			TransitionComponent={TransitionUp}
			aria-labelledby="delete-device"
			fullWidth
		>
			<DialogTitle id="delete-device">
				<div className="d-flex justify-content-between align-items-center">
					<span>{t("groups page:Delete group")}</span>
					<MyIconButton aria-label="Close" onClick={closeDialog}>
						<Close />
					</MyIconButton>
				</div>
			</DialogTitle>
			<DialogContent>
				<Typography variant="body1" gutterBottom>
					{t("translation:Are you sure?")}
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={deleteButtonClicked}>
					{t("translation:Delete")}
				</Button>
			</DialogActions>
		</MyDialog>
	);
};

export default React.memo(DeleteGroupDialog);
