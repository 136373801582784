import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { Language } from "@material-ui/icons";
import { MyMenuItem } from "components/Functional/material-ui-styled-Components";
import { changeLanguage } from "global/utilFunctions";
import { colors } from "assets/config/theme";
import { settings } from "global/settings";

export default function LanguageSelector(props) {
	const [t, i18n] = useTranslation(["translation"]);
	const anchorRef = React.useRef(null);

	const changeLng = (e) => {
		changeLanguage(e.currentTarget.dataset.lng);
	};

	return (
		<>
			{settings.selectedLng === "en" ? (
				<MyMenuItem data-lng="az" onClick={changeLng} transparent={props.transparent}>
					<MyLanguage transparent={props.transparent} />
					<span className="ml-3">{"EN"}</span>
				</MyMenuItem>
			) : settings.selectedLng === "az" ? (
				<MyMenuItem data-lng="en" onClick={changeLng} transparent={props.transparent}>
					<MyLanguage transparent={props.transparent} />
					<span className="ml-3">{"AZ"}</span>
				</MyMenuItem>
			) : null}
		</>
	);
}

let MyLanguage = styled(Language)`
	color: ${(prop) =>
		prop.theme.activeTheme.name === "dark" ? colors.white : prop.transparent ? "white" : colors.grayMain};
`;
