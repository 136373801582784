import React from "react";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import styled from "styled-components/macro";
import { constants } from "assets/config/constants";
import { CircularProgress, Typography } from "@material-ui/core";

export default function HistoryTable(props) {
	const [t, i18n] = useTranslation(["translation, device page", "history page"]);

	return (
		<MUIDataTableStyle
		className="mt-3"
			title={
				<Typography variant="h6">
					{t("history page:History")}
					{props.isLoading && (
						<CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
					)}
				</Typography>
			}
			data={props.data}
			columns={props.columns}
			options={props.options}
		/>
	);
}

const MUIDataTableStyle = styled(MUIDataTable)`
	tfoot.MuiTableFooter-root {
		display: none;
	}

	@media (max-width: ${constants.responsiveSizes.sm}px) {
		.MuiTableCell-root {
			padding: 9px;
		}
		.MuiTypography-body1 {
			font-size: 13px;
		}
		div,
		span {
			font-size: 13px;
		}
	}
`;