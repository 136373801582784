import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { Popper, Grow, ClickAwayListener, MenuList, useTheme, useMediaQuery } from "@material-ui/core";
import {
	PersonPinOutlined,
	MoreVert,
	ExitToAppOutlined,
	PermIdentity,
	ExpandLess,
	ExpandMore,
} from "@material-ui/icons";
import {
	MyCard,
	MyCardHeader,
	MyMenuItem,
	MyDivider,
	MyButton,
} from "components/Functional/material-ui-styled-Components";
import { globalStore } from "global/store";
import { ThemeSwitcher } from "components/Functional/Header/ThemeSwitcher";
import actionTypes from "global/actionTypes";
import LanguageSelector from "components/Functional/Header/LanguageSelector";
import { storageConstants, constants } from "assets/config/constants";
import { availablePages } from "assets/config/config";
import { Link, useHistory } from "react-router-dom";
import { colors } from "assets/config/theme";
import icon_customers from "assets/img/icons/icon_customers.svg";

export default function User(props) {
	const [t, i18n] = useTranslation();
	const [userSelectState, setUserSelectState] = useState(false);
	const [globalState, globalDispatch] = globalStore.getStore();
	const anchorRef = React.useRef(null);
	const history = useHistory();
	const theme = useTheme();
	const mQMatches = useMediaQuery(theme.breakpoints.up("md"));

	const toggleUserSelect = (event) => {
		setUserSelectState((prev) => !prev);
	};

	const closeUserSelect = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setUserSelectState(false);
	};

	const logout = () => {
		localStorage.clear();
		setUserSelectState(false);
		history.push("/");
		globalDispatch({ type: actionTypes.inactive });
	};

	const handleGetProfileDetails = (e) => {
		if (globalState.isFetching) e.preventDefault();
		setUserSelectState((prev) => !prev);
	};

	return (
		<>
			<MyStyledButton ref={anchorRef} aria-label="Menu" onClick={toggleUserSelect}>
				<MyCardHeader
					style={{ textTransform: "capitalize", padding: 0, minWidth: "20px" }}
					small="true"
					title={
						<span>
							{userSelectState ? <ExpandLess /> : <ExpandMore />}
							<img src={icon_customers} className="ml-2" />
						</span>
					}
					subheader={globalState.user.userName}
				/>
			</MyStyledButton>
			<Popper open={userSelectState} anchorEl={anchorRef.current} placement="bottom-end" transition disablePortal>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						id="menu-list-grow"
						style={{ transformOrigin: placement === "bottom-end" ? "center top" : "center bottom" }}
					>
						<MyCard elevation={6}>
							<ClickAwayListener onClickAway={closeUserSelect}>
								<div>
									<MenuList className="py-3 px-4">
										<Link
											to={availablePages[7]}
											onClick={handleGetProfileDetails}
											className="text-decoration-none"
										>
											<MyCardHeader
												small="true"
												title={globalState.user.firstName + " " + globalState.user.lastName}
												avatar={<img src={icon_customers} />}
												subheader={globalState.user.userName}
												className="MenuListHeaderClass"
											/>
										</Link>
										<MyDivider />
										{/* <MyMenuItem>
											<ThemeSwitcher />
										</MyMenuItem> */}
										<MyMenuItem className="pl-0 pt-2 pb-2">
											<LanguageSelector className="w-100" />
										</MyMenuItem>
										<MyDivider />
										<MyMenuItem
											className="pt-3 pb-3"
											onClick={logout}
											disabled={
												globalState.session.sessionStatus !== constants.sessionStatus.active
											}
										>
											<MyLogoutIcon />
											<span style={{ marginLeft: "10px", color: colors.grayMain }}>
												{t("Logout")}
											</span>
										</MyMenuItem>
									</MenuList>
								</div>
							</ClickAwayListener>
						</MyCard>
					</Grow>
				)}
			</Popper>
		</>
	);
}

let MyPerson = styled(PersonPinOutlined)`
	color: ${(prop) => (prop.theme.activeTheme.name === "dark" ? "white" : "black")};
`;

let MyPersonIcon = styled(PermIdentity)`
	color: ${(prop) => (prop.theme.activeTheme.name === "dark" ? "white" : colors.grayMain)};
`;

let MyStyledButton = styled(MyButton)`
	font-size: 1.1rem;
	text-transform: none;
	padding: 0;
	&.root {
		background-color: transparent;
	}
`;

let MyLogoutIcon = styled(ExitToAppOutlined)`
	transform: rotate(180deg);
	color: ${colors.grayMain};
`;
