import themes from "assets/config/theme";
import i18n from "i18n";
import { settings } from "global/settings";
import en from "assets/img/countries/united-kingdom.png";
import az from "assets/img/countries/azerbaijan.png";
import { useState } from "react";

export function isOnlyDigits(str) {
	return /^\d+$/.test(str);
}

export function useTabState(initFunc) {
	const [tabsState, setTabsState] = useState(initFunc());
}

export function isEmpty(obj, exceptKey = []) {
	for (let key in obj) {
		if (obj.hasOwnProperty(key) && exceptKey.indexOf(key) === -1) return false;
	}
	return true;
}

export const changeUrl = (url, history) => () => {
	console.log("changeUrl to ->", url);
	history.push(url);
};

export const openNewTab = (url) => (e) => {
	//mouse wheel clicked button
	if (e.button === 1) {
		window.open(url, "_blank");
		e.preventDefault();
		e.stopPropagation();
	}
};

export function toggleTheme(state) {
	let newState = state === themes.light ? themes.dark : themes.light;
	settings.activeTheme = newState;
	return newState;
}

export const changeLanguage = (selectedLng) => {
	if (i18n.language != selectedLng) {
		i18n.changeLanguage(selectedLng);
		settings.selectedLng = selectedLng;
	}
};

/**
 * Finds intersection of predefined tabs(which are used to build url) with url
 * splitted into path array and returns index of the first found element of
 * intersection array in availableTabs array. If there are no common elements
 * in these arrays returns 0
 * @param {[string]} availableTabs - array of all available tabs of component
 * @param {[string]} splitedPath
 * @returns {number} index of the first found element of intersection array
 * in availableTabs array
 */
export function tabChanger(availableTabs, splitedPath) {
	let intersection = [...availableTabs].filter((x) => splitedPath.indexOf(x) > -1);
	if (intersection.length > 0) return availableTabs.indexOf(intersection[0]);
	else return 0;
}

export function selectedLngIconPath(lng) {
	switch (lng) {
		case "en":
			return en;
		case "az":
			return az;
		default:
			return en;
	}
}

export function sortTableColumn(sortOrder, array = []) {
	let sortField = sortOrder.name;
	let sortDir = sortOrder.direction;
	if (sortField) {
		array = array.sort((a, b) => {
			if (a[sortField] > b[sortField]) {
				return 1 * (sortDir === "asc" ? -1 : 1);
			} else if (a[sortField] < b[sortField]) {
				return -1 * (sortDir === "asc" ? -1 : 1);
			} else {
				return 0;
			}
		});
	}
	return array;
}
