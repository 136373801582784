import React, { useState, useCallback, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components/macro";
import DevicePage from "components/pages/DevicePage/DevicePage";
import GroupsPage from "components/pages/GroupsPage/GroupsPage";
import ClimatePage from "components/pages/ClimatePage/ClimatePage";
import PaymentsPage from "components/pages/PaymentsPage/PaymentsPage";
import HistoryPage from "components/pages/HistoryPage/HistoryPage";
import HowToPage from "components/pages/HowToPage/HowToPage";
import ContactsPage from "components/pages/ContactsPage/ContactsPage";
import UserProfilePage from "components/pages/UserProfilePage/UserProfilePage";
import FAQ from "components/Functional/Header/FAQ";
import { layout } from "assets/config/theme";
import { constants } from "assets/config/constants";
import { availablePages } from "assets/config/config";
import { URLs } from "assets/config/config";
import actionTypes from "global/actionTypes";
import { globalStore } from "global/store";
import { fetchData } from "global/networking";
import { useSnackbar } from "notistack";

export default function Pages(props) {
	const [globalState, globalDispatch] = globalStore.getStore();
	const { enqueueSnackbar } = useSnackbar();

	const fetchProfileDetail = useCallback(() => {
		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
				"Content-type": "application/json",
			},
		};

		const promise = fetchData(URLs.main + `customer/getProfileInfo`, requestOptions)[0];
		promise
			.then(async (response) => {
				const data = await response.json();
				globalDispatch({ type: actionTypes.profileDetails, payload: data.value });
			})
			.catch((error) => {
				console.error("There was an error!", error);
			});
	}, []);

	useEffect(() => {
		if (localStorage.getItem("token")) {
			fetchProfileDetail();
		}
		closeMenu();
	}, []);

	const closeMenu = () => {
		if (document.body.clientWidth < constants.responsiveSizes.md) {
			globalStore.dispatch({ type: actionTypes.closeMenu });
		}
	};

	const fetchDeviceList = useCallback((changeFetchState = false) => {
		const token = `Bearer ${localStorage.getItem("token")}`;
		const requestBody = {
			isActive: true,
			includeDevice: true,
			Pager: {
				CurrentPage: 1,
				PageSize: 100,
			},
		};
		const promise = fetchData(
			URLs.main + "CusDevice/GetListByFilterPaged",
			// "https://26ce05db-0d05-443a-bacd-3e7e3db81a5f.mock.pstmn.io/CusDevice/GetListByFilterPaged",
			{
				method: "POST",
				headers: {
					Authorization: token,
					"Content-type": "application/json",
				},
				body: JSON.stringify(requestBody),
			},
			false,
			changeFetchState
		)[0];
		promise
			.then((res) => {
				// globalDispatch({
				// 	type: actionTypes.fetchDevices, payload: [
				// 		{
				// 			"id": 153,
				// 			"deviceId": 1000000,
				// 			"deviceName": "Cihaz",
				// 			"lastSentDate": "2021-09-14T17:54:52.68805",
				// 			"activatedDt": "2021-03-12T22:14:30.019233",
				// 			"deactivatedDt": null,
				// 			"pin": "12345",
				// 			"imei": "861715031746457",
				// 			"paymentStatus": 2,
				// 			"electricity": 101,
				// 			"mobileSignal": 4,
				// 			"latitude": 40.3902609647365,
				// 			"longitude": 49.830112561409393,
				// 			"wifiStatus": 1,
				// 			"dataSentStatus": 0,
				// 			"sensors": [
				// 				{
				// 					"type": "AirTempHum",
				// 					"sensorId": 123456,
				// 					"sensorName": "Air Temperature Humidity",
				// 					"temperaturStatus": 1,
				// 					"humidityStatus": 1,
				// 					"currTemperature": 30,
				// 					"currHumidity": 40,
				// 					"electricity": 100,
				// 					"mobileSignal": 4
				// 				},
				// 				{
				// 					"type": "SoilHum",
				// 					"sensorId": 123457,
				// 					"sensorName": "Soil Humidity",
				// 					"humidityStatus": 1,
				// 					"currHumidity": 41,
				// 					"electricity": 100,
				// 					"mobileSignal": 4
				// 				},
				// 				{
				// 					"type": "Camera",
				// 					"sensorId": 123457,
				// 					"sensorName": "Camera",
				// 					"electricity": 100,
				// 					"mobileSignal": 4
				// 				}

				// 			]
				// 		}
				// 	]
				// });
				return res.json();
			})
			.then((data) => {
				if (data.success) {
					data.value.list[0].sensors.forEach(el => {
						el.electricity = 100;
						el.mobileSignal = 4;
					});
					data.value.list[0].sensors.push({
						"type": "camera",
						"sensorId": 123457,
						"sensorName": "Camera",
						"electricity": 100,
						"mobileSignal": 4
					});
					data.value.list[0].latitude = 40.976215;
					data.value.list[0].longitude = 28.825178;

					globalDispatch({ type: actionTypes.fetchDevices, payload: data.value.list });
				} else {
					enqueueSnackbar(data.errors[0].errorMsg, { variant: "error" });
				}
			})
			.catch((error) => {
				enqueueSnackbar(error.message, { variant: "error" });
				console.log("Request failed", error);
			});
	}, []);

	useEffect(() => {
		fetchDeviceList(true);
		const interval = setInterval(() => {
			fetchDeviceList(false);
		}, 1000 * 20);
		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
		<Main
			className={`flex-grow-1 ${props.menuState == constants.menuState.toggled ? "wide" : null}`}
			onClick={closeMenu}
		>
			<Switch>
				<Route
					exact
					path={availablePages[0]}
					render={(routeProps) => <DevicePage {...routeProps} fetchDeviceList={fetchDeviceList} />}
				/>
				<Route path={availablePages[1]} component={GroupsPage} />
				<Route path={availablePages[2]} component={ClimatePage} />
				<Route path={availablePages[3]} component={PaymentsPage} />
				<Route path={availablePages[4]} component={HistoryPage} />
				<Route path={availablePages[5]} component={HowToPage} />
				<Route path={availablePages[6]} component={ContactsPage} />
				<Route path={availablePages[7]} component={UserProfilePage} />
				<Route path={availablePages[8]} component={FAQ} />

				{/* when none of the above match, <NoMatch> will be rendered */}
				<Route render={(routeProps) => <DevicePage {...routeProps} fetchDeviceList={fetchDeviceList} />} />
			</Switch>
		</Main>
	);
}

let Main = styled("main")`
	padding: 3rem;
	background: ${(props) => props.theme.activeTheme.main.backgroundColor};
	margin-top: ${layout.header.height};
	margin-left: ${layout.mainMenu.widthOpen};
	transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	&.wide {
		margin-left: 4rem;
		@media (max-width: 768px) {
			margin-left: 0;
		}
	}
	@media (max-width: 768px) {
		margin-left: 0;
	}
	@media (max-width: 576px) {
		padding: 3rem 0.7rem;
	}
`;
