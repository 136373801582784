import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
	MyCard,
	MyCardHeader,
	MyInputLabel,
	MyFilledInput,
	MyButton,
	MyFilledInputForLoginButton,
} from "components/Functional/material-ui-styled-Components";
import { CardContent, FormControl, CardActions, FormHelperText } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { globalStore } from "global/store";
import { fetchData } from "global/networking";
import { URLs } from "assets/config/config";
import { storageConstants } from "assets/config/constants";
import actionTypes from "global/actionTypes";

export default function LoginPage(props) {
	const [inputIncorrect, setInputIncorrect] = useState(false);
	const [inputedUsername, setInputedUsername] = useState("");
	const [inputedPassword, setInputedPassword] = useState("");
	const [t, i18n] = useTranslation(["translation", "login page"]);
	const [globalState, globalDispatch] = globalStore.getStore();

	const loginSubmit = (e) => {
		// to prevent form submit to fix login problem on firefox
		e.preventDefault();
		const promise = fetchData(URLs.main + "authenticate", {
			method: "POST",
			headers: {
				"Content-type": "application/json",
			},
			body: JSON.stringify({
				USERNAME: inputedUsername,
				PASSWORD: inputedPassword,
			}),
		})[0];
		promise
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.success && data.value.token) {
					localStorage.setItem(storageConstants.token, data.value.token);
					globalDispatch({ type: actionTypes.auth, payload: data.value.token });
				} else setInputIncorrect(true);
			})
			.catch((error) => {
				console.log("Request failed", error);
			});
	};

	const handleChange = () => {};

	const forgetPasswordSubmit = () => {};

	const handleUsernameInput = (e) => {
		setInputIncorrect(false);
		setInputedUsername(e.target.value);
	};

	const handlePasswordInput = (e) => {
		setInputIncorrect(false);
		setInputedPassword(e.target.value);
	};

	return (
		<MyCard className="w-100 mx-auto mt-4 p-5">
			<MyCardHeader subheader={t("login page:Login card subheader")} />
			<form onSubmit={loginSubmit} action="/" method="POST" autoComplete="off">
				<CardContent className="pt-0">
					<FormControl error={inputIncorrect} className="w-100">
						<MyInputLabel htmlFor="username-input">{t("login page:Username")}</MyInputLabel>
						<MyFilledInput
							autoFocus
							error={inputIncorrect}
							onChange={handleUsernameInput}
							id="username-input"
							name="Login input"
							value={inputedUsername}
							aria-describedby="login-error-text"
							disabled={globalState.isFetching}
						/>
					</FormControl>
					<FormControl error={inputIncorrect} className="w-100 mt-4">
						<MyInputLabel htmlFor="password-input">{t("login page:Password")}</MyInputLabel>
						<MyFilledInput
							error={inputIncorrect}
							onChange={handlePasswordInput}
							id="password-input"
							name="Password input"
							value={inputedPassword}
							aria-describedby="login-error-text"
							disabled={globalState.isFetching}
							type="password"
						/>
						{inputIncorrect ? (
							<FormHelperText id="login-error-text">{t("login page:Wrong credentials")}</FormHelperText>
						) : null}
					</FormControl>
				</CardContent>

				<CardActions className="px-3 d-flex justify-content-between align-items-center">
					{/* <Link className="text-dark" to="/resetPassword"> */}
					<Link className="text-dark">{t("login page:Forget password")}</Link>
				</CardActions>

				<CardActions className="px-3 mt-3 d-flex justify-content-between">
					<MyFilledInputForLoginButton
						id="login-btn"
						component="span"
						onClick={loginSubmit}
						disabled={globalState.isFetching}
						type="submit"
						value={t("login page:Login")}
					/>
					{/* <Link className="text-decoration-none" disabled={globalState.isFetching} to="/register"> */}
					<Link className="text-decoration-none" disabled={globalState.isFetching}>
						<MyButton id="signup-btn" variant="outlined" component="span" disabled={true}>
							{t("login page:Sign up")}
						</MyButton>
					</Link>
				</CardActions>
			</form>
		</MyCard>
	);
}
