import React, { useState, useEffect } from "react";
import { Typography, Table, Paper, TableHead, TableRow, TableContainer, TableBody, TableCell } from "@material-ui/core";
import styled from "styled-components/macro";
import { constants } from "assets/config/constants";
import moment from "moment";
import NoDataFound from "components/pages/common/NoDataFound";
import { useTranslation } from "react-i18next";
import { colors } from "assets/config/theme";
import Error from "@material-ui/icons/Error";

export default function Payments(props) {
	const [t, i18n] = useTranslation("translation, payment page");
	let dateNow = Date.now();

	return (
		<>
			<Typography gutterBottom style={{ fontSize: "1.2rem", color: colors.black }}>
				{t("payment page:Your payments")}
			</Typography>
			{props.nextPayments.length > 0 ? (
				<TableContainerStyle style={{ maxHeight: 490, marginTop: "1.6rem" }}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								<TableCell
									style={{
										backgroundColor: colors.white,
										color: colors.grayLightSeventh,
									}}
								>
									{t("payment page:Device")}
								</TableCell>
								<TableCell
									style={{
										backgroundColor: colors.white,
										color: colors.grayLightSeventh,
									}}
								>
									{t("payment page:Amount")}
								</TableCell>
								<TableCell
									style={{
										backgroundColor: colors.white,
										color: colors.grayLightSeventh,
									}}
								>
									{t("payment page:Next payment")}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{props.nextPayments.map((item, key) => (
								<TableRow
									key={key}
									style={{
										backgroundColor: `${key % 2 === 0 ? colors.grayLightMain : null}`,
									}}
								>
									<TableCell
										className={`${new Date(item.nextPaymentDt) < dateNow ? "text-danger" : null}`}
									>
										{item.name}
									</TableCell>
									<TableCell
										className={`${new Date(item.nextPaymentDt) < dateNow ? "text-danger" : null}`}
									>
										{item.price} AZN
									</TableCell>
									<TableCell
										className={`${new Date(item.nextPaymentDt) < dateNow ? "text-danger" : null}`}
									>
										{moment(item.nextPaymentDt).format("DD.MM.YYYY")}
										{new Date(item.nextPaymentDt) < dateNow ? (
											<Error
												style={{
													marginLeft: "1.25rem",
													color: colors.red,
												}}
											/>
										) : null}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainerStyle>
			) : (
				<NoDataFound text={t("translation:There is no amount you have to pay")} />
			)}
		</>
	);
}

const TableContainerStyle = styled(TableContainer)`
	@media (max-width: ${constants.responsiveSizes.sm}px) {
		.MuiTableCell-root {
			padding: 9px;
		}
		td {
			font-size: 13px;
		}
	}
`;
