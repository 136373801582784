import React, { useEffect, useState, useCallback } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useTranslation } from "react-i18next";
import {
	MyCard,
	MyCardHeader,
	MyInputLabel,
	MyFilledInput,
	MySelect,
	MyMenuItem,
	MyArrowDropDownRoundedIcon,
	MyDateTimePicker,
	MyButton,
} from "components/Functional/material-ui-styled-Components";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import {
	CardContent,
	FormControl,
	CircularProgress,
	Typography,
	Button,
	Grid,
	IconButton,
	Tooltip,
	Select,
	MenuItem,
	InputLabel,
	Box,
	Tab,
	Tabs,
	AppBar,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { URLs } from "assets/config/config";
import HistoryTable from "./HistoryTable";
import HistoryChartPage from "./HistoryChartPage";
import { fetchData } from "global/networking";
import { useSnackbar } from "notistack";
import moment from "moment";
import NoDataFound from "components/pages/common/NoDataFound";
import { saveAs } from "file-saver";
import { colors } from "assets/config/theme";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import styled from "styled-components/macro";
import { globalStore } from "global/store";
import { constants } from "assets/config/constants";

export default function HistoryPage() {
	const [globalState, globalDispatch] = globalStore.getStore();
	const [t, i18n] = useTranslation(["translation, device page", "history page"]);
	const { enqueueSnackbar } = useSnackbar();
	const [isLoadingDevice, setIsLoadingDevice] = useState(false);
	const [showAdditionalFilter, setShowAdditionalFilter] = useState(false);
	const [historyData, setHistoryData] = useState({ list: [] });
	const [deviceData, setDeviceData] = useState([]);
	const [sortOrder, setSortOrder] = useState({ direction: "asc", name: "sentDt" });
	const [fromDate, setFromDate] = useState(null);
	const [toDate, setToDate] = useState(null);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [allPageSize, setAllPageSize] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchValue, setSearchValue] = useState({
		deviceId: "",
		fromTemperature: "",
		sensor: constants.sensorTypes.airTempHum,
		toTemperature: "",
		fromHumidity: "",
		toHumidity: "",
		interval: "",
	});
	const [value, setValue] = useState(0);
	const classes = useStyles();
	const paginationClasses = PaginationStyles();
	const selectClasses = selectStyles();
	const theme = useTheme();

	const tableOptions = {
		responsive: "vertical",
		selectableRows: "none",
		serverSide: true,
		search: false,
		viewColumns: false,
		download: false,
		print: false,
		count: 0,
		page: 0,
		expandableRows: false,
		downloadOptions: {
			filename: "History",
		},
		customToolbar: () => {
			return (
				<Tooltip title={"Export Excel"}>
					<IconButton onClick={exportHistory}>
						<DownloadIcon />
					</IconButton>
				</Tooltip>
			);
		},
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					if (!globalState.isFetching) {
						fetchHistory(tableState.page + 1, tableState.rowsPerPage);
					}
					break;
			}
		},
		setRowProps: (row, dataIndex) => {
			if (dataIndex % 2 === 0) {
				return {
					style: { background: colors.grayLightMain },
				};
			}
		},
	};

	const [options, setOptions] = useState(tableOptions);

	const percentText = (number) => {
		return number === 4 ? "100%" : number === 3 ? "75%" : number === 2 ? "50%" : number === 1 ? "25%" : "0%";
	};
	const electricityText = (number) => {
		return number === 101 ? "100%" : `${number}%`;
	};

	const intervalList = [
		{
			name: t("history page:Without interval"),
			value: 0,
		},
		{
			name: t("history page:Every 1 hours"),
			value: 60,
		},
		{
			name: t("history page:Every 3 hours"),
			value: 180,
		},
		{
			name: t("history page:Every 6 hours"),
			value: 360,
		},
	];

	// fetch device list for dropdown
	const fetchDeviceList = useCallback(() => {
		setIsLoadingDevice(true);
		const token = `Bearer ${localStorage.getItem("token")}`;
		const requestBody = {
			isActive: true,
			includeDevice: true,
			Pager: {
				CurrentPage: 1,
				PageSize: 100,
			},
		};
		const promise = fetchData(URLs.main + "CusDevice/GetListByFilterPaged", {
			method: "POST",
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
			body: JSON.stringify(requestBody),
		})[0];
		promise
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				setIsLoadingDevice(false);
				if (data.success) {
					setDeviceData(data.value.list);
				} else {
					enqueueSnackbar(data.errors[0].errorMsg, { variant: "error" });
				}
			})
			.catch((error) => {
				setIsLoadingDevice(false);
				enqueueSnackbar(error.message, { variant: "error" });
				console.log("Request failed", error);
			});
	}, []);

	const exportHistory = useCallback(() => {
		const currentSearchValue = JSON.parse(localStorage.getItem("searchValue"));
		const currentFromDate = localStorage.getItem("fromDate");
		const currentToDate = localStorage.getItem("toDate");
		const requestOptions = {
			method: "POST",
			responseType: "blob",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
				"Content-Type": "application/json;charset=UTF-8",
			},
			body: JSON.stringify({
				dateInterval: currentSearchValue.interval === "" ? 0 : currentSearchValue.interval,
				fromDt: currentFromDate === null ? "" : moment(currentFromDate).format(),
				toDt: currentToDate === null ? "" : moment(currentToDate).format(),
				toTemperature: currentSearchValue.toTemperature,
				fromTemperature: currentSearchValue.fromTemperature,
				fromHumidity: currentSearchValue.fromHumidity,
				toHumidity: currentSearchValue.toHumidity,
				sensorType: searchValue.sensor,
				value: {
					cusDeviceId: currentSearchValue.deviceId,
				},
			}),
		};

		const promise = fetchData(URLs.main + "Excell/ExportDeviceData", requestOptions)[0];
		promise
			.then((response) => {
				response.blob().then((blob) => {
					saveAs(blob, "history.xlsx");
				});
			})
			.catch((error) => {
				console.error("There was an error!", error);
			});
	}, []);

	// fetch history data in table
	const fetchHistory = useCallback(
		(sortOrder = { direction: "asc", name: "sentDt" }) => {
			const isSortOrderEmpty = Object.keys(sortOrder).length === 0;
			const token = `Bearer ${localStorage.getItem("token")}`;
			const requestBody = {
				orderByTemp: !isSortOrderEmpty
					? sortOrder.name === "temperature"
						? sortOrder.direction === "asc"
							? true
							: false
						: ""
					: "",
				orderByHum: !isSortOrderEmpty
					? sortOrder.name === "humidity"
						? sortOrder.direction === "asc"
							? true
							: false
						: ""
					: "",
				orderDt: !isSortOrderEmpty
					? sortOrder.name === "sentDt"
						? sortOrder.direction === "asc"
							? true
							: false
						: ""
					: "",
				fromDt: fromDate === null ? "" : moment(fromDate).format(),
				toDt: toDate === null ? "" : moment(toDate).format(),
				toTemperature: searchValue.toTemperature,
				fromTemperature: searchValue.fromTemperature,
				fromHumidity: searchValue.fromHumidity,
				toHumidity: searchValue.toHumidity,
				dateInterval: searchValue.interval === "" ? 0 : searchValue.interval,
				sensorType: searchValue.sensor,
				value: {
					cusDeviceId: searchValue.deviceId,
				},
				Pager: {
					CurrentPage: currentPage,
					PageSize: rowsPerPage,
				},
			};
			const promise = fetchData(URLs.main + "DeviceData/GetListByFilterPaged", {
				method: "POST",
				headers: {
					Authorization: token,
					"Content-type": "application/json",
				},
				body: JSON.stringify(requestBody),
			})[0];
			promise
				.then((res) => {
					return res.json();
				})
				.then((data) => {
					if (data.success) {
						setHistoryData({ list: data.value.list });
						setAllPageSize(Math.ceil(data.value.totalCount / rowsPerPage));
						setOptions({
							...options,
							count: data.value.totalCount,
							onTableChange: (action, tableState) => {
								switch (action) {
									case "changePage":
										if (!globalState.isFetching) {
											fetchHistory(tableState.sortOrder);
										}
										break;
									case "changeRowsPerPage":
										if (!globalState.isFetching) {
											fetchHistory(tableState.sortOrder);
										}
										break;
									case "sort":
										if (!globalState.isFetching) {
											fetchHistory(tableState.sortOrder);
											setSortOrder(tableState.sortOrder);
										}
										break;
								}
							},
						});
					} else {
						enqueueSnackbar(data.errors[0].errorMsg, { variant: "error" });
					}
				})
				.catch((error) => {
					enqueueSnackbar(error.message, { variant: "error" });
					console.log("Request failed", error);
				});
		},
		[searchValue, fromDate, toDate, rowsPerPage, currentPage]
	);

	useEffect(() => {
		fetchDeviceList();
	}, []);

	const handleSearchValue = (e) => {
		const value = e.target.value;
		setSearchValue({
			...searchValue,
			[e.target.name]: value,
		});
	};

	const searchButtonClicked = () => {
		setHistoryData({ list: [] });
		if (searchValue.deviceId) {
			setCurrentPage(1);
			fetchHistory(sortOrder);
		}
	};

	const tableColumns = [
		{
			name: "temperature",
			label: t("device page:Temperature"),
			options: {
				customBodyRender: (value, tableMeta, updateValue) => <div>{`${value}°C`}</div>,
				display: searchValue.sensor === constants.sensorTypes.airTempHum ? true : "excluded"
			},
		},
		{
			name: "humidity",
			label: searchValue.sensor === constants.sensorTypes.airTempHum ? t("device page:Humidity") : t("device page:Soil Humidity"),
			options: {
				customBodyRender: (value, tableMeta, updateValue) => <div>{`${value}%`}</div>,
			},
		},
		{
			name: "electricity",
			label: t("history page:Electricity"),
			options: {
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => electricityText(value),
			},
		},
		{
			name: "mobileSignal",
			label: t("history page:Mobile signal"),
			options: {
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => percentText(value),
			},
		},
		{
			name: "sentDt",
			label: t("history page:Date"),
			options: {
				customBodyRender: (value, tableMeta, updateValue) => (
					<div>{moment(value).format("DD-MM-YYYY HH:mm:ss")}</div>
				),
			},
		},
	];

	useEffect(() => {
		localStorage.setItem("searchValue", JSON.stringify(searchValue));
		localStorage.setItem("fromDate", fromDate);
		localStorage.setItem("toDate", toDate);
	}, [searchValue, fromDate, toDate]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleChangeIndex = (index) => {
		setValue(index);
	};
	const handleSelectChange = (event) => {
		setRowsPerPage(event.target.value);
	};

	useEffect(() => {
		setHistoryData({ list: [] });
		setCurrentPage(1);
		if (searchValue.deviceId) {
			fetchHistory(sortOrder);
		}
	}, [rowsPerPage]);

	const handleChangePage = (event, number) => {
		setCurrentPage(number);
	};

	useEffect(() => {
		setHistoryData({ list: [] });
		if (searchValue.deviceId) {
			fetchHistory(sortOrder);
		}
	}, [currentPage]);

	return (
		<>
			{isLoadingDevice ? (
				<div className="d-flex h-100 justify-content-center align-items-center">
					<CircularProgress size="5rem" />
				</div>
			) : (
				<>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<MyCard wide="100%">
							<MyCardHeader title={t("history page:Search")} className="pb-0 pb-sm-2" />
							<CardContent className="pt-0 pt-sm-2">
								<Grid container spacing={2}>
									<Grid item xs={12} sm={12} md={6} lg={3} className="p-0 p-sm-2">
										<FormControl className="w-100 mb-0 mb-sm-3">
											<MyInputLabel htmlFor="device-name-select">
												{t("history page:Select device")}
											</MyInputLabel>
											<MySelect
												input={
													<MyFilledInput
														onChange={handleSearchValue}
														id="device-name-select"
														name="deviceId"
														value={searchValue.deviceId}
													/>
												}
												IconComponent={MyArrowDropDownRoundedIcon}
											>
												{deviceData.map((x, key) => (
													<MyMenuItem key={key} className="text-dark" value={x.id}>
														{x.deviceName}
													</MyMenuItem>
												))}
											</MySelect>
										</FormControl>

										<MyDateTimePicker
											label={t("history page:From date")}
											ampm={false}
											value={fromDate}
											onChange={(val) => setFromDate(val)}
											// format="dd/MM/yyyy hh:mm"
											className="mb-0 mb-sm-3"
											clearable
										/>

										<FormControl className="w-100 mb-0 mb-sm-3">
											<MyInputLabel htmlFor="interval-select">
												{t("history page:Select interval")}
											</MyInputLabel>
											<MySelect
												input={
													<MyFilledInput
														onChange={handleSearchValue}
														id="interval-select"
														name="interval"
														value={searchValue.interval}
													/>
												}
												IconComponent={MyArrowDropDownRoundedIcon}
											>
												{intervalList.map((x, key) => (
													<MyMenuItem key={key} className="text-dark" value={x.value}>
														{x.name}
													</MyMenuItem>
												))}
											</MySelect>
										</FormControl>
									</Grid>
									<Grid item xs={12} sm={12} md={6} lg={3} className="p-0 p-sm-2">
										<FormControl className="w-100 mb-0 mb-sm-3">
											<MyInputLabel htmlFor="sensor-select">
												{t("device page:Sensor name")}
											</MyInputLabel>
											<MySelect
												input={
													<MyFilledInput
														onChange={handleSearchValue}
														id="sensor-select"
														name="sensor"
														value={searchValue.sensor}
													/>
												}
												IconComponent={MyArrowDropDownRoundedIcon}
											>
												<MyMenuItem className="text-dark" value={constants.sensorTypes.airTempHum}>
													{t("sensors:Air sensor")}
												</MyMenuItem>
												<MyMenuItem className="text-dark" value={constants.sensorTypes.soilHum}>
													{t("sensors:Soil sensor")}
												</MyMenuItem>
											</MySelect>
										</FormControl>
										<MyDateTimePicker
											clearable
											label={t("history page:To date")}
											ampm={false}
											value={toDate}
											onChange={(val) => setToDate(val)}
										// format="dd/MM/yyyy hh:mm"
										/>
									</Grid>
									<Grid
										className="d-flex justify-content-lg-center align-items-start pb-0 pb-sm-0"
										item
										xs={12}
										sm={12}
										md={6}
										lg={2}
									>
										<Button
											className="mt-1"
											variant="contained"
											color="default"
											size="small"
											onClick={() => setShowAdditionalFilter(!showAdditionalFilter)}
											startIcon={showAdditionalFilter ? <Visibility /> : <VisibilityOff />}
										>
											{t("history page:Additional filter")}
										</Button>
									</Grid>
									{showAdditionalFilter && (
										<>
											<Grid item xs={12} sm={12} md={6} lg={2} className="p-0 p-sm-2">
												<FormControl className="w-100 mb-0 mb-sm-3">
													<MyInputLabel htmlFor="from-temperature">
														{t("history page:Min temperature")}
													</MyInputLabel>
													<MyFilledInput
														required
														id="from-temperature"
														type="number"
														name="fromTemperature"
														value={searchValue.fromTemperature}
														onChange={handleSearchValue}
													/>
												</FormControl>
												<FormControl className="w-100 mb-0 mb-sm-3">
													<MyInputLabel htmlFor="to-temperature">
														{t("history page:Max temperature")}
													</MyInputLabel>
													<MyFilledInput
														required
														id="to-temperature"
														type="number"
														name="toTemperature"
														value={searchValue.toTemperature}
														onChange={handleSearchValue}
													/>
												</FormControl>
											</Grid>
											<Grid item xs={12} sm={12} md={6} lg={2} className="p-0 p-sm-2">
												<FormControl className="w-100 mb-0 mb-sm-3">
													<MyInputLabel htmlFor="from-humidity">
														{t("history page:Min humidity")}
													</MyInputLabel>
													<MyFilledInput
														required
														id="from-humidity"
														type="number"
														name="fromHumidity"
														value={searchValue.fromHumidity}
														onChange={handleSearchValue}
													/>
												</FormControl>
												<FormControl className="w-100 mb-3">
													<MyInputLabel htmlFor="to-humidity">
														{t("history page:Max humidity")}
													</MyInputLabel>
													<MyFilledInput
														required
														id="to-humidity"
														type="number"
														name="toHumidity"
														value={searchValue.toHumidity}
														onChange={handleSearchValue}
													/>
												</FormControl>
											</Grid>
										</>
									)}
								</Grid>
								<Grid
									spacing={2}
									container
									className="d-flex justify-content-between align-items-center"
								>
									<Grid item xs={12} className="d-flex justify-content-end">
										<MyButton
											id="search-btn"
											variant="contained"
											component="span"
											onClick={searchButtonClicked}
											disabled={!searchValue.deviceId}
										>
											{t("climate page:Search")}
										</MyButton>
									</Grid>
								</Grid>
								<DivStyle>
									<FormControl className={selectClasses.formControl}>
										<InputLabel id="demo-simple-select-label">
											{t("history page:Rows per page")}
										</InputLabel>
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={rowsPerPage}
											onChange={handleSelectChange}
										>
											<MenuItem value={10}>10</MenuItem>
											<MenuItem value={50}>50</MenuItem>
											<MenuItem value={100}>100</MenuItem>
										</Select>
									</FormControl>
									<div className={paginationClasses.root}>
										<Pagination
											count={allPageSize}
											page={currentPage}
											onChange={handleChangePage}
											color="primary"
										/>
									</div>
								</DivStyle>
							</CardContent>
						</MyCard>
					</MuiPickersUtilsProvider>

					<div className={`${classes.root} mt-5`}>
						<AppBar position="static" color="default">
							<TabsStyle
								value={value}
								onChange={handleChange}
								indicatorColor="primary"
								textColor="primary"
								variant="fullWidth"
								aria-label="full width tabs example"
							>
								<Tab label={t("history page:Table")} {...a11yProps(0)} />
								<Tab label={t("history page:Chart")} {...a11yProps(1)} />
							</TabsStyle>
						</AppBar>
						<SwipeableViews
							axis={theme.direction === "rtl" ? "x-reverse" : "x"}
							index={value}
							onChangeIndex={handleChangeIndex}
						>
							<TabPanel value={value} index={0} dir={theme.direction}>
								<HistoryTable
									data={historyData.list}
									columns={tableColumns}
									options={options}
									isLoading={globalState.isFetching}
								/>
							</TabPanel>
							<TabPanel value={value} index={1} dir={theme.direction}>
								<HistoryChartPage
									data={historyData.list}
									columns={tableColumns}
									deviceData={deviceData}
									searchValue={searchValue}
									isLoading={globalState.isFetching}
								/>
							</TabPanel>
						</SwipeableViews>
					</div>
				</>
			)}
		</>
	);
}

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<BoxStyle>
					<Typography>{children}</Typography>
				</BoxStyle>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
	},
}));

const TabsStyle = styled(Tabs)`
	.MuiTab-textColorPrimary.Mui-selected {
		background-color: #66bb6a;
		color: white;
	}
	.MuiTab-textColorPrimary {
		color: #66bb6a;
		background-color: white;
		font-weight: 700;
		letter-spacing: 1px;
	}
`;

const BoxStyle = styled(Box)`
	padding: 30px;
	@media (max-width: 576px) {
		padding: 30px 5px;
	}
`;

const selectStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		marginRight: theme.spacing(3),
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}));

const PaginationStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			marginTop: theme.spacing(2),
		},
	},
}));

const DivStyle = styled("div")`
	margin-top: 20px;
	padding: 20px 0;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 2px 1px -1px rgba(0, 0, 0, 0.12);
	@media (min-width: 768px) {
		display: flex;
	}
	.MuiPaginationItem-textPrimary.Mui-selected {
		color: #fff;
		background-color: ${colors.green};
	}
`;
