import React, { useState, useCallback, useEffect, useMemo } from "react";
import { findIndex } from "lodash";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { NotificationsNone, Help } from "@material-ui/icons";
import moment from "moment";
import {
	Badge,
	List,
	ListItem,
	ListItemText,
	ClickAwayListener,
	ListItemIcon,
	Box,
	Hidden,
	useTheme,
} from "@material-ui/core";
import { MyCard } from "components/Functional/material-ui-styled-Components";
import { IconButton, Popper, Grow } from "@material-ui/core";
import BurgerMenu from "components/Functional/Header/BurgerMenu";
import LanguageSelector from "components/Functional/Header/LanguageSelector";
import User from "components/Functional/Header/User";
import { layout } from "assets/config/theme";
import { withRouter, Link } from "react-router-dom";
import { globalStore } from "global/store";
import { constants } from "assets/config/constants";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { URLs, availablePages } from "assets/config/config";
import { colors } from "assets/config/theme";
import { fetchData } from "global/networking";
import logo from "assets/img/icons/azercell_logo.svg";
import IQLimeLogo from "assets/img/icons/iqlimeLogo";

const defaultProps = {
	color: "secondary",
	children: <NotificationsNone />,
};

export default function Header(props) {
	const [t, i18n] = useTranslation(["translation"]);
	const BurgerMenuWithRouter = withRouter(BurgerMenu);
	const [globalState, globalDispatch] = globalStore.getStore();
	const [userSelectState, setUserSelectState] = useState(false);
	const [notificationData, setNotificationData] = useState([]);
	const anchorRef = React.useRef(null);
	const theme = useTheme();

	const toggleUserSelect = (event) => {
		setUserSelectState((prev) => !prev);
	};

	const closeUserSelect = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setUserSelectState(false);
	};

	const fetchReadNotification = useCallback((id) => {
		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
				"Content-type": "application/json",
			},
		};

		const promise = fetchData(URLs.main + `Notification/Read/${id}`, requestOptions)[0];
		promise
			.then(async (response) => {
				const data = await response.json();
			})
			.catch((error) => {
				console.error("There was an error!", error);
			});
	}, []);

	const fetchNotifications = useCallback(() => {
		const requestOptions = {
			method: "POST",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
				"Content-type": "application/json",
			},
			body: JSON.stringify({
				Pager: {
					CurrentPage: 1,
					PageSize: 100,
				},
			}),
		};

		const promise = fetchData(URLs.main + "Notification/GetListByFilterPaged", requestOptions)[0];
		promise
			.then(async (response) => {
				const data = await response.json();
				setNotificationData(data.value.list);
			})
			.catch((error) => {
				console.error("There was an error!", error);
			});
	}, []);

	useEffect(() => {
		let interval;
		if (localStorage.getItem("token")) {
			fetchNotifications();
			interval = setInterval(() => {
				fetchNotifications();
			}, 1000 * 10);
		}
		return () => clearInterval(interval);
	}, [localStorage.getItem("token")]);

	const readNotification = (value) => {
		const updateIndex = findIndex(notificationData, { id: value });
		const newNotificationData = [
			...notificationData.slice(0, updateIndex),
			{ ...notificationData[updateIndex], read: true },
			...notificationData.slice(updateIndex + 1, notificationData.length),
		];
		fetchReadNotification(value);
		setNotificationData(newNotificationData);
	};

	const existNotification = useMemo(() => {
		return notificationData.filter((item) => item.read === false);
	}, [notificationData]);

	const handleFAQ = (e) => {
		if (globalState.isFetching) e.preventDefault();
	};

	return (
		<Div className="d-flex w-100 justify-content-between align-items-center pr-3">
			<Box display="flex" alignItems="center" justifyContent="space-between" width="7rem">
				{globalState.session.sessionStatus === constants.sessionStatus.active ? (
					<BurgerMenuWithRouter menuState={props.menuState} />
				) : null}
				<IQLimeLogo />
			</Box>
			<div className="d-flex align-items-center">
				<Link
					to={availablePages[8]}
					onClick={handleFAQ}
					style={{
						color:
							globalState.session.sessionStatus === constants.sessionStatus.inactive
								? "white"
								: theme.palette.grey.dark,
						marginRight: 15,
					}}
				>
					<Help
						style={{
							fontSize: 27,
							color: colors.grayLightThird,
						}}
					/>
				</Link>
				{globalState.session.sessionStatus === constants.sessionStatus.inactive ? (
					<LanguageSelector className="mx-3" transparent color="white" />
				) : null}

				{globalState.session.sessionStatus === constants.sessionStatus.active ? (
					<>
						<IconButton
							ref={anchorRef}
							style={{ color: theme.palette.grey.dark }}
							aria-label="Notifications"
							onClick={toggleUserSelect}
						>
							<Badge variant={existNotification.length > 0 ? "dot" : "standard"} {...defaultProps} />
						</IconButton>

						<PopperStyle
							open={userSelectState}
							anchorEl={anchorRef.current}
							placement="bottom-end"
							transition
							disablePortal
						>
							{({ TransitionProps, placement }) => (
								<Grow
									{...TransitionProps}
									id="menu-list-grow"
									style={{
										transformOrigin: placement === "bottom-end" ? "center top" : "center bottom",
									}}
								>
									<MyCard elevation={6}>
										<ClickAwayListener onClickAway={closeUserSelect}>
											<List
												dense
												style={{
													color: "black",
													maxHeight: 450,
													overflow: "auto",
												}}
												className="px-1 px-sm-4"
											>
												{notificationData.length > 0 ? (
													<ListItemText
														className="border-bottom p-3"
														primary={
															<span style={{ fontSize: "1.1rem" }}>
																{t("translation:Notifications")}
															</span>
														}
													/>
												) : null}
												{notificationData.length > 0 ? (
													notificationData.map((item, key) => (
														<ListItem key={key} className="border-bottom">
															<ListItemIcon style={{ minWidth: 50 }}>
																{!item.read && (
																	<IconButton
																		disabled={globalState.isFetching}
																		onClick={() => readNotification(item.id)}
																	>
																		<FiberManualRecordIcon
																			style={{
																				color: colors.blue,
																				fontSize: "1rem",
																			}}
																		/>
																	</IconButton>
																)}
															</ListItemIcon>
															<ListItemText
																primary={
																	<span
																		style={{
																			fontWeight: !item.read ? "bold" : "normal",
																			fontSize: "13px",
																		}}
																	>
																		{item.text}
																	</span>
																}
																secondary={
																	<span
																		style={{
																			color: colors.grayMain,
																			fontSize: "11px",
																		}}
																	>
																		{moment(item.date).format("DD.MM.YYYY HH:mm")}
																	</span>
																}
															/>
														</ListItem>
													))
												) : (
													<ListItem>
														<ListItemText primary={t("translation:No notifications")} />
													</ListItem>
												)}
											</List>
										</ClickAwayListener>
									</MyCard>
								</Grow>
							)}
						</PopperStyle>
						<User />
					</>
				) : null}
			</div>
		</Div>
	);
}

let Div = styled("div")`
	position: fixed;
	z-index: 9999;
	height: ${layout.header.height};
	background: ${(props) => props.theme.activeTheme.header.backgroundColor};
	border-bottom: ${(props) => props.theme.activeTheme.header.borderBottom};
`;

let AStyled = styled(Box)`
	text-decoration: none;
`;

const PopperStyle = styled(Popper)`
	@media (max-width: ${constants.responsiveSizes.sm}px) {
		margin-left: 1%;
		width: 94%;
	}
`;
