import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import {
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	FormControl,
	Grid,
	FormControlLabel,
	Switch,
	Typography,
	InputAdornment,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { TransitionUp } from "components/pages/common/TransitionUp";
import {
	MyIconButton,
	MyFilledInput,
	MyInputLabel,
	MyDialog,
	MyButton,
} from "components/Functional/material-ui-styled-Components";
import { fetchData } from "global/networking";
import { URLs } from "assets/config/config";
import { useSnackbar } from "notistack";
import { globalStore } from "global/store";

const defaultInputValues = {
	airMinTemperature: "",
	airMaxTemperature: "",
	airMinHumidity: "",
	airMaxHumidity: "",
	soilMinHumidity: "",
	soilMaxHumidity: "",
	phone1: "",
	phone2: "",
	phone3: "",
	isCall: false,
	isSms: false,
	id: null,
};

const SettingDialog = (props) => {
	const [globalState, globalDispatch] = globalStore.getStore();
	const [t] = useTranslation(["device page", "translation"]);
	const [fetchDevices, setFetchDevices] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [inputValue, setInputValue] = React.useState(defaultInputValues);

	const closeDialog = () => {
		setInputValue(defaultInputValues);
		props.setSettingDialogState(false);
	};

	const handleInputValue = (e) => {
		const value = e.target.value;
		setInputValue({
			...inputValue,
			[e.target.name]: value,
		});
		props.setIsButtonDisabled(false);
	};

	const handleSwitchValue = (e) => {
		setInputValue({
			...inputValue,
			[e.target.name]: e.target.checked,
		});
		props.setIsButtonDisabled(false);
	};

	useEffect(() => {
		let mounted = true;

		if (mounted && props.settingDialogState) {
			fetchSettingData();
		}

		return function cleanup() {
			mounted = false;
		};
	}, [props.settingDialogState]);

	// post settings data
	const saveButtonClicked = useCallback(() => {
		const token = `Bearer ${localStorage.getItem("token")}`;
		const requestBody = {
			...inputValue,
			phone1: inputValue.phone1,
			phone2: inputValue.phone2,
			phone3: inputValue.phone3,
			isCall: inputValue.isCall,
			isSms: inputValue.isSms,
			id: props.deviceData.id,
		};

		const promise = fetchData(URLs.main + "CusDeviceSetting/save", {
			method: "POST",
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
			body: JSON.stringify(requestBody),
		})[0];
		promise
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.success) {
					setFetchDevices(true);
					enqueueSnackbar(`${t("device page:Device settings updated successfully")}`, { variant: "success" });
					closeDialog();
				} else {
					enqueueSnackbar(`${t("device page:Thresholds are set incorrectly")}`, { variant: "error" });
				}
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: "error" });
				console.log("Request failed", error);
			});
	}, [inputValue]);

	// fetch settings data
	const fetchSettingData = useCallback(() => {
		const token = `Bearer ${localStorage.getItem("token")}`;
		const promise = fetchData(URLs.main + `CusDeviceSetting/GetById/${props.deviceData.id}`, {
			method: "GET",
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
		})[0];
		promise
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.success) {
					setInputValue({
						...inputValue,
						airMinTemperature: data.value.airMinTemperature != null ? data.value.airMinTemperature : "",
						airMaxTemperature: data.value.airMaxTemperature != null ? data.value.airMaxTemperature : "",
						airMinHumidity: data.value.airMinHumidity != null ? data.value.airMinHumidity : "",
						airMaxHumidity: data.value.airMaxHumidity != null ? data.value.airMaxHumidity : "",
						soilMinHumidity: data?.value?.soilMinHumidity ?? "",
						soilMaxHumidity: data?.value?.soilMaxHumidity ?? "",
						phone1: data.value.phone1 || "",
						phone2: data.value.phone2 || "",
						phone3: data.value.phone3 || "",
						isCall: data.value.isCall,
						isSms: data.value.isSms,
					});
				}
			})
			.catch((error) => {
				console.log("Request failed", error);
			});
	}, []);

	// fetch devices list again when updated and closed dialog
	useEffect(() => {
		if (fetchDevices && !props.settingDialogState) {
			props.fetchDeviceList();
		}
	}, [fetchDevices, props.settingDialogState]);

	return (
		<MyDialog
			disableBackdropClick
			open={props.settingDialogState}
			onClose={closeDialog}
			TransitionComponent={TransitionUp}
			aria-labelledby="settings"
			fullWidth
		>
			<DialogTitle id="settings">
				<div className="d-flex justify-content-between align-items-center">
					<span>
						{props.deviceData.deviceName} - {t("translation:Settings")}
					</span>
					<MyIconButton aria-label="Close" onClick={closeDialog}>
						<Close />
					</MyIconButton>
				</div>
			</DialogTitle>
			<DialogContent>
				<Grid container>
					<Grid item xs={12} md={12} lg={6}>
						{props.deviceData.activatedDt && (
							<Typography variant="subtitle2" gutterBottom>
								{t("device page:Activated date")}:&nbsp;
								{moment(props.deviceData.activatedDt).format("DD-MM-YYYY")}
							</Typography>
						)}
					</Grid>
					<Grid item xs={12} md={12} lg={6}>
						{props.deviceData.deactivatedDt && (
							<Typography variant="subtitle2" gutterBottom>
								{t("device page:Deactivated date")}:&nbsp;
								{moment(props.deviceData.deactivatedDt).format("DD-MM-YYYY")}
							</Typography>
						)}
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs={12} md={12} lg={12}>
						<Typography variant="body2" gutterBottom>
							{t("device page:Enter phone numbers for notifications")}
						</Typography>
					</Grid>
					<Grid item xs={12} md={4} lg={4}>
						<FormControl className="w-100 mb-4">
							<MyInputLabel htmlFor="phone-number-1">{t("device page:Phone number")}&nbsp;1</MyInputLabel>
							<MyFilledInput
								startAdornment={
									<InputAdornment classes={{ root: "MuiInputAdornment-black" }} position="start">
										+994
									</InputAdornment>
								}
								inputProps={{ maxLength: 9 }}
								required
								id="phone-number-1"
								type="text"
								name="phone1"
								value={inputValue.phone1}
								onChange={handleInputValue}
								className="text-dark"
								disabled={globalState.isFetching}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} md={4} lg={4}>
						<FormControl className="w-100 mb-4">
							<MyInputLabel htmlFor="phone-number-2">{t("device page:Phone number")}&nbsp;2</MyInputLabel>
							<MyFilledInput
								startAdornment={
									<InputAdornment classes={{ root: "MuiInputAdornment-black" }} position="start">
										+994
									</InputAdornment>
								}
								inputProps={{ maxLength: 9 }}
								required
								id="phone-number-2"
								type="text"
								name="phone2"
								value={inputValue.phone2}
								onChange={handleInputValue}
								className="text-dark"
								disabled={globalState.isFetching}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} md={4} lg={4}>
						<FormControl className="w-100 mb-4">
							<MyInputLabel htmlFor="phone-number-3">{t("device page:Phone number")}&nbsp;3</MyInputLabel>
							<MyFilledInput
								startAdornment={
									<InputAdornment classes={{ root: "MuiInputAdornment-black" }} position="start">
										+994
									</InputAdornment>
								}
								inputProps={{ maxLength: 9 }}
								required
								id="phone-number-3"
								type="text"
								name="phone3"
								value={inputValue.phone3}
								onChange={handleInputValue}
								className="text-dark"
								disabled={globalState.isFetching}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} md={4} lg={4} className="d-flex align-items-center">
						<Typography variant="body2" gutterBottom>
							{t("device page:Select the notification type")}
						</Typography>
					</Grid>
					<Grid item xs={12} md={3} lg={3}>
						<FormControlLabel
							control={
								<Switch
									checked={inputValue.isCall}
									onChange={handleSwitchValue}
									name="isCall"
									color="primary"
									disabled={globalState.isFetching}
								/>
							}
							label={t("device page:Call")}
						/>
					</Grid>
					<Grid item xs={12} md={3} lg={3}>
						<FormControlLabel
							control={
								<Switch
									disabled={globalState.isFetching}
									checked={inputValue.isSms}
									onChange={handleSwitchValue}
									name="isSms"
									color="primary"
								/>
							}
							label="SMS"
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<MyButton
					className="mr-4 mb-2"
					variant="contained"
					style={{ fontSize: "0.7rem" }}
					onClick={saveButtonClicked}
					disabled={globalState.isFetching || props.isButtonDisabled}
				>
					{t("translation:Save")}
				</MyButton>
			</DialogActions>
		</MyDialog>
	);
};

export default React.memo(SettingDialog);
