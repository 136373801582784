import baseConfig from "assets/config/config";

class Settings {
	constructor() {
		this._activeTheme = baseConfig.theme.activeTheme;
		this._selectedLng = baseConfig.selectedLng;
		//todo: loading settings from server or local storage
	}

	get activeTheme() {
		// console.log('get activeTheme=', this._activeTheme);
		return this._activeTheme;
	}
	set activeTheme(activeTheme) {
		this._activeTheme = activeTheme;
		// console.log('set activeTheme=', this._activeTheme);
		//todo: saving
	}

	get selectedLng() {
		// console.log('get selectedLng=', this._selectedLng);
		return this._selectedLng;
	}
	set selectedLng(lng) {
		this._selectedLng = lng;
		// console.log('set selectedLng=', this._selectedLng);
		//todo: saving
	}

	// functions for saveing setting fields from server or local storage
}

export let settings = new Settings();
