import React, { useState, useCallback, useEffect } from "react";
import { findIndex } from "lodash";
import {
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Checkbox,
	CircularProgress,
	FormControl,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { TransitionUp } from "components/pages/common/TransitionUp";
import {
	MyIconButton,
	MyDialog,
	MyFilledInput,
	MyInputLabel,
	MyButton,
} from "components/Functional/material-ui-styled-Components";
import { fetchData } from "global/networking";
import { URLs } from "assets/config/config";
import { useSnackbar } from "notistack";
import NoDataFound from "components/pages/common/NoDataFound";
import { globalStore } from "global/store";

const EditGroupDialog = (props) => {
	const [globalState, globalDispatch] = globalStore.getStore();
	const [t] = useTranslation(["device page", "translation", "groups page"]);
	const [fetchGroups, setFetchGroups] = useState(false);
	const defaultInputValues = { groupName: props.groupData.name };
	const [inputValue, setInputValue] = useState(defaultInputValues);
	const [assignments, setAssignments] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);

	const canEditGroup = React.useMemo(() => {
		return assignments.some((item) => item.checked === true);
	}, [assignments]);

	const handleToggle = (value) => () => {
		const updateIndex = findIndex(assignments, { cusDeviceId: value });
		const newChecked = [
			...assignments.slice(0, updateIndex),
			{ ...assignments[updateIndex], checked: !assignments[updateIndex].checked },
			...assignments.slice(updateIndex + 1, assignments.length),
		];

		setAssignments(newChecked);
		setIsButtonDisabled(false);
	};

	useEffect(() => {
		setInputValue(defaultInputValues);
	}, [props.editDialogState]);

	const handleInputValue = (e) => {
		const value = e.target.value;
		setInputValue({
			...inputValue,
			[e.target.name]: value,
		});
		setIsButtonDisabled(false);
	};

	const closeDialog = () => {
		props.setEditDialogState(false);
	};

	// post group data
	const addButtonClicked = useCallback(() => {
		const token = `Bearer ${localStorage.getItem("token")}`;
		const requestBody = {
			group: {
				id: props.groupData.id,
				name: inputValue.groupName,
			},
			assignments: assignments.map(function (v) {
				delete v.name;
				return v;
			}),
		};
		const promise = fetchData(URLs.main + "groupCusDevice/saveGroup", {
			method: "POST",
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
			body: JSON.stringify(requestBody),
		})[0];
		promise
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.success) {
					setFetchGroups(true);
					enqueueSnackbar(`${t("groups page:Group updated successfully")}`, { variant: "success" });
					closeDialog();
				} else {
					enqueueSnackbar(data.errors[0].errorMsg, { variant: "error" });
				}
			})
			.catch((error) => {
				enqueueSnackbar(error.message, { variant: "error" });
				console.log("Request failed", error);
			});
	}, [assignments, inputValue]);

	// fetch group data when open dialog
	const fetchDeviceList = useCallback(() => {
		const token = `Bearer ${localStorage.getItem("token")}`;
		const promise = fetchData(URLs.main + `groupCusDevice/GetGroupAssignments/${props.groupData.id}`, {
			method: "GET",
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
		})[0];
		promise
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.success) {
					setAssignments(data.list);
				} else {
					enqueueSnackbar(data.errors[0].errorMsg, { variant: "error" });
				}
			})
			.catch((error) => {
				enqueueSnackbar(error.message, { variant: "error" });
				console.log("Request failed", error);
			});
	}, []);

	useEffect(() => {
		let mounted = true;

		if (mounted && props.editDialogState) {
			fetchDeviceList();
		}

		return function cleanup() {
			mounted = false;
		};
	}, [props.editDialogState]);

	// fetch group list again when updated and closed dialog
	useEffect(() => {
		if (fetchGroups && !props.editDialogState) {
			props.fetchGroupList();
		}
	}, [fetchGroups, props.editDialogState]);

	return (
		<MyDialog
			disableBackdropClick
			open={props.editDialogState}
			onClose={closeDialog}
			TransitionComponent={TransitionUp}
			aria-labelledby="add-new-group"
			fullWidth
			maxWidth="xs"
		>
			<DialogTitle id="add-new-group">
				<div className="d-flex justify-content-between align-items-center">
					<span>{t("translation:Edit")}</span>
					<MyIconButton aria-label="Close" onClick={closeDialog}>
						<Close />
					</MyIconButton>
				</div>
			</DialogTitle>
			<DialogContent>
				{globalState.isFetching ? (
					<div style={{ height: "4rem" }} className="d-flex justify-content-lg-center align-items-center">
						<CircularProgress size="3rem" />
					</div>
				) : assignments.length > 0 ? (
					<>
						<FormControl className="w-100">
							<MyInputLabel htmlFor="group-name">{t("groups page:Group name")}</MyInputLabel>
							<MyFilledInput
								required
								id="group-name"
								type="text"
								name="groupName"
								value={inputValue.groupName}
								onChange={handleInputValue}
								className="text-dark border-bottom-2 border-success"
							/>
						</FormControl>
						<List>
							{assignments.map((item, key) => {
								const labelId = `checkbox-list-label-${key}`;
								return (
									<ListItem
										className="border-bottom border-muted"
										key={key}
										role={undefined}
										dense
										button
										onClick={handleToggle(item.cusDeviceId)}
									>
										<ListItemIcon>
											<Checkbox
												edge="start"
												checked={item.checked}
												tabIndex={-1}
												disableRipple
												inputProps={{ "aria-labelledby": labelId }}
												className="text-success"
											/>
										</ListItemIcon>
										<ListItemText id={labelId} primary={item.name} />
									</ListItem>
								);
							})}
						</List>
					</>
				) : (
					<NoDataFound text={t("translation:No data was found")} />
				)}
			</DialogContent>
			<DialogActions>
				<MyButton
					className="mr-4 mb-2"
					variant="contained"
					style={{ fontSize: "0.7rem" }}
					color="primary"
					onClick={addButtonClicked}
					disabled={
						globalState.isFetching || !canEditGroup || inputValue.groupName === "" || isButtonDisabled
					}
				>
					{t("translation:Save")}
				</MyButton>
			</DialogActions>
		</MyDialog>
	);
};

export default React.memo(EditGroupDialog);
