import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components/macro";
import {
	CircularProgress,
	Typography,
	Grid,
	CardContent,
	Table,
	TableHead,
	TableRow,
	TableContainer,
	TableBody,
	TableCell,
	Box,
	SvgIcon,
} from "@material-ui/core";
import { MyDivider, MyButton, MyCard } from "components/Functional/material-ui-styled-Components";
import { useTranslation } from "react-i18next";
import PaymentDialog from "./PaymentDialog";
import Payments from "./Payments";
import PaymentHistory from "./PaymentHistory";
import { URLs } from "assets/config/config";
import { fetchData } from "global/networking";
import { useSnackbar } from "notistack";
import { sortTableColumn } from "global/utilFunctions";
import { colors } from "assets/config/theme";
import { globalStore } from "global/store";
import EmanatPDF from "assets/files/Emanat_odenish.pdf";
import MilliOnPDF from "assets/files/MilliOn_odenish.pdf";
import GetApp from "@material-ui/icons/GetApp";

export default function PaymentsPage() {
	const [t, i18n] = useTranslation("translation, payment page");
	const { enqueueSnackbar } = useSnackbar();
	const [paymentDialogState, setPaymentDialogState] = useState(false);
	const [paymentInfo, setPaymentInfo] = useState({
		balance: "",
		deviceCount: "",
		code: "",
		package: {
			name: "",
			price: "",
		},
	});
	const [nextPayments, setNextPayments] = useState([]);
	const [paymentHistory, setPaymentHistory] = useState([]);
	const [globalState, globalDispatch] = globalStore.getStore();
	const [isLoadingHistory, setIsLoadingHistory] = useState(false);
	const [historyPageOptions, setHistoryPageOptions] = useState({ count: 0 });

	// fetch payment history data
	const fetchPaymentHistory = useCallback(
		(currentPage = 1, pageSize = 10, sortOrder = { direction: "asc", name: "date" }) => {
			setIsLoadingHistory(true);
			const token = `Bearer ${localStorage.getItem("token")}`;
			const requestBody = {
				Pager: {
					CurrentPage: currentPage,
					PageSize: pageSize,
				},
			};
			const promise = fetchData(URLs.main + "Cash/GetlistByFilterPaged", {
				method: "POST",
				headers: {
					Authorization: token,
					"Content-type": "application/json",
				},
				body: JSON.stringify(requestBody),
			})[0];
			promise
				.then((res) => {
					return res.json();
				})
				.then((data) => {
					setIsLoadingHistory(false);
					if (data.success) {
						if (sortOrder) {
							setPaymentHistory(sortTableColumn(sortOrder, data.value?.list));
						} else {
							setPaymentHistory(data.value?.list);
						}
						setHistoryPageOptions({ count: data.value.totalCount });
					} else {
						enqueueSnackbar(data.errors[0].errorMsg, { variant: "error" });
					}
				})
				.catch((error) => {
					setIsLoadingHistory(false);
					enqueueSnackbar(error.message, { variant: "error" });
					console.log("Request failed", error);
				});
		},
		[]
	);

	// fetch next payments data
	const fetchNextPayments = useCallback(() => {
		const token = `Bearer ${localStorage.getItem("token")}`;
		const requestBody = {
			Pager: {
				CurrentPage: 1,
				PageSize: 100,
			},
		};
		const promise = fetchData(URLs.main + "CusDevice/GetPayInfoList", {
			method: "POST",
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
			body: JSON.stringify(requestBody),
		})[0];
		promise
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.success) {
					setNextPayments(data.value?.list);
					fetchPaymentHistory();
				} else {
					enqueueSnackbar(data.errors[0].errorMsg, { variant: "error" });
				}
			})
			.catch((error) => {
				enqueueSnackbar(error.message, { variant: "error" });
				console.log("Request failed", error);
			});
	}, []);

	// fetch payment info
	const fetchPaymentInfo = useCallback(() => {
		const token = `Bearer ${localStorage.getItem("token")}`;
		const promise = fetchData(URLs.main + "Customer/GetPaymentInfo", {
			method: "GET",
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
		})[0];
		promise
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.success) {
					setPaymentInfo({
						balance: data.value.balance,
						deviceCount: data.value.deviceCount,
						code: data.value.code,
						package: {
							name: data.value.package.name,
							price: data.value.package.price,
						},
					});
					fetchNextPayments();
				} else {
					enqueueSnackbar(data.errors[0].errorMsg, { variant: "error" });
				}
			})
			.catch((error) => {
				enqueueSnackbar(error.message, { variant: "error" });
				console.log("Request failed", error);
			});
	}, []);

	useEffect(() => {
		fetchPaymentInfo();
	}, []);

	return (
		<>
			{globalState.isFetching ? (
				<div className="d-flex h-100 justify-content-center align-items-center">
					<CircularProgress size="5rem" />
				</div>
			) : (
				<>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<MyCard wide="100%">
								<CardContent className="pl-2 pr-2 pl-sm-4 pr-sm-4">
									<Typography gutterBottom style={{ fontSize: "1.2rem", color: colors.black }}>
										{t("payment page:Customer code")}: {paymentInfo.code}
									</Typography>
									<TableContainer style={{ paddingLeft: "10px", paddingRight: "10px" }}>
										<Table aria-label="sticky table">
											<TableHead>
												<TableRow>
													<TableCell
														style={{
															backgroundColor: colors.white,
															color: colors.grayLightSeventh,
														}}
													>
														{t("payment page:The price of the tariff")}
													</TableCell>
													<TableCell
														style={{
															backgroundColor: colors.white,
															color: colors.grayLightSeventh,
														}}
													>
														{t("payment page:Device count")}
													</TableCell>
													<TableCell
														style={{
															backgroundColor: colors.white,
															color: colors.grayLightSeventh,
														}}
													>
														{t("payment page:Current balance")}
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody style={{ backgroundColor: colors.grayLightMain }}>
												<TableRow>
													<TableCell>{paymentInfo.package.price} AZN</TableCell>
													<TableCell>{paymentInfo.deviceCount}</TableCell>
													<TableCell>{paymentInfo.balance} AZN</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</TableContainer>

									<Grid container spacing={2} className="pt-4 pb-4">
										<Grid item xs={12} sm={12} md={6} lg={6}>
											<Typography
												variant="subtitle2"
												gutterBottom
												style={{ color: colors.blackOpacity50 }}
											>
												{t("payment page:Current tariff")}
											</Typography>
											<Typography variant="body2" gutterBottom style={{ color: colors.black }}>
												{paymentInfo.package.name}
											</Typography>
											<Typography
												variant="caption"
												gutterBottom
												style={{ color: colors.blackOpacity50 }}
											>
												{t("payment page:The service is updated every 30 days")}
											</Typography>
										</Grid>
										<Grid
											item
											xs={12}
											sm={12}
											md={6}
											lg={6}
											className="d-flex justify-content-lg-end align-items-lg-end"
										>
											<PaymentLink
												target="_blank"
												rel="noopener noreferrer"
												href="https://expresspay.az/payment/service/1023"
											>
												<MyButton
													variant="contained"
													size="small"
													// onClick={() => setPaymentDialogState(true)}
												>
													{t("payment page:Pay online")}
												</MyButton>
											</PaymentLink>
										</Grid>
									</Grid>
									<MyDivider className="mb-4 mt-2" />
									<Typography
										variant="subtitle2"
										gutterBottom
										style={{ color: colors.blackOpacity50 }}
									>
										{t("payment page:Payment channels")}
									</Typography>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={6} md={6} lg={6}>
											<Typography
												variant="subtitle2"
												gutterBottom
												style={{ color: colors.black, marginBottom: "10px" }}
											>
												{t("payment page:Terminal network")}:
											</Typography>
											<ul className="pl-4 text-dark">
												<li>
													<a
														href={EmanatPDF}
														target="_blank"
														style={{
															fontWeight: "Bold",
															color: "#66BB6A",
														}}
													>
														E-manat
													</a>
												</li>
												<li>
													<a
														href={MilliOnPDF}
														target="_blank"
														style={{
															fontWeight: "Bold",
															color: "#66BB6A",
														}}
													>
														MilliÖn
													</a>
												</li>
												<li>ExpressPay</li>
												<li>KassamAz</li>
											</ul>
										</Grid>
										<Grid item xs={12} sm={6} md={6} lg={6}>
											<Typography
												variant="subtitle2"
												gutterBottom
												style={{ color: colors.black, marginBottom: "10px" }}
											>
												{t("payment page:Websites and Mobile Applications")}:
											</Typography>
											<ul className="pl-4 text-dark">
												<li>ExpressPay</li>
												<li>E-Pul</li>
												<li>Portmanat</li>
												<li>CİB</li>
												<li>Pasha Mobile Bank</li>
												<li>İBA Pay (Beynəlxalq Bank)</li>
												<li>BirBank (Kapital Bank)</li>
											</ul>
										</Grid>
									</Grid>
								</CardContent>
							</MyCard>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<MyCard wide="100%">
								<CardContent>
									<Payments nextPayments={nextPayments} />
								</CardContent>
							</MyCard>
						</Grid>
					</Grid>
					<PaymentHistory
						isLoadingHistory={isLoadingHistory}
						setIsLoading={setIsLoadingHistory}
						paymentHistory={paymentHistory}
						historyPageOptions={historyPageOptions}
						fetchPaymentHistory={fetchPaymentHistory}
					/>
				</>
			)}

			<PaymentDialog paymentDialogState={paymentDialogState} setPaymentDialogState={setPaymentDialogState} />
		</>
	);
}
let PaymentLink = styled("a")`
	&:hover {
		text-decoration: none;
	}
`;
