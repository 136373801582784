import React from "react";
import { useTranslation } from "react-i18next";
import { CardContent, Grid, Tooltip, Typography } from "@material-ui/core";
import { MyCard, MyDivider } from "components/Functional/material-ui-styled-Components";
import windIcon from "assets/img/icons/climateIcons/windSpeed.svg";
import windDirection from "assets/img/icons/climateIcons/windDirection.svg";
import rainDrop from "assets/img/icons/climateIcons/rainDrop.svg";
import humidity from "assets/img/icons/climateIcons/humidity.svg";
import pressure from "assets/img/icons/climateIcons/pressure.svg";
import Carousel from "react-elastic-carousel";
import moment from "moment";
import { constants } from "assets/config/constants";

const breakPoints = [
	{ width: 1, itemsToShow: 3 },
	{ width: 350, itemsToShow: 4 },
	{ width: 550, itemsToShow: 5 },
];

export default function Forecast(props) {
	const [t, i18n] = useTranslation(["climate page"]);

	const noonForecast = props.hourlyForecast.filter((item) => moment(item.time).format("HH:mm") === "15:00");
	const nightForecast = props.hourlyForecast.filter((item) => moment(item.time).format("HH:mm") === "03:00");
	console.log(props);
	return (
		<MyCard wide="100%">
			<CardContent>
				<Grid container className="align-items-center">
					<Grid item xs={12} md={12} lg={4}>
						<Typography className="text-dark" variant="h5" gutterBottom>
							{props.location.name}, <span>{props.location.country}</span>
						</Typography>
						<Typography className="text-dark" variant="body1" gutterBottom>
							{t("climate page:Noon")} {noonForecast[0]?.temperature}°C - {t("climate page:Night")}{" "}
							{nightForecast[0]?.temperature}°C
						</Typography>
						<div className="d-flex my-3">
							<img
								width={45}
								className="mr-2"
								src={
									require(`assets/img/icons/climateIcons/${props.hourlyForecast[0]?.symbol}.svg`)
										.default
								}
							/>
							<Typography className="text-dark m-0" variant="h2" gutterBottom>
								{props.hourlyForecast[0]?.temperature}°C
							</Typography>
						</div>
						<div className="d-flex">
							<Tooltip title={t("climate page:Wind speed")}>
								<Typography
									className="text-dark d-flex align-items-center w-33"
									variant="body1"
									gutterBottom
								>
									<img width={20} className="mr-1" src={windIcon} />{" "}
									{props.hourlyForecast[0]?.windSpeed} km/h
								</Typography>
							</Tooltip>
							<Tooltip title={t("climate page:Wind direction")}>
								<Typography
									className=" text-dark d-flex align-items-center w-33"
									variant="body1"
									gutterBottom
								>
									<img width={20} className="mr-1" src={windDirection} />{" "}
									{props.hourlyForecast[0]?.windDir}°
								</Typography>
							</Tooltip>
							<Tooltip title={t("climate page:Accumulated precipitation")}>
								<Typography
									className=" text-dark d-flex align-items-center w-33"
									variant="body1"
									gutterBottom
								>
									<img width={20} className="mr-1" src={rainDrop} />{" "}
									{props.hourlyForecast[0]?.precipAccum} mm
								</Typography>
							</Tooltip>
						</div>
						<div className="d-flex">
							<Tooltip title={t("climate page:Precipitation probability")}>
								<Typography
									className="text-dark d-flex align-items-center w-33"
									variant="body1"
									gutterBottom
								>
									<img width={20} className="mr-1" src={rainDrop} />{" "}
									{props.hourlyForecast[0]?.precipProb}%
								</Typography>
							</Tooltip>
							<Tooltip title={t("climate page:Humidity")}>
								<Typography
									className=" text-dark d-flex align-items-center w-33"
									variant="body1"
									gutterBottom
								>
									<img width={20} className="mr-1" src={humidity} />{" "}
									{props.hourlyForecast[0]?.relHumidity} %
								</Typography>
							</Tooltip>
							<Tooltip title={t("climate page:Pressure")}>
								<Typography
									className=" text-dark d-flex align-items-center w-33"
									variant="body1"
									gutterBottom
								>
									<img width={20} className="mr-1" src={pressure} />{" "}
									{props.hourlyForecast[0]?.pressure} hPa
								</Typography>
							</Tooltip>
						</div>
					</Grid>
					<Grid item xs={12} md={12} lg={8}>
						<Typography className="text-dark mb-3 mt-lg-0 mt-4" variant="h6" gutterBottom>
							{t("climate page:Hourly forecast")}
						</Typography>

						<Carousel
							showArrows={document.body.clientWidth < constants.responsiveSizes.md ? false : true}
							pagination={false}
							breakPoints={breakPoints}
						>
							{props.hourlyForecast.map((item, key) => (
								<div key={key} className="d-flex flex-column justify-content-center ">
									<Typography className="text-dark mt-1" variant="subtitle1" gutterBottom>
										{moment(item.time).format("HH:mm")}
									</Typography>
									<div className="d-flex align-items-center">
										<img
											width={40}
											height={26}
											className="mr-2"
											src={require(`assets/img/icons/climateIcons/${item.symbol}.svg`).default}
										/>
										<Typography className="text-dark my-1" variant="body1" gutterBottom>
											{item.temperature}°C
										</Typography>
									</div>
									<div className="d-flex align-items-center ">
										<Tooltip title={t("climate page:Wind speed")}>
											<Typography
												className="text-dark d-flex align-items-center mt-1"
												variant={
													document.body.clientWidth < constants.responsiveSizes.md
														? "caption"
														: "body1"
												}
												gutterBottom
											>
												<img width={20} height={20} className="mr-2" src={windIcon} />{" "}
												{item.windSpeed} km/h
											</Typography>
										</Tooltip>
									</div>
									<div className="d-flex align-items-center ">
										<Tooltip title={t("climate page:Wind direction")}>
											<Typography
												className=" text-dark d-flex align-items-center mt-1"
												variant={
													document.body.clientWidth < constants.responsiveSizes.md
														? "caption"
														: "body1"
												}
												gutterBottom
											>
												<img width={20} height={20} className="mr-2" src={windDirection} />{" "}
												{item.windDir}°
											</Typography>
										</Tooltip>
									</div>
									<div className="d-flex align-items-center ">
										<Tooltip title={t("climate page:Accumulated precipitation")}>
											<Typography
												className="text-dark d-flex align-items-center mt-1"
												variant={
													document.body.clientWidth < constants.responsiveSizes.md
														? "caption"
														: "body1"
												}
												gutterBottom
											>
												<img width={20} height={20} className="mr-2" src={rainDrop} />{" "}
												{item.precipAccum} mm
											</Typography>
										</Tooltip>
									</div>
									<div className="d-flex align-items-center ">
										<Tooltip title={t("climate page:Precipitation probability")}>
											<Typography
												className="text-dark d-flex align-items-center mt-1"
												variant={
													document.body.clientWidth < constants.responsiveSizes.md
														? "caption"
														: "body1"
												}
												gutterBottom
											>
												<img width={20} height={20} className="mr-2" src={rainDrop} />{" "}
												{item.precipProb} %
											</Typography>
										</Tooltip>
									</div>
									<div className="d-flex align-items-center ">
										<Tooltip title={t("climate page:Humidity")}>
											<Typography
												className="text-dark d-flex align-items-center mt-1"
												variant={
													document.body.clientWidth < constants.responsiveSizes.md
														? "caption"
														: "body1"
												}
												gutterBottom
											>
												<img width={20} height={20} className="mr-2" src={humidity} />{" "}
												{item.relHumidity} %
											</Typography>
										</Tooltip>
									</div>
									<div className="d-flex align-items-center ">
										<Tooltip title={t("climate page:Pressure")}>
											<Typography
												className="text-dark d-flex align-items-center mt-1"
												variant={
													document.body.clientWidth < constants.responsiveSizes.md
														? "caption"
														: "body1"
												}
												gutterBottom
											>
												<img width={20} height={20} className="mr-2" src={pressure} />{" "}
												{item.pressure}hPa
											</Typography>
										</Tooltip>
									</div>
								</div>
							))}
						</Carousel>
					</Grid>
				</Grid>

				<MyDivider className="my-4" />

				<Grid container>
					<Grid item xs={12}>
						<Typography className="text-dark mb-3" variant="h6" gutterBottom>
							{t("climate page:Daily forecast")}
						</Typography>
						<div className="d-flex justify-content-around flex-wrap">
							{props.dailyForecast.map((item, key) => (
								<div key={key} className="d-flex flex-column px-3 mb-5 w-14">
									<Typography className="text-dark m-0" variant="subtitle1" gutterBottom>
										{moment(item.date).format("DD.MM.YYYY")}
									</Typography>
									<img
										width={50}
										height={50}
										className=""
										src={require(`assets/img/icons/climateIcons/${item.symbol}.svg`).default}
									/>
									<div className="d-flex">
										<Typography className="text-dark mr-2" variant="body1" gutterBottom>
											{item.maxTemp}°C
										</Typography>
										<Typography className="text-dark-50 m-0" variant="body1" gutterBottom>
											{item.minTemp}°C
										</Typography>
									</div>
									<Tooltip title={t("climate page:Wind speed")}>
										<Typography
											className="text-dark d-flex align-items-center "
											variant={
												document.body.clientWidth < constants.responsiveSizes.md
													? "caption"
													: "body1"
											}
											gutterBottom
										>
											<img width={22} height={22} className="mr-1" src={windIcon} />{" "}
											{item.maxWindSpeed} km/h
										</Typography>
									</Tooltip>
									<Tooltip title={t("climate page:Wind direction")}>
										<Typography
											className="text-dark d-flex align-items-center mt-1"
											variant={
												document.body.clientWidth < constants.responsiveSizes.md
													? "caption"
													: "body1"
											}
											gutterBottom
										>
											<img width={22} height={22} className="mr-1" src={windDirection} />(
											{item.windDir}°)
										</Typography>
									</Tooltip>
									<Tooltip title={t("climate page:Accumulated precipitation")}>
										<Typography
											className="text-dark d-flex align-items-center mt-1"
											variant={
												document.body.clientWidth < constants.responsiveSizes.md
													? "caption"
													: "body1"
											}
											gutterBottom
										>
											<img width={22} height={22} className="mr-1" src={rainDrop} />{" "}
											{item.precipAccum} mm
										</Typography>
									</Tooltip>
									<Tooltip title={t("climate page:Precipitation probability")}>
										<Typography
											className="text-dark d-flex align-items-center mt-1"
											variant={
												document.body.clientWidth < constants.responsiveSizes.md
													? "caption"
													: "body1"
											}
											gutterBottom
										>
											<img width={22} height={22} className="mr-1" src={rainDrop} />{" "}
											{item.precipProb} %
										</Typography>
									</Tooltip>
									<Tooltip title={t("climate page:Humidity")}>
										<Typography
											className="text-dark d-flex align-items-center mt-1"
											variant={
												document.body.clientWidth < constants.responsiveSizes.md
													? "caption"
													: "body1"
											}
											gutterBottom
										>
											<img width={22} height={22} className="mr-1" src={humidity} />{" "}
											{item.maxRelHumidity}-{item.minRelHumidity} %
										</Typography>
									</Tooltip>
									<Tooltip title={t("climate page:Pressure")}>
										<Typography
											className="text-dark d-flex align-items-center mt-1"
											variant={
												document.body.clientWidth < constants.responsiveSizes.md
													? "caption"
													: "body1"
											}
											gutterBottom
										>
											<img width={22} height={22} className="mr-1" src={pressure} />{" "}
											{item.pressure} hPa
										</Typography>
									</Tooltip>
								</div>
							))}
						</div>
					</Grid>
				</Grid>
			</CardContent>
		</MyCard>
	);
}
