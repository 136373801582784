import React, { useReducer, useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { ThemeProvider as StyledThemeProvider, createGlobalStyle } from "styled-components/macro";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import baseConfig from "assets/config/config";
import { constants } from "assets/config/constants";
import { settings } from "global/settings";
import actionTypes from "global/actionTypes";
import { toggleTheme } from "global/utilFunctions";
import { stateReducer } from "global/reducers";
import { globalStore } from "global/store";
import Authentication from "components/Functional/Authentication";
import Header from "components/Functional/Header/Header";
import MainMenuSidebar from "components/Functional/Menu";
import Pages from "components/Functional/Pages";
import LoginPage from "components/pages/LoginPage";
import RegisterPage from "components/pages/RegisterPage";
import ForgetPasswordPage from "components/pages/ForgetPasswordPage";
import FAQ from "components/Functional/Header/FAQ";
import { availablePages } from "assets/config/config";
import { StylesProvider } from "@material-ui/styles";

const theme = createMuiTheme({
	activeTheme: settings.activeTheme,
	// toggleTheme: () => setTheme(toggleTheme),
	typography: {
		fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
	},
	palette: baseConfig.palette,
});

export default function App(props) {
	const [state, dispatch] = useReducer(stateReducer, {
		isFetching: baseConfig.isFetching,
		session: baseConfig.session,
		user: baseConfig.user,
		menuState: baseConfig.menuState,
		devicesData: baseConfig.devicesData,
		groupsData: baseConfig.groupsData,
	});
	globalStore.setState(state);
	globalStore.setDispatch(dispatch);

	useEffect(() => {
		window.addEventListener("resize", () => {
			dispatch({ type: actionTypes.toggleMenu });
		});
		// return window.removeEventListener('resize', updateMenuState);
	}, []);

	let openPages = null;
	if (state.session.sessionStatus === constants.sessionStatus.inactive)
		openPages = (
			<div className="d-flex flex-grow-1 justify-content-center align-items-center py-5 pt-xs-0 mt-5 mt-lg-2 mt-xs-0 px-2">
				<Switch>
					<Route path="/login" component={LoginPage} />
					<Route path="/register" component={RegisterPage} />
					<Route path="/resetPassword" component={ForgetPasswordPage} />
					<Route path={availablePages[8]} component={FAQ} />
					<Route component={LoginPage} />
				</Switch>
			</div>
		);

	return (
		<StylesProvider injectFirst>
			<ThemeProvider theme={theme}>
				<StyledThemeProvider theme={theme}>
					<GlobalStyle />
					<Header menuState={state.menuState} />
					<Authentication sessionStatus={state.session.sessionStatus} authSet={dispatch}>
						<MainMenuSidebar menuState={state.menuState} />
						<Pages menuState={state.menuState} />
					</Authentication>
					{openPages}
				</StyledThemeProvider>
			</ThemeProvider>
		</StylesProvider>
	);
}

// const ContainerWithHistory = withRouter(Container);

const GlobalStyle = createGlobalStyle`
	body {
		background-color: ${(props) => props.theme.activeTheme.body.backgroundColor};
	}
`;
