import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
	MyCard,
	MyCardHeader,
	MyInputLabel,
	MyFilledInput,
	MySelect,
	MyMenuItem,
	MyArrowDropDownRoundedIcon,
	MyFab,
	MyAccordion,
} from "components/Functional/material-ui-styled-Components";
import { Send, Lock } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
	CardContent,
	FormControl,
	CardActions,
	CircularProgress,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from "@material-ui/core";
import { URLs } from "assets/config/config";
import { fetchData } from "global/networking";
import { constants } from "assets/config/constants";
import styled from "styled-components/macro";
import { useSnackbar } from "notistack";
import { globalStore } from "global/store";

export default function HowToPage() {
	const [t, i18n] = useTranslation(["translation", "howto page"]);
	const [globalState, globalDispatch] = globalStore.getStore();
	const [isDescLoading, setIsDescLoading] = useState(false);
	const [inputValue, setInputValue] = useState(1);
	const [plantTypes, setPlantTypes] = useState([]);
	const [plantInfo, setPlantInfo] = useState([]);
	const [checkChange, setCheckChange] = useState(false);
	const [isActive, setIsActive] = useState(true);
	const { enqueueSnackbar } = useSnackbar();

	// fetch plant type list for dropdown
	const fetchPlantTypes = useCallback(() => {
		const token = `Bearer ${localStorage.getItem("token")}`;
		const requestBody = {
			Pager: {
				CurrentPage: 1,
				PageSize: 100,
			},
		};
		const promise = fetchData(URLs.main + "Plant/GetListByFilterPaged", {
			method: "POST",
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
			body: JSON.stringify(requestBody),
		})[0];
		promise
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.success) {
					setPlantTypes(data.value.list);
					fetchPlantInfo();
				} else {
					enqueueSnackbar(data.errors[0].errorMsg, { variant: "error" });
				}
			})
			.catch((error) => {
				enqueueSnackbar(error.message, { variant: "error" });
				console.log("Request failed", error);
			});
	}, []);

	// check if user is active or deactive to disable or enable pages
	const checkIfUserIsActive = useCallback(() => {
		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
				"Content-type": "application/json",
			},
		};

		const promise = fetchData(URLs.main + `Customer/IsActive`, requestOptions)[0];
		promise
			.then(async (response) => {
				const data = await response.json();
				setIsActive(data.value);
			})
			.catch((error) => {
				console.error("There was an error!", error);
			});
	}, []);

	useEffect(() => {
		fetchPlantTypes();
		checkIfUserIsActive();
	}, []);

	const handleInputValue = useCallback(
		(e) => {
			const value = e.target.value;
			setInputValue(value);
			setCheckChange(true);
		},
		[inputValue]
	);

	useEffect(() => {
		if (checkChange && !isDescLoading) {
			fetchPlantInfo();
		}
	}, [inputValue, checkChange]);

	// fetch plant info
	const fetchPlantInfo = useCallback(() => {
		setIsDescLoading(true);

		const token = `Bearer ${localStorage.getItem("token")}`;
		const requestBody = {
			includeHeader: true,
			value: {
				plantId: inputValue,
			},
			Pager: {
				CurrentPage: 1,
				PageSize: 100,
			},
		};
		const promise = fetchData(URLs.main + "PlantHeader/GetListByFilterPaged", {
			method: "POST",
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
			body: JSON.stringify(requestBody),
		})[0];
		promise
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				setIsDescLoading(false);
				if (data.success) {
					setPlantInfo(data.value.list);
				} else {
					enqueueSnackbar(data.errors[0].errorMsg, { variant: "error" });
				}
			})
			.catch((error) => {
				setIsDescLoading(false);
				enqueueSnackbar(error.message, { variant: "error" });
				console.log("Request failed", error);
			});
	}, [inputValue]);

	// const sendButtonClicked = useCallback(() => {
	// 	fetchPlantInfo();
	// }, [inputValue]);

	return globalState.isFetching ? (
		<div className="d-flex h-100 justify-content-center align-items-center">
			<CircularProgress size="5rem" />
		</div>
	) : isActive ? (
		<>
			<MyCard className="mb-3">
				<MyCardHeader title={t("howto page:Select plant type")} />
				<div className="d-flex align-items-start">
					<CardContent className="pt-0 flex-grow-1">
						<FormControl className="w-100">
							<MyInputLabel htmlFor="plant-type">{t("howto page:Plant type")}</MyInputLabel>
							<MySelect
								input={
									<MyFilledInput
										onChange={handleInputValue}
										id="plant-type"
										name="inputValue"
										value={inputValue}
									/>
								}
								IconComponent={MyArrowDropDownRoundedIcon}
							>
								{plantTypes?.map((x, key) => (
									<MyMenuItem key={key} className="text-dark" value={x.id}>
										{x.name}
									</MyMenuItem>
								))}
							</MySelect>
						</FormControl>
					</CardContent>

					{/* <CardActions className='pr-3'>
							<MyFab size='small' color='primary' onClick={sendButtonClicked} disabled={!inputValue}>
								<Send />
							</MyFab>
						</CardActions> */}
				</div>
			</MyCard>
			{isDescLoading ? (
				<div className="d-flex h-50 justify-content-center align-items-center">
					<CircularProgress size="5rem" />
				</div>
			) : (
				plantInfo.length > 0 &&
				plantInfo.map((item, key) => (
					<MyAccordion key={key}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon className="text-dark" />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography className="text-dark px-2" variant="subtitle2">
								<div dangerouslySetInnerHTML={{ __html: item.title }} />
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography className="text-dark px-2" variant="body2">
								<DivStyle dangerouslySetInnerHTML={{ __html: item.info }} />
							</Typography>
						</AccordionDetails>
					</MyAccordion>
				))
			)}
		</>
	) : (
		<div className="d-flex flex-column justify-content-center align-items-center p-5 text-dark">
			<Lock style={{ fontSize: 100 }} />
			<p className={"mt-3"}>{t("device page:Insufficient balance")}</p>
		</div>
	);
}

const DivStyle = styled("div")`
	img {
		margin: 10px;
		max-width: 90%;
		max-height: 200px;
		border-radius: 5px;
	}
`;
