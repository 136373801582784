import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MyFab } from "components/Functional/material-ui-styled-Components";
import styled from "styled-components";
import { Grid, CircularProgress, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { globalStore } from "global/store";
import DeviceCard from "./DeviceCard";
import NoDataFound from "components/pages/common/NoDataFound";
import AddDialog from "./AddDialog";
import actionTypes from "global/actionTypes";
import { colors } from "assets/config/theme";
import { constants } from "assets/config/constants";

const DevicePage = (props) => {
	const [globalState, globalDispatch] = globalStore.getStore();
	const [t, i18n] = useTranslation(["translation"]);
	const [addDialogState, setAddDialogState] = useState(false);

	const openAddDialog = () => {
		setAddDialogState(true);
	};

	return (
		<>
			<Tooltip title={t("device page:Add new device")}>
				<MyAddButton disabled aria-label="add" onClick={openAddDialog}>
					<AddIcon />
				</MyAddButton>
			</Tooltip>

			<AddDialog
				fetchDeviceList={props.fetchDeviceList}
				setAddDialogState={setAddDialogState}
				addDialogState={addDialogState}
			/>

			{globalState.isFetching ? (
				<div className="d-flex h-100 justify-content-center align-items-center">
					<CircularProgress size="5rem" />
				</div>
			) : globalState.devicesData.length > 0 ? (
				<Grid container spacing={3}>
					{globalState.devicesData.map((item, key) => (
						<DeviceCard
							key={key}
							deviceIdx={key}
							deviceData={item}
							fetchDeviceList={props.fetchDeviceList}
						/>
					))}
				</Grid>
			) : (
				<NoDataFound text={t("translation:No data was found")} />
			)}
		</>
	);
};

const MyAddButton = styled(MyFab)`
	position: fixed;
	bottom: 3rem;
	right: 3rem;
	z-index: 2;
	color: ${(prop) => (prop.theme.activeTheme.name === "dark" ? colors.black : colors.grayMain)};
	background-color: ${(prop) => (prop.theme.activeTheme.name === "dark" ? colors.white : colors.white)};
`;

export default DevicePage;
