import React, { useState, useCallback, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import _ from "lodash";
import {
	MyCard,
	MyCardHeader,
	MyInputLabel,
	MyFilledInput,
	MyButton,
	MySelect,
	MyMenuItem,
	MyArrowDropDownRoundedIcon,
	MyIconButton,
} from "components/Functional/material-ui-styled-Components";
import {
	CardContent,
	FormControl,
	CardActions,
	InputAdornment,
	Button,
	FormHelperText,
	Grid,
	Checkbox,
	FormControlLabel,
	Box,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { globalStore } from "global/store";
import { fetchData } from "global/networking";
import { URLs } from "assets/config/config";
import { useSnackbar } from "notistack";
import { green } from "@material-ui/core/colors";
import { colors } from "assets/config/theme";
import { Close } from "@material-ui/icons";

const defaultInputValues = {
	username: "",
	password: "",
	passwordAgain: "",
	address: {},
	msisdn: "",
	lastName: "",
	firstName: "",
};

export default function RegisterPage(props) {
	const [inputValue, setInputValue] = useState(defaultInputValues);
	const [redirect, setRedirect] = useState(false);
	const [cityData, setCityData] = useState([]);
	const [t, i18n] = useTranslation(["translation", "login page"]);
	const [state, dispatch] = globalStore.getStore();
	const { enqueueSnackbar } = useSnackbar();
	const [passwordsAreNotSame, setPasswordsAreNotSame] = useState(false);
	const [usernameAlreadyAvailable, setUsernameAlreadyAvailable] = useState(false);
	const [phoneNumberAlreadyAvailable, setPhoneNumberAlreadyAvailable] = useState(false);
	const [openPopper, setOpenPopper] = useState(false);
	const [acceptConditions, setAcceptConditions] = useState(false);
	const [termsConditions, setTermsConditions] = useState([]);

	const handleInputValue = (e) => {
		const value = e.target.value;
		setInputValue({
			...inputValue,
			[e.target.name]: value,
		});
	};

	const fetchCityList = useCallback(() => {
		const promise = fetchData(URLs.main + "City/GetAll", {
			method: "GET",
			headers: {
				"Content-type": "application/json",
			},
		})[0];
		promise
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.success) {
					setCityData(data.list);
				} else {
					enqueueSnackbar(data.errors[0].errorMsg, { variant: "error" });
				}
			})
			.catch((error) => {
				enqueueSnackbar(error.message, { variant: "error" });
				console.log("Request failed", error);
			});
	}, []);

	const fetchTermsConditions = useCallback(() => {
		const promise = fetchData(URLs.main + "TermsConditions/Get", {
			method: "GET",
			headers: {
				"Content-type": "application/json",
			},
		})[0];
		promise
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.success) {
					setTermsConditions(data.value);
				} else {
					enqueueSnackbar(data.errors[0].errorMsg, { variant: "error" });
				}
			})
			.catch((error) => {
				enqueueSnackbar(error.message, { variant: "error" });
				console.log("Request failed", error);
			});
	}, []);

	useEffect(() => {
		fetchCityList();
		fetchTermsConditions();
	}, []);

	//check if password and passwordAgain are same
	const checkPasswords = () => {
		inputValue.password === inputValue.passwordAgain ||
		inputValue.password === "" ||
		inputValue.passwordAgain === ""
			? setPasswordsAreNotSame(false)
			: setPasswordsAreNotSame(true);
	};

	useEffect(() => {
		checkPasswords();
	}, [inputValue.password, inputValue.passwordAgain]);

	const registerSubmit = () => {
		if (inputValue.password === inputValue.passwordAgain) {
			if (!_.isEmpty(inputValue.address) && isAllInputsFilled()) {
				const promise = fetchData(URLs.main + "register", {
					method: "POST",
					headers: {
						"Content-type": "application/json",
					},
					body: JSON.stringify({
						username: inputValue.username,
						password: inputValue.password,
						value: {
							address: inputValue.address.name,
							cityId: inputValue.address.id,
							msisdn: inputValue.msisdn,
							lastName: inputValue.lastName,
							firstName: inputValue.firstName,
						},
					}),
				})[0];
				promise
					.then((res) => {
						return res.json();
					})
					.then((data) => {
						if (data.success) {
							enqueueSnackbar(`${t("login page:Register successfully")}`, { variant: "success" });
							setRedirect(true);
						} else {
							if (data.errors.length === 2) {
								setUsernameAlreadyAvailable(true);
								setPhoneNumberAlreadyAvailable(true);
								setTimeout(() => {
									setUsernameAlreadyAvailable(false);
									setPhoneNumberAlreadyAvailable(false);
								}, 3000);
							} else if (data.errors[0].error === "duplicate_username") {
								setUsernameAlreadyAvailable(true);
								setTimeout(() => {
									setUsernameAlreadyAvailable(false);
								}, 3000);
							} else if (data.errors[0].error === "duplicate_msisdn") {
								setPhoneNumberAlreadyAvailable(true);
								setTimeout(() => {
									setPhoneNumberAlreadyAvailable(false);
								}, 3000);
							}
						}
					})
					.catch((error) => {
						console.log("Request failed", error);
					});
			} else {
				enqueueSnackbar(`${t("login page:Fill all inputs")}`, { variant: "error" });
			}
		} else {
			enqueueSnackbar(`${t("login page:Password error")}`, { variant: "error" });
		}
	};

	const isAllInputsFilled = () => {
		return !(
			inputValue.username === "" ||
			inputValue.firstName === "" ||
			inputValue.lastName === "" ||
			inputValue.msisdn === "" ||
			inputValue.address === "" ||
			inputValue.password === "" ||
			inputValue.passwordAgain === ""
		);
	};

	if (redirect) {
		return <Redirect to="/login" />;
	}

	const handleChange = () => {
		setAcceptConditions(!acceptConditions);
	};

	const handleOpenPopper = () => {
		setOpenPopper(true);
	};

	const handleClosePopper = () => {
		setOpenPopper(false);
	};

	return (
		<>
			<Popper style={{ display: openPopper ? "inline" : "none" }}>
				<MyIconButton
					aria-label="Close"
					onClick={handleClosePopper}
					style={{ position: "sticky", left: "90%" }}
					className="sticky-top"
				>
					<Close className="bg-danger text-white rounded-circle" />
				</MyIconButton>
				<div>
					<h3 dangerouslySetInnerHTML={{ __html: termsConditions.name }} />
					<p dangerouslySetInnerHTML={{ __html: termsConditions.description }} />
				</div>
			</Popper>
			<MyCard className="mx-auto mt-0 mt-md-3 mt-xs-0">
				<MyCardHeader subheader={t("login page:Register")} />
				<CardContent className="pt-0">
					<form onSubmit={registerSubmit} action="/" method="POST" autoComplete="off">
						<Grid container spacing={2} className="justify-content-center">
							<Grid item xs={12} md={6} lg={6}>
								<FormControl className="w-100 ">
									<MyInputLabel htmlFor="first-name-input">{t("login page:First name")}</MyInputLabel>
									<MyFilledInput
										onChange={handleInputValue}
										id="first-name-input"
										name="firstName"
										value={inputValue.firstName}
										aria-describedby="login-error-text"
										disabled={state.isFetching}
									/>
								</FormControl>
							</Grid>

							<Grid item xs={12} md={6} lg={6}>
								<FormControl className="w-100 ">
									<MyInputLabel htmlFor="last-name-input">{t("login page:Last name")}</MyInputLabel>
									<MyFilledInput
										onChange={handleInputValue}
										id="last-name-input"
										name="lastName"
										value={inputValue.lastName}
										aria-describedby="login-error-text"
										disabled={state.isFetching}
									/>
								</FormControl>
							</Grid>

							<Grid item xs={12} md={12} lg={12}>
								<FormControl error={usernameAlreadyAvailable} className="w-100 ">
									<MyInputLabel error={usernameAlreadyAvailable} htmlFor="username-input">
										{t("login page:Username")}
									</MyInputLabel>
									<MyFilledInput
										onChange={handleInputValue}
										id="username-input"
										name="username"
										value={inputValue.username}
										aria-describedby="login-error-text"
										disabled={state.isFetching}
									/>
									{usernameAlreadyAvailable ? (
										<FormHelperText id="login-error-text">
											{t("login page:Already available")}
										</FormHelperText>
									) : null}
								</FormControl>
							</Grid>

							<Grid item xs={12} md={6} lg={6}>
								<FormControl
									error={phoneNumberAlreadyAvailable || inputValue.msisdn.length !== 9 ? true : false}
									className="w-100 "
								>
									<MyInputLabel error={phoneNumberAlreadyAvailable} htmlFor="number-input">
										{t("login page:Phone number")}
									</MyInputLabel>
									<MyFilledInputTypeNmber
										startAdornment={
											<InputAdornment
												classes={{ root: "MuiInputAdornment-black" }}
												position="start"
											>
												+994
											</InputAdornment>
										}
										onChange={handleInputValue}
										id="number-input"
										name="msisdn"
										inputProps={{ maxLength: 9, minLength: 9 }}
										value={inputValue.msisdn}
										aria-describedby="login-error-text"
										disabled={state.isFetching}
										type="number"
									/>
									{phoneNumberAlreadyAvailable ? (
										<FormHelperText id="login-error-text">
											{t("login page:Already available")}
										</FormHelperText>
									) : null}
									{inputValue.msisdn.length === 0 ? (
										false
									) : inputValue.msisdn.length !== 9 ? (
										<FormHelperText id="login-error-text">
											{t("login page:Wrong phone number")}
										</FormHelperText>
									) : null}
								</FormControl>
							</Grid>

							<Grid item xs={12} md={6} lg={6}>
								<FormControl className="w-100">
									<MyInputLabel htmlFor="address-input">{t("login page:City/District")}</MyInputLabel>
									<MySelect
										disabled={state.isFetching}
										input={
											<MyFilledInput
												onChange={handleInputValue}
												id="address-input"
												name="address"
												value={inputValue.address}
											/>
										}
										IconComponent={MyArrowDropDownRoundedIcon}
									>
										{cityData.map((x, key) => (
											<MyMenuItem key={key} className="text-dark" value={x}>
												{x.name}
											</MyMenuItem>
										))}
									</MySelect>
								</FormControl>
							</Grid>

							<Grid item xs={12} md={12} lg={12}>
								<FormControl
									className="w-100"
									error={
										passwordsAreNotSame ||
										(inputValue.password.length === 0 ? false : inputValue.password.length < 6)
									}
								>
									<MyInputLabel htmlFor="password-input">{t("login page:Password")}</MyInputLabel>
									<MyFilledInput
										error={passwordsAreNotSame}
										onChange={handleInputValue}
										id="password-input"
										name="password"
										value={inputValue.password}
										aria-describedby="login-error-text"
										disabled={state.isFetching}
										type="password"
									/>
									{inputValue.password.length >= 1 && inputValue.password.length < 6 ? (
										<FormHelperText id="login-error-text">
											{t("login page:Min length password")}
										</FormHelperText>
									) : null}
								</FormControl>
							</Grid>

							<Grid item xs={12} md={12} lg={12}>
								<FormControl error={passwordsAreNotSame} className="w-100 ">
									<MyInputLabel error={passwordsAreNotSame} htmlFor="password-again-input">
										{t("login page:Password again")}
									</MyInputLabel>
									<MyFilledInput
										error={passwordsAreNotSame}
										onChange={handleInputValue}
										id="password-again-input"
										name="passwordAgain"
										value={inputValue.passwordAgain}
										aria-describedby="login-error-text"
										disabled={state.isFetching}
										type="password"
									/>
									{passwordsAreNotSame ? (
										<FormHelperText id="login-error-text">
											{t("login page:Password validation")}
										</FormHelperText>
									) : null}
								</FormControl>
							</Grid>
						</Grid>
					</form>
				</CardContent>

				<CardActions className="px-3 d-flex align-items-start">
					<FormControlLabel
						className="mr-0"
						control={
							<Checkbox
								color="primary"
								checked={acceptConditions}
								onChange={handleChange}
								name="checkedG"
							/>
						}
					/>
					<span style={{ marginTop: "0.4rem", fontSize: "0.93rem" }}>
						{t("login page:I accept all term 1")}
						<TermsAndConditions
							component="span"
							color="primary.main"
							fontWeight="bold"
							onClick={handleOpenPopper}
						>
							{t("login page:I accept all term 2")}
						</TermsAndConditions>
						{t("login page:I accept all term 3")}
					</span>
				</CardActions>

				<CardActions className="px-3 mt-3 d-flex justify-content-between">
					<Link className="text-decoration-none" disabled={state.isFetching} to="/login">
						<MyButton id="signup-btn" variant="outlined" component="span" disabled={state.isFetching}>
							{t("login page:Back")}
						</MyButton>
					</Link>
					<MyButton
						id="login-btn"
						variant="contained"
						component="span"
						onClick={registerSubmit}
						disabled={
							passwordsAreNotSame ||
							state.isFetching ||
							!acceptConditions ||
							inputValue.msisdn.length !== 9 ||
							inputValue.password.length < 6
						}
					>
						{t("login page:Sign up")}
					</MyButton>
				</CardActions>
			</MyCard>
		</>
	);
}

const TermsAndConditions = styled(Box)`
	&:hover,
	&:active {
		cursor: pointer;
	}
`;

const ForgetButton = styled(Button)`
	color: white;
`;

const Popper = styled("div")`
	background: white;
	position: fixed;
	border-radius: 0.5rem;
	padding: 1rem 2rem;
	overflow: auto;
	right: 0;
	left: 0;
	margin-right: auto;
	margin-left: auto;
	min-height: 10em;
	max-width: 40rem;
	max-height: 35rem;
	width: 90%;
	z-index: 1000;
	text-align: justify;
	-webkit-box-shadow: 3px 3px 20px -3px rgba(163, 163, 163, 1);
	-moz-box-shadow: 3px 3px 20px -3px rgba(163, 163, 163, 1);
	box-shadow: 3px 3px 20px -3px rgba(163, 163, 163, 1);
	@media (max-width: 576px) {
		max-height: 27rem;
		margin-bottom: 8rem;
	}
`;
const MyFilledInputTypeNmber = styled(MyFilledInput)`
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type="number"] {
		-moz-appearance: textfield;
	}
`;
