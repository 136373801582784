import { globalStore } from "global/store";
import actionTypes from "global/actionTypes";

export let currentRequests = [];

export function cancellAllCurrentRequests() {
	for (let i = currentRequests.length - 1; i > -1; i--) {
		currentRequests[i].abort();
		currentRequests.pop();
	}
}

export function fetchData(url, params = {}, abortPrevious = false, changeFetchState = false) {
	let dispatch = globalStore.getStore()[1];

	const controller = new AbortController();
	const { signal } = controller;

	const fetchParams = {
		...params,
		signal, // extend provided params with AbortController signal
	};

	if (abortPrevious && currentRequests.length > 0) cancellAllCurrentRequests();
	if (changeFetchState == true) dispatch({ type: actionTypes.isFetching });

	currentRequests.push(controller);

	const promise = fetch(url, fetchParams).then((response) => {
		currentRequests.pop();
		if (changeFetchState == true) dispatch({ type: actionTypes.isFetched });
		if (response.status == 401) {
			console.log("Unauthorized request. Clear localstorage");
			localStorage.clear();
			dispatch({ type: actionTypes.inactive });
			return Promise.reject(new Error(response.statusText));
		}
		return response;
	});

	return [promise, controller.abort];
}
