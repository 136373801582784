import { constants } from "assets/config/constants";
import actionTypes from "global/actionTypes";

export function updateMenuState(state) {
	if (document.body.clientWidth > constants.responsiveSizes.lg) {
		return { ...state, menuState: constants.menuState.opened };
	} else return { ...state, menuState: constants.menuState.toggled };
}

export function auth(state, token) {
	// request to server to fetch user

	const newState = {
		...state,
		session: {
			...state.session,
			sessionStatus: constants.sessionStatus.active,
			token: token,
		},
	};
	return newState;
}

export function inactive(state) {
	const newState = {
		...state,
		isFetching: false,
		session: {
			...state.session,
			sessionStatus: constants.sessionStatus.inactive,
			token: "",
		},
		user: {
			...state.user,
			firstName: "",
			lastName: "",
			userName: "",
			address: "",
			cityId: "",
			number: "",
			password: "",
		},
	};
	return newState;
}

export function isFetching(state) {
	const newState = {
		...state,
		isFetching: true,
	};
	return newState;
}

export function isFetched(state) {
	const newState = {
		...state,
		isFetching: false,
	};
	return newState;
}

export function fetchDevices(state, devicesData) {
	const newState = {
		...state,
		devicesData,
	};
	return newState;
}

export function fetchGroups(state, groupsData) {
	const newState = {
		...state,
		groupsData,
	};
	return newState;
}

export function profileDetails(state, userDetail) {
	const newState = {
		...state,
		user: {
			...state.user,
			firstName: userDetail.firstName,
			lastName: userDetail.lastName,
			username: userDetail.username,
			address: userDetail.address,
			cityId: userDetail.cityId,
			msisdn: userDetail.msisdn,
			password: userDetail.password,
		},
	};
	return newState;
}

export function stateReducer(state, action) {
	switch (action.type) {
		case actionTypes.auth:
			return auth(state, action.payload);
		case actionTypes.isFetching:
			return isFetching(state);
		case actionTypes.isFetched:
			return isFetched(state);
		case actionTypes.inactive:
			return inactive(state);
		case actionTypes.toggleMenu:
			return updateMenuState(state);
		case actionTypes.openMenu:
			return { ...state, menuState: constants.menuState.open };
		case actionTypes.closeMenu:
			return { ...state, menuState: constants.menuState.toggled };
		case actionTypes.fetchDevices:
			return fetchDevices(state, action.payload);
		case actionTypes.fetchGroups:
			return fetchGroups(state, action.payload);
		case actionTypes.profileDetails:
			return profileDetails(state, action.payload);
		default:
			return { ...state };
	}
}
