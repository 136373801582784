import React, { useState, useCallback, useEffect } from "react";
import _ from "lodash";
import {
	MyCard,
	MyCardHeader,
	MyInputLabel,
	MyFilledInput,
	MyButton,
	MySelect,
	MyMenuItem,
	MyArrowDropDownRoundedIcon,
} from "components/Functional/material-ui-styled-Components";
import { CardContent, FormControl, InputAdornment, Button, FormHelperText, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { globalStore } from "global/store";
import { fetchData } from "global/networking";
import { URLs } from "assets/config/config";
import { useSnackbar } from "notistack";
import actionTypes from "global/actionTypes";

export default function UserProfilePage(props) {
	const [cityData, setCityData] = useState([""]);
	const [t, i18n] = useTranslation(["translation", "login page"]);
	const { enqueueSnackbar } = useSnackbar();
	const [passwordsAreNotSame, setPasswordsAreNotSame] = useState(false);
	const [usernameAlreadyAvailable, setUsernameAlreadyAvailable] = useState(false);
	const [phoneNumberAlreadyAvailable, setPhoneNumberAlreadyAvailable] = useState(false);
	const [redirect, setRedirect] = useState(false);

	const [globalState, globalDispatch] = globalStore.getStore();
	const [inputValue, setInputValue] = useState({
		username: "",
		password: "",
		passwordAgain: "",
		msisdn: "",
		lastName: "",
		firstName: "",
		address: "",
	});

	const handleInputValue = (e) => {
		const value = e.target.value;
		setInputValue({
			...inputValue,
			[e.target.name]: value,
		});
	};

	const fetchCityList = useCallback(() => {
		const promise = fetchData(URLs.main + "City/GetAll", {
			method: "GET",
			headers: {
				"Content-type": "application/json",
			},
		})[0];
		promise
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.success) {
					setCityData(data.list);
				} else {
					enqueueSnackbar(data.errors[0].errorMsg, { variant: "error" });
				}
			})
			.catch((error) => {
				enqueueSnackbar(error.message, { variant: "error" });
				console.log("Request failed", error);
			});
	}, []);

	useEffect(() => {
		fetchCityList();
	}, []);
	useEffect(() => {
		setInputValue({
			username: globalState.user.username,
			password: globalState.user.password,
			passwordAgain: globalState.user.password,
			msisdn: globalState.user.msisdn,
			lastName: globalState.user.lastName,
			firstName: globalState.user.firstName,
			address: globalState.user.address,
		});
	}, [cityData, globalState]);

	//check if password and passwordAgain are same
	const checkPasswords = () => {
		inputValue.password === inputValue.passwordAgain ||
		inputValue.password === "" ||
		inputValue.passwordAgain === ""
			? setPasswordsAreNotSame(false)
			: setPasswordsAreNotSame(true);
	};

	useEffect(() => {
		checkPasswords();
	}, [inputValue.password, inputValue.passwordAgain]);

	const updateProfileDetail = useCallback(() => {
		if (inputValue.password === inputValue.passwordAgain) {
			if (!_.isEmpty(inputValue.address) && isAllInputsFilled()) {
				let city = cityData.find((el) => el.name == inputValue.address);
				const promise = fetchData(URLs.main + "customer/update", {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
						"Content-type": "application/json",
					},
					body: JSON.stringify({
						username: inputValue.username,
						password: inputValue.password,
						msisdn: inputValue.msisdn,
						lastName: inputValue.lastName,
						firstName: inputValue.firstName,
						address: inputValue.address,
						cityId: city.id,
					}),
				})[0];
				promise
					.then((res) => {
						return res.json();
					})
					.then((data) => {
						if (data.success) {
							globalDispatch({ type: actionTypes.profileDetails, payload: inputValue });
							enqueueSnackbar(`${t("login page:Updated successfully")}`, { variant: "success" });
						} else {
							enqueueSnackbar(`${t("login page:Something went error")}`, { variant: "error" });
						}
					})
					.catch((error) => {
						console.log("Request failed", error);
					});
			} else {
				enqueueSnackbar(`${t("login page:Fill all inputs")}`, { variant: "error" });
			}
		} else {
			enqueueSnackbar(`${t("login page:Password error")}`, { variant: "error" });
		}
	});

	const isAllInputsFilled = () => {
		return !(
			inputValue.username === "" ||
			inputValue.firstName === "" ||
			inputValue.lastName === "" ||
			inputValue.msisdn === "" ||
			inputValue.address === "" ||
			inputValue.password === "" ||
			inputValue.passwordAgain === ""
		);
	};

	return (
		<MyCard className="mx-auto" wide="100%">
			<MyCardHeader title={t("login page:Profil details")} />
			<CardContent className="pt-0">
				<form onSubmit={updateProfileDetail} action="/" method="POST" autoComplete="off">
					<Grid container spacing={2} className="justify-content-center">
						<Grid item xs={12} md={12} lg={12}>
							<FormControl error={usernameAlreadyAvailable} className="w-100 mt-0 mt-sm-4">
								<MyInputLabel error={usernameAlreadyAvailable} htmlFor="username-input">
									{t("login page:Username")}
								</MyInputLabel>
								<MyFilledInput
									onChange={handleInputValue}
									id="username-input"
									name="username"
									value={inputValue.username}
									aria-describedby="login-error-text"
									disabled={true}
								/>
								{usernameAlreadyAvailable ? (
									<FormHelperText id="login-error-text">
										{t("login page:Already available")}
									</FormHelperText>
								) : null}
							</FormControl>
						</Grid>

						<Grid item xs={12} md={6} lg={6}>
							<FormControl className="w-100 mt-0 mt-sm-4">
								<MyInputLabel htmlFor="first-name-input">{t("login page:First name")}</MyInputLabel>
								<MyFilledInput
									onChange={handleInputValue}
									id="first-name-input"
									name="firstName"
									value={inputValue.firstName}
									aria-describedby="login-error-text"
									disabled={globalState.isFetching}
								/>
							</FormControl>
						</Grid>

						<Grid item xs={12} md={6} lg={6}>
							<FormControl className="w-100 mt-0 mt-sm-4">
								<MyInputLabel htmlFor="last-name-input">{t("login page:Last name")}</MyInputLabel>
								<MyFilledInput
									onChange={handleInputValue}
									id="last-name-input"
									name="lastName"
									value={inputValue.lastName}
									aria-describedby="login-error-text"
									disabled={globalState.isFetching}
								/>
							</FormControl>
						</Grid>

						<Grid item xs={12} md={6} lg={6}>
							<FormControl error={phoneNumberAlreadyAvailable} className="w-100 mt-0 mt-sm-4">
								<MyInputLabel error={phoneNumberAlreadyAvailable} htmlFor="number-input">
									{t("login page:Phone number")}
								</MyInputLabel>
								<MyFilledInput
									startAdornment={
										<InputAdornment classes={{ root: "MuiInputAdornment-black" }} position="start">
											+994
										</InputAdornment>
									}
									onChange={handleInputValue}
									id="number-input"
									name="msisdn"
									inputProps={{ maxLength: 9 }}
									value={inputValue.msisdn}
									aria-describedby="login-error-text"
									disabled={globalState.isFetching}
								/>
								{phoneNumberAlreadyAvailable ? (
									<FormHelperText id="login-error-text">
										{t("login page:Already available")}
									</FormHelperText>
								) : null}
							</FormControl>
						</Grid>

						<Grid item xs={12} md={6} lg={6}>
							<FormControl className="w-100 mt-0 mt-sm-4">
								<MyInputLabel htmlFor="address-input">{t("login page:City/District")}</MyInputLabel>
								<MySelect
									disabled={globalState.isFetching}
									input={
										<MyFilledInput
											onChange={handleInputValue}
											id="address-input"
											name="address"
											value={inputValue.address}
										/>
									}
									IconComponent={MyArrowDropDownRoundedIcon}
								>
									{cityData.map((x, key) => (
										<MyMenuItem key={key} className="text-dark" value={x.name}>
											{x.name}
										</MyMenuItem>
									))}
								</MySelect>
							</FormControl>
						</Grid>

						<Grid item xs={12} md={6} lg={6}>
							<FormControl className="w-100 mt-0 mt-sm-4">
								<MyInputLabel error={passwordsAreNotSame} htmlFor="password-input">
									{t("login page:New password")}
								</MyInputLabel>
								<MyFilledInput
									error={passwordsAreNotSame}
									onChange={handleInputValue}
									id="password-input"
									name="password"
									value={inputValue.password}
									aria-describedby="login-error-text"
									disabled={globalState.isFetching}
									type="password"
								/>
							</FormControl>
						</Grid>

						<Grid item xs={12} md={6} lg={6}>
							<FormControl error={passwordsAreNotSame} className="w-100 mt-0 mt-sm-4">
								<MyInputLabel error={passwordsAreNotSame} htmlFor="password-again-input">
									{t("login page:Password again")}
								</MyInputLabel>
								<MyFilledInput
									error={passwordsAreNotSame}
									onChange={handleInputValue}
									id="password-again-input"
									name="passwordAgain"
									value={inputValue.passwordAgain}
									aria-describedby="login-error-text"
									disabled={globalState.isFetching}
									type="password"
								/>
								{passwordsAreNotSame ? (
									<FormHelperText id="login-error-text">
										{t("login page:Password validation")}
									</FormHelperText>
								) : null}
							</FormControl>
						</Grid>

						<Grid item xs={12} md={6} lg={6}>
							<MyButton
								className="w-100 mt-0 mt-sm-4"
								id="submit-btn"
								variant="contained"
								component="span"
								onClick={updateProfileDetail}
								disabled={passwordsAreNotSame || globalState.isFetching}
							>
								{t("login page:Submit")}
							</MyButton>
						</Grid>
					</Grid>
				</form>
			</CardContent>
		</MyCard>
	);
}

const ForgetButton = styled(Button)`
	color: white;
`;
