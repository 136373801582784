import React from "react";
import { DialogTitle, DialogContent, Box } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { TransitionUp } from "components/pages/common/TransitionUp";
import { MyIconButton, MyDialog } from "components/Functional/material-ui-styled-Components";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const mapStyles = {
	width: "100%",
	height: "100%",
};

const LocationDialog = (props) => {
	const [t] = useTranslation(["device page", "translation"]);

	const closeDialog = () => {
		props.setLocationDialogState(false);
	};

	return (
		<MyDialog
			disableBackdropClick
			open={props.locationDialogState}
			onClose={closeDialog}
			TransitionComponent={TransitionUp}
			aria-labelledby="device-location"
			fullWidth
		>
			<DialogTitle id="device-location">
				<div className="d-flex justify-content-between align-items-center">
					<span>{t("translation:Location")}</span>
					<MyIconButton aria-label="Close" onClick={closeDialog}>
						<Close />
					</MyIconButton>
				</div>
			</DialogTitle>
			<DialogContent>
				<Box width="100%" height="300px" position="relative">
					<Map
						google={props.google}
						zoom={14}
						style={mapStyles}
						initialCenter={{
							lat: props.latitude,
							lng: props.longitude,
						}}
					>
						<Marker position={{ lat: props.latitude, lng: props.longitude }} />
					</Map>
				</Box>
			</DialogContent>
		</MyDialog>
	);
};

export default GoogleApiWrapper({
	apiKey: "AIzaSyDXqWrVX-SGt9qi5jAyiU274Nwctbj3j-s",
})(LocationDialog);
