import themes from "assets/config/theme";
import { constants } from "assets/config/constants";

export const availablePages = [
	"/",
	"/groups",
	"/climate",
	"/payments",
	"/history",
	"/howto",
	"/contacts",
	"/profiledetails",
	"/FAQ",
];

export const URLs = {
	main: "https://www.aqro.iqlime.az/api/",
	// main: "http://192.168.3.114:9191/",
	// main: "http://localhost:9191/",
};

// configures default values of variables. Functions must be defined by components which use them
const baseConfig = {
	isFetching: false,
	session: {
		sessionStatus: constants.sessionStatus.off,
		token: "",
	},
	user: {
		userName: "",
		firstName: "",
		lastName: "",
		permissions: [],
	},
	theme: {
		activeTheme: themes.light,
		toggleTheme: () => { },
	},
	palette: {
		primary: {
			light: "#B099BF",
			main: "#66bb6a",
			dark: "#36154D",
		},
		secondary: {
			light: "#DEDAEB",
			main: "#7C6EB0",
			dark: "#443D61",
		},
		error: {
			light: "#E9AF90",
			main: "#EA5B0C",
			dark: "#B84709",
		},
		warning: {
			light: "#EDD987",
			main: "#EBBD00",
			dark: "#B89400",
		},
		info: {
			light: "#7ADDF5",
			main: "#00B0DB",
			dark: "#0087A8",
		},
		success: {
			light: "#D6DE82",
			main: "#BCCF00",
			dark: "#A4B500",
		},
		grey: {
			light: "#F5F4F4",
			main: "#EDEDED",
			dark: "#A3A3A3",
		},
	},
	menuState: constants.menuState.open,
	selectedLng: "az",
	fallbackLng: "az",
	devicesData: [],
	groupsData: [],
};

export default baseConfig;
