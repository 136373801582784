import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import config from "assets/config/config";

// import Backend from 'i18next-xhr-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

import translations from "assets/locales/translations";

i18n
	// load translation using xhr -> see /public/locales
	// learn more: https://github.com/i18next/i18next-xhr-backend
	// .use(Backend)

	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	// .use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		resources: translations,
		// detection: {
		//     // order and from where user language should be detected
		//     order: ['localStorage', 'navigator'],

		//     lookupLocalStorage: 'i18nextLng',

		//     // cache user language on
		//     caches: ['localStorage'],
		//     // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

		//     // optional htmlTag with lang attribute, the default is:
		//     htmlTag: document.documentElement
		// },
		lng: config.selectedLng,
		fallbackLng: config.fallbackLng,
		debug: true,

		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
	});

export default i18n;
