import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { MyAccordion, MyButton } from "components/Functional/material-ui-styled-Components";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CircularProgress, AccordionDetails, Typography, AccordionSummary } from "@material-ui/core";
import { URLs } from "assets/config/config";
import { fetchData } from "global/networking";
import { useTranslation } from "react-i18next";
import { globalStore } from "global/store";
import { constants } from "assets/config/constants";

export default function FAQ() {
	const [faqList, setFaqList] = useState([]);
	const [t, i18n] = useTranslation(["translation", "login page"]);
	const [globalState, globalDispatch] = globalStore.getStore();

	const getFAQ = useCallback(() => {
		const promise = fetchData(URLs.main + `faq/getall`, { method: "GET" })[0];
		promise
			.then(async (response) => {
				const data = await response.json();
				setFaqList(data.list);
			})
			.catch((error) => {
				console.error("There was an error!", error);
			});
	}, []);

	useEffect(() => {
		getFAQ();
	}, []);

	return faqList.length > 0 ? (
		<>
			<div
				className={`d-flex flex-column align-self-start w-100 ${
					localStorage.getItem("token") ? null : "mt-md-5 p-md-5"
				}`}
			>
				{faqList.map((item) => (
					<MyAccordion key={item.id} className="w-100">
						<AccordionSummary
							expandIcon={<ExpandMoreIcon className="text-dark" />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography className="text-dark" variant="subtitle2">
								<div dangerouslySetInnerHTML={{ __html: item.question }} />
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography className="text-dark" variant="body2">
								<div dangerouslySetInnerHTML={{ __html: item.answer }} />
							</Typography>
						</AccordionDetails>
					</MyAccordion>
				))}
				{globalState.session.sessionStatus !== constants.sessionStatus.active ? (
					<Link className="text-decoration-none mt-4" disabled={globalState.isFetching} to="/login">
						<MyButton id="back-btn" variant="contained" component="span">
							<ArrowBackIcon fontSize="small" className="mr-2 ml-0 pl-0" />
							{t("faq page:Back")}
						</MyButton>
					</Link>
				) : null}
			</div>
		</>
	) : null;
}
