import React from "react";
import styled from "styled-components/macro";
import { colors } from "assets/config/theme";
import {
	Card,
	CardHeader,
	Tabs,
	Tab,
	FilledInput,
	InputLabel,
	Fab,
	Tooltip,
	Avatar,
	IconButton,
	Button,
	Divider,
	CircularProgress,
	MenuItem,
	Select,
	Dialog,
	ListItemText,
	Accordion,
} from "@material-ui/core/";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { DateTimePicker } from "@material-ui/pickers";
import { constants } from "assets/config/constants";

export let MyTab = styled(Tab).attrs({
	classes: { wrapper: "wrapper" },
})`
	& .wrapper {
		color: ${(prop) => prop.theme.activeTheme.Tab.color};
		font-size: ${(prop) => {
			if (prop.fontSize) return prop.fontSize;
			else return "2rem";
		}};
	}
`;

export let MyTabs = styled(Tabs).attrs({
	classes: { indicator: "indicator" },
})`
	& .indicator {
		background-color: ${(prop) => prop.theme.activeTheme.Tabs.backgroundColor};
		border-radius: 50%;
		height: 4px;
	}
`;

export let Box = styled.div`
	border-radius: 20px;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 2px 1px -1px rgba(0, 0, 0, 0.12);
	border: ${(prop) => prop.theme.activeTheme.Box.border};
	background-color: ${(prop) => prop.theme.activeTheme.Box.backgroundColor};
`;

export let MyCard = styled(Card)`
	max-width: ${(prop) => (prop.wide ? "1150px" : " 430px")};
	width: ${(prop) => (prop.width ? prop.width : null)};
	/* width: 557px; */
	height: ${(prop) => (prop.height ? prop.height : null)};
	min-height: ${(prop) => (prop.minHeight ? prop.minHeight : null)};
	background-color: ${(prop) => prop.theme.activeTheme.Box.backgroundColor};
`;

export let MyCardHeader = styled(CardHeader).attrs({
	classes: { title: "title", subheader: "subheader", avatar: "avatar" },
})`
	& .title {
		color: ${(prop) => prop.theme.activeTheme.CardHeader.titleColor};
		font-size: ${(prop) => (prop.small ? "1rem" : "1.4rem")};
		@media (max-width: ${constants.responsiveSizes.sm}px) {
			font-size: ${(prop) => (prop.small ? "0.8rem" : "1.1rem")};
		}
	}

	& .avatar {
		color: ${(prop) => prop.theme.activeTheme.CardHeader.avatar};
		margin-right: 6px;
	}

	& .subheader {
		color: ${(prop) => prop.theme.activeTheme.CardHeader.subheaderColor};
	}
`;

export let MyListItemText = styled(ListItemText).attrs({
	classes: { primary: "primary", secondary: "secondary" },
})`
	& .primary {
		color: ${(prop) => prop.theme.activeTheme.ListItemText.titleColor};
	}

	& .secondary {
		color: ${(prop) => prop.theme.activeTheme.ListItemText.subheaderColor};
	}
`;

export let MyFilledInput = styled(FilledInput).attrs({
	classes: { disabled: "disabled", root: "root", error: "error", underline: "underline" },
})`
	&.root {
		color: ${(prop) => prop.theme.activeTheme.FilledInput.rootColor};
		background-color: ${(prop) => prop.theme.activeTheme.FilledInput.rootBackgroundColor};

		textarea {
			height: 76px !important;
		}
	}

	&.error.underline ::after {
		border-bottom: 2px solid ${(prop) => prop.theme.activeTheme.FilledInput.errorColor};
	}

	&.disabled {
		color: ${(prop) => prop.theme.activeTheme.FilledInput.disabledColor};
	}

	&.underline ::after {
		border-bottom: 3px solid ${(prop) => prop.theme.activeTheme.FilledInput.underlineColor};
	}
	@media (max-width: ${constants.responsiveSizes.sm}px) {
		&.root {
			textarea {
				height: 46px !important;
			}
		}
	}
`;

export let MyFilledInputForLoginButton = styled(FilledInput).attrs({
	classes: { disabled: "disabled", root: "root", underline: "underline" },
})`
	&.root {
		color: ${(prop) => prop.theme.activeTheme.Button.color};
		background-color: ${(prop) => prop.theme.activeTheme.Button.backgroundColor};
		height: 40px !important;
		border-radius: 4px;
	}

	&:hover {
		color: ${(prop) => prop.theme.activeTheme.Button.color};
		background-color: ${(prop) => prop.theme.activeTheme.Button.hoveredBackgroundColor};
	}

	&.disabled {
		background-color: ${(prop) => prop.theme.activeTheme.Button.disabledBackgroundColor};
	}

	&.underline ::before {
		border-bottom: none;
	}

	&.underline ::after {
		border-bottom: none;
	}

	.MuiFilledInput-input {
		padding: 0 30px;
	}
`;

export let MySelect = styled(({ className, ...props }) => (
	<Select
		classes={{ root: className, select: "select", icon: "icon" }}
		MenuProps={{
			classes: { paper: className },
			getContentAnchorEl: null,
			anchorOrigin: {
				vertical: "bottom",
				horizontal: "center",
			},
			transformOrigin: {
				vertical: "top",
				horizontal: "center",
			},
		}}
		{...props}
	/>
))`
	&.select:focus {
		background-color: transparent;
	}

	ul {
		max-height: 40vh;
	}
`;

export let MyInputLabel = styled(InputLabel).attrs({
	classes: {
		root: "root",
		disabled: "disabled",
		focused: "focused",
		shrink: "shrink",
		error: "error",
	},
})`
	&.textarea-label {
		transform: translate(0, 74px) scale(1);
	}

	&.textarea-label.focused {
		transform: translate(0, 1.5px) scale(0.75);
	}

	&.root {
		color: ${(prop) => prop.theme.activeTheme.InputLabel.rootColor};
		padding-left: 0.75rem;
		padding-top: 0.5rem;
		transition: padding 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
	}

	&.focused {
		color: ${(prop) => prop.theme.activeTheme.InputLabel.focusedColor};
		padding-left: 1.75rem;
	}

	&.shrink {
		padding-left: 1.75rem;
	}

	&.disabled {
		color: ${(prop) => prop.theme.activeTheme.InputLabel.disabledColor};
	}

	&.error {
		color: ${(prop) => prop.theme.activeTheme.InputLabel.errorColor};
	}
	@media (max-width: ${constants.responsiveSizes.sm}px) {
		&.textarea-label {
			transform: translate(0, 44px) scale(1);
		}

		&.textarea-label.focused {
			transform: translate(0, 1.5px) scale(0.75);
		}
	}
`;

export let MyTooltip = styled((props) => (
	<Tooltip classes={{ popper: props.className, tooltip: "mytooltip" }} {...props} />
))`
	& .mytooltip {
		font-size: 0.9rem;
		background-color: ${(prop) => prop.theme.activeTheme.Tooltip.backgroundColor};
		color: ${(prop) => prop.theme.activeTheme.Tooltip.textColor};
	}
`;

export const MyAvatar = styled(Avatar)`
	margin: 10px;
	width: 60px;
	height: 60px;
	background-color: ${(prop) => prop.theme.activeTheme.Avatar.backgroundColor};
`;

export let InfoName = styled("h5")`
	color: ${(prop) => prop.theme.activeTheme.InfoName.textColor};
`;

export let TextDiv = styled("div")`
	color: ${(prop) => {
		return prop.colorAlter
			? prop.theme.activeTheme.TextDiv.textColorAlter
			: prop.theme.activeTheme.TextDiv.textColor;
	}};
`;

export let MyFab = styled(Fab).attrs({
	classes: { root: "root", primary: "primary", secondary: "secondary", disabled: "disabled" },
})`
	&.primary {
		background-color: ${(prop) => prop.theme.activeTheme.Fab.primaryColor};
	}

	&.primary:hover {
		background-color: ${(prop) => prop.theme.activeTheme.Fab.hoveredPrimaryColor};
	}

	&.disabled {
		background-color: ${(prop) => prop.theme.activeTheme.Fab.disabledBackgroundColor};
		color: ${(prop) => prop.theme.activeTheme.Fab.disabledColor};
	}
`;

export let MyIconButton = styled(IconButton).attrs({
	classes: { root: "root" },
})`
	&.root {
		transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		font-size: ${(prop) => (prop.fontSize ? prop.fontSize : "4rem")};
		color: ${(prop) =>
			prop.color === "primary"
				? prop.theme.activeTheme.IconButton.primaryColor
				: prop.theme.activeTheme.IconButton.color};
	}
	&.root:hover {
		font-size: ${(prop) => (prop.fontSize ? prop.fontSize : "4rem")};
		color: ${(prop) =>
			prop.color === "primary"
				? prop.theme.activeTheme.IconButton.primaryColorHovered
				: prop.theme.activeTheme.IconButton.colorHovered};
	}
`;

export let MyButton = styled(Button).attrs({
	classes: { root: "root", contained: "contained", disabled: "disabled" },
})`
	&.root {
		color: ${(prop) => {
			if (prop.color === "actionBackground") return prop.theme.activeTheme.Button.actionColor;
			return prop.theme.activeTheme.Button.color;
		}};
		background-color: ${(prop) => {
			if (prop.color === "actionBackground") return prop.theme.activeTheme.Button.actionBackgroundColor;
			return prop.theme.activeTheme.Button.backgroundColor;
		}};
		text-transform: none;
		font-size: 1rem;
	}

	&.contained {
		background-color: ${(prop) => {
			if (prop.color === "actionBackground") return prop.theme.activeTheme.Button.actionBackgroundColor;
			return prop.theme.activeTheme.Button.backgroundColor;
		}};
		transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	}

	&:hover.contained {
		color: ${(prop) => {
			if (prop.color === "actionBackground") return prop.theme.activeTheme.Button.color;
			return prop.theme.activeTheme.Button.color;
		}};
		background-color: ${(prop) => prop.theme.activeTheme.Button.hoveredBackgroundColor};
	}

	&.disabled {
		background-color: ${(prop) => prop.theme.activeTheme.Button.disabledBackgroundColor};
	}
`;

export let MyDivider = styled(Divider)`
	background-color: ${(prop) => prop.theme.activeTheme.Divider.bgColor};
`;

export let MyCircularProgress = styled(CircularProgress)`
	color: ${(prop) => {
		if (prop.theme.activeTheme) return prop.theme.activeTheme.CircularProgress.color;
		else return colors.actionPrimary;
	}};
`;

export let MyMenuItem = styled(MenuItem).attrs({
	classes: { root: "root" },
})`
	&.root {
		color: ${(prop) => prop.theme.activeTheme.MenuList.color};
		transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		width: 100%;
	}
	&.root:hover {
		color: ${(prop) => prop.theme.activeTheme.MenuList.hoveredColor};
		background-color: ${colors.grayLightMain};
		background-color: ${(prop) => (prop.transparent ? "transparent" : colors.grayLightMain)};
	}
`;

export let MyDialog = styled(Dialog)`
	margin-top: 2rem;
`;

export const MyArrowDropDownRoundedIcon = styled(ArrowDropDownRounded)`
	color: ${(prop) =>
		prop.inverseColor
			? prop.theme.activeTheme.SelectArrowIcon.color
			: prop.theme.activeTheme.SelectArrowIcon.inverseColor};
`;

export let MyAccordion = styled(Accordion)`
	background-color: ${(prop) => prop.theme.activeTheme.Box.backgroundColor};
`;

export let MyDateTimePicker = styled(DateTimePicker)`
	position: relative;
	transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
	background-color: ${colors.white};
	color: ${colors.black};
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	height: 56px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	label {
		padding-left: 0.75rem;
		padding-top: 0.5rem;
		font-weight: 400;
		transform-origin: top left;
		font-size: 1rem;
		top: 0;
		left: 0;
		position: absolute;
		color: ${colors.black};
		&.Mui-focused {
			padding-left: 1.75rem;
			transform: translate(0, 1.5px) scale(0.75);
			color: ${colors.green};
		}
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-bottom: 1px solid rgba(0, 0, 0, 0.87);
	}

	.MuiInput-underline:after {
		border-bottom: 2px solid ${(prop) => prop.theme.activeTheme.InputLabel.focusedColor};
	}

	input {
		color: black;
		padding-left: 0.75rem;
	}
`;
