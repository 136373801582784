import React from "react";
import { MyCard, MyCardHeader, InfoName } from "components/Functional/material-ui-styled-Components";
import { CardContent } from "@material-ui/core";

export default function NoDataFound(props) {
	return (
		<MyCard wide="100%" className="mx-auto mt-4">
			<MyCardHeader avatar={props.avatar} title={props.title} />
			<CardContent className="pt-0 ">
				<div className="d-flex justify-content-center align-items-center">
					<InfoName>{props.text}</InfoName>
				</div>
			</CardContent>
		</MyCard>
	);
}
