import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	MyCard,
	MyCardHeader,
	MyInputLabel,
	MyFilledInput,
	MyButton,
} from "components/Functional/material-ui-styled-Components";
import { CardContent, FormControl, Grid, Typography } from "@material-ui/core";
import Iframe from "react-iframe";
import { fetchData } from "global/networking";
import { URLs } from "assets/config/config";
import { useSnackbar } from "notistack";
import styled from "styled-components/macro";
import { constants } from "assets/config/constants";
import { globalStore } from "global/store";

const defaultInputValue = {
	name: "",
	email: "",
	number: "",
	message: "",
};

export default function ContactsPage() {
	const [globalState, setGlobalState] = globalStore.getStore();
	const [t, i18n] = useTranslation(["contact page"]);
	const { enqueueSnackbar } = useSnackbar();
	const [inputValue, setInputValue] = useState(defaultInputValue);

	const handleInputValue = (e) => {
		const value = e.target.value;
		setInputValue({
			...inputValue,
			[e.target.name]: value,
		});
	};

	const sendButtonClicked = useCallback(() => {
		const token = `Bearer ${localStorage.getItem("token")}`;
		const requestBody = {
			name: inputValue.name,
			number: inputValue.number,
			from: inputValue.email,
			subject: "",
			Body: inputValue.message,
		};
		const promise = fetchData(URLs.main + "email/send", {
			method: "POST",
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
			body: JSON.stringify(requestBody),
		})[0];
		promise
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.success) {
					setInputValue(defaultInputValue);
					enqueueSnackbar(`${t("contact page:Mail sent successfully")}`, { variant: "success" });
				} else {
					enqueueSnackbar(data.errors[0].errorMsg, { variant: "error" });
				}
			})
			.catch((error) => {
				enqueueSnackbar(error.message, { variant: "error" });
				console.log("Request failed", error);
			});
	}, [inputValue]);

	return (
		<>
			<MyCardStyle wide="100%">
				<MyCardHeader title={t("contact page:Contact us")} className="pb-0 pb-sm-3" />
				<CardContent>
					<Grid container spacing={2} className="justify-content-lg-between">
						<Grid item xs={12} sm={12} md={6} lg={4}>
							<Typography className="text-dark-50">{t("contact page:Call us")}:</Typography>
							<Typography className="text-dark">
								<span
									style={{ textDecoration: "underline", color: "#3CADEC" }}
									onClick={() => (window.location.href = "tel:+994123770037")}
								>
									+99412 377 00 37
								</span>
							</Typography>

							<Typography className="text-dark-50 mt-4">
								{t("contact page:Better yet, be our guest")}:
							</Typography>
							<Typography className="text-dark">
								102A, Jalil Mammadguluzade street, "City Point" Business Center AZ1078 Baku, Azerbaijan
							</Typography>
						</Grid>

						<Grid item xs={12} sm={12} md={6} lg={7}>
							<Iframe
								url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3038.8505555816428!2d49.827982915401364!3d40.39000466518838!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d82b3b0c031%3A0xc11310c761bdb24!2sCity%20Point%20Baku!5e0!3m2!1sen!2s!4v1595925403983!5m2!1sen!2s"
								width="100%"
								height="250"
								display="initial"
								frameBorder="0"
								position="relative"
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2} className="mt-sm-5">
						<Grid item xs={12} sm={12} md={12} lg={4} className="p-0 p-sm-2">
							<FormControl className="w-100">
								<MyInputLabel htmlFor="name-input">{t("contact page:Name")}</MyInputLabel>
								<MyFilledInput
									onChange={handleInputValue}
									name="name"
									id="name-input"
									value={inputValue.name}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={4} className="p-0 p-sm-2">
							<FormControl className="w-100">
								<MyInputLabel htmlFor="email-input">{t("contact page:Email")}</MyInputLabel>
								<MyFilledInput
									onChange={handleInputValue}
									name="email"
									id="email-input"
									value={inputValue.email}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={4} className="p-0 p-sm-2">
							<FormControl className="w-100">
								<MyInputLabel htmlFor="phone-input">{t("contact page:Phone")}</MyInputLabel>
								<MyFilledInput
									onChange={handleInputValue}
									name="number"
									id="phone-input"
									value={inputValue.number}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} className="p-0 p-sm-2">
							<FormControl className="w-100">
								<MyInputLabel className="textarea-label" htmlFor="message-input">
									{t("contact page:Message")}
								</MyInputLabel>
								<MyFilledInput
									multiline
									rowsMax={4}
									onChange={handleInputValue}
									name="message"
									id="phone-input"
									value={inputValue.message}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} className="justify-content-end d-flex">
							<MyButton
								disabled={globalState.isFetching}
								onClick={sendButtonClicked}
								className="mt-2"
								variant="contained"
								color="primary"
							>
								{t("contact page:Send")}
							</MyButton>
						</Grid>
					</Grid>
				</CardContent>
			</MyCardStyle>
		</>
	);
}

const MyCardStyle = styled(MyCard)`
	@media (max-width: ${constants.responsiveSizes.sm}px) {
		p {
			font-size: 13px !important;
		}
	}
`;
