import React from "react";
import styled from "styled-components/macro";
import logo from "assets/img/icons/logo.svg";
import logo2 from "assets/img/icons/IQLIME_logo.svg";
import { globalStore } from "global/store";
import { constants } from "assets/config/constants";

export default function IQLimeLogo() {
	const [globalState, globalDispatch] = globalStore.getStore();

	let logoIqlime =
		globalState.session.sessionStatus === constants.sessionStatus.active ? (
			<img src={logo} alt="Logo" />
		) : (
			<PaymentLink rel="noopener noreferrer" href="https://iqlime.az">
				<img src={logo2} alt="Logo" height="58" width="50" />
			</PaymentLink>
		);
	return logoIqlime;
}

let PaymentLink = styled("a")`
	&:hover {
		text-decoration: none;
	}
`;
