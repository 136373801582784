import React, { useEffect } from "react";
import LoadingComponent from "components/Functional/LoadingComponent";
import { storageConstants, constants } from "assets/config/constants";
import jwtDecode from "jwt-decode";
import actionTypes from "global/actionTypes";

/**
 * This component is used to send requests to server whenever application starts (component mounts), user logins/logouts to check authentication.
 */
export default function Authentication(props) {
	useEffect(() => {
		// props.authSet({ type: actionTypes.auth, payload: 'test' });
		let decodedToken = null;
		const token = localStorage.getItem(storageConstants.token);
		if (token) decodedToken = jwtDecode(token);
		if (decodedToken && Math.floor(Date.now() / 1000) < decodedToken.exp)
			props.authSet({ type: actionTypes.auth, payload: token });
		else {
			props.authSet({ type: actionTypes.inactive });
		}
	}, []);

	if (props.sessionStatus == constants.sessionStatus.active) return props.children;
	else if (props.sessionStatus == constants.sessionStatus.off)
		return <LoadingComponent className="d-flex h-100 w-100 justify-content-center align-items-center" />;
	else return null;
}
