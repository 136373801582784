import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "index.css";
import App from "App";
// import * as serviceWorker from 'serviceWorker';
import "i18n";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "fonts/Source-Sans-Pro.ttf";

ReactDOM.render(
	<BrowserRouter>
		<SnackbarProvider
			maxSnack={3}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
		>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<App />
			</MuiPickersUtilsProvider>
		</SnackbarProvider>
	</BrowserRouter>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
