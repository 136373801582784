export const constants = {
	responsiveSizes: {
		sm: 576,
		md: 768,
		lg: 992,
		xl: 1200,
	},
	sessionStatus: {
		off: 0, // on startup
		active: 1,
		inactive: 2, // if token has expired
	},
	permissions: {
		dashboard: 0,
		statusCheck: 1,
		imeiManagement: 2,
		applicationCheck: 3,
		vipManagement: 4,
		tacManagement: 5,
		systemUsers: 6,
	},
	menuState: {
		close: 0,
		toggled: 1,
		open: 2,
	},
	validation: {
		IMEILength: 15,
		IMSIMinLength: 14,
	},
	sensorTypes: {
		airTempHum: "airTempHum",
		soilHum: "soilHum",
		camera: "camera",
	},
	WiFiStatuses: {
		off: 0,
		on: 1,
	},
};


export const storageConstants = {
	token: "token",
};

export const usersStatuses = ["acitve", "deactive"];

export const defaultFakeTemperature = 25.5;
export const maxFakeTemperature = 26.5;
export const minFakeTemperature = 24.5;
export const defaultFakeHumidity = 30.1;
export const maxFakeHumidity = 32;
export const minFakeHumidity = 29;
