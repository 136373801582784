import React from "react";
import { DialogTitle, DialogContent, DialogActions, Button, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { TransitionUp } from "components/pages/common/TransitionUp";
import { MyIconButton, MyDialog } from "components/Functional/material-ui-styled-Components";

const PaymentDialog = (props) => {
	const [t] = useTranslation(["payment page", "translation"]);

	const closeDialog = () => {
		props.setPaymentDialogState(false);
	};

	return (
		<MyDialog
			disableBackdropClick
			open={props.paymentDialogState}
			onClose={closeDialog}
			maxWidth="xs"
			TransitionComponent={TransitionUp}
			aria-labelledby="payment"
			fullWidth
		>
			<DialogTitle id="payment">
				<div className="d-flex justify-content-between align-items-center">
					<span>{t("payment page:Online payment")}</span>
					<MyIconButton aria-label="Close" onClick={closeDialog}>
						<Close />
					</MyIconButton>
				</div>
			</DialogTitle>
			<DialogContent>
				<Typography variant="body1" gutterBottom>
					{t("payment page:Online payment is not available now")}
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={closeDialog}>
					{t("translation:Close")}
				</Button>
			</DialogActions>
		</MyDialog>
	);
};

export default React.memo(PaymentDialog);
