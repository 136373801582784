import React, { useState, useCallback, useEffect } from "react";
import { findIndex } from "lodash";
import {
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Checkbox,
	CircularProgress,
	FormControl,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { TransitionUp } from "components/pages/common/TransitionUp";
import {
	MyIconButton,
	MyDialog,
	MyFilledInput,
	MyInputLabel,
	MyButton,
} from "components/Functional/material-ui-styled-Components";
import { fetchData } from "global/networking";
import { URLs } from "assets/config/config";
import { useSnackbar } from "notistack";
import NoDataFound from "components/pages/common/NoDataFound";
import { globalStore } from "global/store";

const defaultInputValues = {
	groupName: "",
	assignments: [],
};

const AddGroupDialog = (props) => {
	const [globalState, globalDispatch] = globalStore.getStore();
	const [t] = useTranslation(["device page", "translation", "groups page"]);
	const [devicesData, setDevicesData] = useState([]);
	const [inputValue, setInputValue] = useState(defaultInputValues);
	const { enqueueSnackbar } = useSnackbar();

	const canEditGroup = React.useMemo(() => {
		return inputValue.assignments.some((item) => item.checked === true);
	}, [inputValue.assignments]);

	const handleToggle = (value) => () => {
		const updateIndex = findIndex(inputValue.assignments, { cusDeviceId: value });
		const newChecked = [...inputValue.assignments];

		if (updateIndex === -1) {
			newChecked.push({
				cusDeviceId: value,
				checked: true,
			});
		} else {
			newChecked.splice(updateIndex, 1);
		}

		setInputValue({
			...inputValue,
			assignments: newChecked,
		});
	};

	const handleInputValue = (e) => {
		const value = e.target.value;
		setInputValue({
			...inputValue,
			[e.target.name]: value,
		});
	};

	const closeDialog = () => {
		setInputValue(defaultInputValues);
		props.setAddDialogState(false);
	};

	// post new group
	const addButtonClicked = useCallback(() => {
		const token = `Bearer ${localStorage.getItem("token")}`;
		const requestBody = {
			group: {
				name: inputValue.groupName,
			},
			assignments: inputValue.assignments,
		};
		const promise = fetchData(URLs.main + "groupCusDevice/saveGroup", {
			method: "POST",
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
			body: JSON.stringify(requestBody),
		})[0];
		promise
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.success) {
					props.fetchGroupList();
					enqueueSnackbar(`${t("groups page:Group added successfully")}`, { variant: "success" });
					closeDialog();
				} else {
					enqueueSnackbar(data.errors[0].errorMsg, { variant: "error" });
				}
			})
			.catch((error) => {
				enqueueSnackbar(error.message, { variant: "error" });
				console.log("Request failed", error);
			});
	}, [inputValue]);

	// fetch device list data
	const fetchDeviceList = useCallback(() => {
		const token = `Bearer ${localStorage.getItem("token")}`;
		const requestBody = {
			includeDevice: true,
			Pager: {
				CurrentPage: 1,
				PageSize: 100,
			},
		};
		const promise = fetchData(URLs.main + "CusDevice/GetListByFilterPaged", {
			method: "POST",
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
			body: JSON.stringify(requestBody),
		})[0];
		promise
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.success) {
					setDevicesData(data.value?.list);
				} else {
					enqueueSnackbar(data.errors[0].errorMsg, { variant: "error" });
				}
			})
			.catch((error) => {
				enqueueSnackbar(error.message, { variant: "error" });
				console.log("Request failed", error);
			});
	}, []);

	useEffect(() => {
		let mounted = true;

		if (mounted && props.addDialogState) {
			fetchDeviceList();
		}

		return function cleanup() {
			mounted = false;
		};
	}, [props.addDialogState]);

	return (
		<MyDialog
			style={{ maxHeight: "650px" }}
			disableBackdropClick
			open={props.addDialogState}
			onClose={closeDialog}
			TransitionComponent={TransitionUp}
			aria-labelledby="add-new-group"
			fullWidth
			maxWidth="xs"
		>
			<DialogTitle id="add-new-group" className="pb-0 mb-0">
				<div className="d-flex justify-content-between align-items-center border-bottom pb-1">
					<span style={{ fontSize: "1.1rem" }}>{t("groups page:Add new group")}</span>
					<MyIconButton aria-label="Close" onClick={closeDialog}>
						<Close />
					</MyIconButton>
				</div>
			</DialogTitle>
			<DialogContent className="pt-0 mt-0">
				{globalState.isFetching ? (
					<div style={{ height: "4rem" }} className="d-flex justify-content-lg-center align-items-center">
						<CircularProgress size="3rem" />
					</div>
				) : devicesData.length > 0 ? (
					<>
						<FormControl className="w-100">
							<MyFilledInput
								required
								id="group-name"
								type="text"
								name="groupName"
								value={inputValue.groupName}
								onChange={handleInputValue}
								className="text-dark border-bottom-2 border-success"
							/>
						</FormControl>
						<MyInputLabel style={{ fontSize: "0.8rem", marginTop: "5px" }}>
							{t("groups page:Group name")}
						</MyInputLabel>
						<List>
							{devicesData.map((item, key) => {
								const labelId = `checkbox-list-label-${key}`;
								return (
									<ListItem
										className="border-bottom border-muted"
										key={key}
										role={undefined}
										dense
										button
										onClick={handleToggle(item.id)}
										style={{ height: "70px" }}
									>
										<ListItemIcon>
											<Checkbox
												edge="start"
												checked={
													findIndex(inputValue.assignments, { cusDeviceId: item.id }) !== -1
												}
												tabIndex={-1}
												disableRipple
												inputProps={{ "aria-labelledby": labelId }}
												className="text-success"
											/>
										</ListItemIcon>
										<ListItemText id={labelId} primary={item.deviceName} />
									</ListItem>
								);
							})}
						</List>
					</>
				) : (
					<NoDataFound text={t("translation:No data was found")} />
				)}
			</DialogContent>
			<DialogActions>
				<MyButton
					className="mr-5 mb-2"
					style={{ fontSize: "0.7rem" }}
					variant="contained"
					onClick={addButtonClicked}
					disabled={globalState.isFetching || !canEditGroup || inputValue.groupName === ""}
				>
					{t("translation:Add")}
				</MyButton>
			</DialogActions>
		</MyDialog>
	);
};

export default React.memo(AddGroupDialog);
