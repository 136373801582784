import React, { useState, useCallback } from "react";
import { DialogTitle, DialogContent, DialogActions, Button, FormControl } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { TransitionUp } from "components/pages/common/TransitionUp";
import {
	MyIconButton,
	MyFilledInput,
	MyInputLabel,
	MyDialog,
	MyButton,
} from "components/Functional/material-ui-styled-Components";
import { fetchData } from "global/networking";
import { URLs } from "assets/config/config";
import { useSnackbar } from "notistack";
import { globalStore } from "global/store";
import actionTypes from "global/actionTypes";
import { constants, defaultFakeHumidity, defaultFakeTemperature } from "assets/config/constants";

const defaultInputValues = {
	sensorName: "",
	sensorId: "",
	mac: "",
};

const AddSensorDialog = (props) => {
	const [t] = useTranslation(["device page", "translation"]);
	const { enqueueSnackbar } = useSnackbar();
	const [inputValue, setInputValue] = React.useState(defaultInputValues);
	const [globalState, globalDispatch] = globalStore.getStore();

	const closeDialog = () => {
		setInputValue(defaultInputValues);
		props.setAddSensorDialogState((state) => {
			return { open: false, type: state.type };
		});
	};

	const handleInputValue = (e) => {
		const value = e.target.value;
		setInputValue({
			...inputValue,
			[e.target.name]: value,
		});
	};

	const addButtonClicked = useCallback(() => {
		let sensorParams = {
			type: props.addSensorDialogState.type,
			sensorId: inputValue.sensorId,
			sensorName: inputValue.sensorName,
		};

		const newDevicesWithSensors = globalState.devicesWithSensors.map((x) => {
			if (x.deviceId == props.deviceData.deviceId) {
				x.sensors[0] = {
					...x.sensors[0],
					...sensorParams,
					airMinTemperature: "",
					airMaxTemperature: "",
					airMinHumidity: "",
					airMaxHumidity: "",
					phone1: "",
					phone2: "",
					phone3: "",
					isCall: false,
					isSms: false,
				};
			}
			return x;
		});

		globalDispatch({
			type: actionTypes.setDevicesWithSensors,
			payload: newDevicesWithSensors,
		});

		closeDialog();
	}, [inputValue, props.deviceData, props.addSensorDialogState]);

	return (
		<MyDialog
			disableBackdropClick
			open={props.addSensorDialogState.open}
			onClose={closeDialog}
			TransitionComponent={TransitionUp}
			aria-labelledby="add-new-device"
			fullWidth
		>
			<DialogTitle id="add-new-device">
				<div className="d-flex justify-content-between align-items-center">
					<span>
						{t(
							`device page:${
								props.addSensorDialogState.type == constants.sensorTypes.airTempHum
									? "Add sensor"
									: "Add camera"
							}`
						)}
					</span>
					<MyIconButton aria-label="Close" onClick={closeDialog}>
						<Close />
					</MyIconButton>
				</div>
			</DialogTitle>
			<DialogContent>
				<FormControl className="w-100 mt-4">
					<MyInputLabel htmlFor="sensor-name">
						{t(
							`device page:${
								props.addSensorDialogState.type == constants.sensorTypes.airTempHum
									? "Sensor name"
									: "Camera name"
							}`
						)}
					</MyInputLabel>
					<MyFilledInput
						required
						id="sensor-name"
						type="text"
						name="sensorName"
						value={inputValue.sensorName}
						onChange={handleInputValue}
						className="text-dark"
					/>
				</FormControl>
				{props.addSensorDialogState.type == constants.sensorTypes.airTempHum ? (
					<>
						<FormControl className="w-100 mt-4">
							<MyInputLabel htmlFor="sensor-id">{t("device page:Sensor id number")}</MyInputLabel>
							<MyFilledInput
								id="sensor-id"
								type="text"
								name="sensorId"
								value={inputValue.sensorId}
								onChange={handleInputValue}
								className="text-dark"
							/>
						</FormControl>
						<FormControl className="w-100 mt-4">
							<MyInputLabel htmlFor="sensor-pin">{t("device page:Sensor pin code")}</MyInputLabel>
							<MyFilledInput
								id="sensor-pin"
								type="text"
								name="pin"
								value={inputValue.pin}
								onChange={handleInputValue}
								className="text-dark"
							/>
						</FormControl>
					</>
				) : (
					<FormControl className="w-100 mt-4">
						<MyInputLabel htmlFor="sensor-pin">{t("device page:MAC address")}</MyInputLabel>
						<MyFilledInput
							id="camera-mac"
							type="text"
							name="mac"
							value={inputValue.mac}
							onChange={handleInputValue}
							className="text-dark"
						/>
					</FormControl>
				)}
			</DialogContent>
			<DialogActions>
				<MyButton
					className="mr-5 mb-2"
					style={{ fontSize: "0.7rem" }}
					variant="contained"
					onClick={addButtonClicked}
				>
					{t("translation:Add")}
				</MyButton>
			</DialogActions>
		</MyDialog>
	);
};

export default React.memo(AddSensorDialog);
