const actionTypes = {
	auth: "auth",
	inactive: "inactive",
	openMenu: "openMenu",
	closeMenu: "closeMenu",
	toggleMenu: "toggleMenu",
	isFetching: "isFetching",
	isFetched: "isFetched",
	fetchDevices: "fetchDevices",
	fetchGroups: "fetchGroups",
	profileDetails: "profileDetails",
};

export default actionTypes;
