import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import SingleChart from "./SingleChart";
import { CircularProgress, Typography } from "@material-ui/core";
import { constants } from "assets/config/constants";

export default function HistoryChartPage(props) {
	const [t, i18n] = useTranslation(["translation, device page", "history page"]);
	const convertFunction = (item) => {
		switch (item) {
			case 4:
				return 100;
				break;
			case 3:
				return 75;
				break;
			case 2:
				return 50;
				break;
			case 1:
				return 25;
				break;
			default:
				return 0;
				break;
		}
	};
	const fullDate = props.data.map((item) => item.sentDt);
	const name = props.deviceData.find((item) => item.id === props.searchValue.deviceId);
	const fullChartData = [
		{
			name: t("device page:Temperature"),
			data: props.data.map((item) => item.temperature),
			min: -50,
			max: 50,
			sing: "°C",
		},
		{
			name: props.searchValue.sensor === constants.sensorTypes.airTempHum ? t("device page:Humidity") : t("device page:Soil Humidity"),
			data: props.data.map((item) => item.humidity),
			min: 0,
			max: 100,
			sing: "%",
		},
		{
			name: t("history page:Electricity"),
			data: props.data.map((item) => (item.electricity > 100 ? 100 : item.electricity)),
			min: 0,
			max: 100,
			sing: "%",
		},
		{
			name: t("history page:Mobile signal"),
			data: props.data.map((item) => convertFunction(item.mobileSignal)),
			min: 0,
			max: 100,
			sing: "%",
		},
	];

	return (
		<>
			<Typography variant="h6" className="ml-3">
				{t("history page:History")}
				{props.isLoading && (
					<CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
				)}
			</Typography>
			{fullChartData[0].data.length > 0 ? (
				fullChartData.map((item, index) => {
					return (
						index == 0 && props.columns[0].options?.display === "excluded" ? null : <SingleChart
							key={index}
							chartData={item}
							date={fullDate}
							name={name}
							ymin={index == 0 ? -40 : 0}
							ymax={index == 0 ? 60 : 100}
						/>
					);
				})
			) : (
				<Typography className="d-flex justify-content-center">{t("history page:No matching")}</Typography>
			)}
		</>
	);
}
