import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import {
	DialogTitle,
	DialogContent,
	DialogActions,
	FormControl,
	Grid,
	FormControlLabel,
	Switch,
	Typography,
	InputAdornment,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { TransitionUp } from "components/pages/common/TransitionUp";
import {
	MyIconButton,
	MyFilledInput,
	MyInputLabel,
	MyDialog,
	MyButton,
} from "components/Functional/material-ui-styled-Components";
import { fetchData } from "global/networking";
import { URLs } from "assets/config/config";
import { useSnackbar } from "notistack";
import { globalStore } from "global/store";
import actionTypes from "global/actionTypes";
import { constants } from "assets/config/constants";

const defaultInputValues = {
	airMinTemperature: "",
	airMaxTemperature: "",
	airMinHumidity: "",
	airMaxHumidity: "",
	soilMinHumidity: "",
	soilMaxHumidity: "",
	phone1: "",
	phone2: "",
	phone3: "",
	isCall: false,
	isSms: false,
	id: null,
};

const SensorSettingDialog = (props) => {
	const [globalState, globalDispatch] = globalStore.getStore();
	const [t] = useTranslation(["device page", "translation"]);
	const [fetchDevices, setFetchDevices] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [inputValue, setInputValue] = React.useState(props.sensorSettingDialogState?.sensorData);

	const closeDialog = () => {
		setInputValue(defaultInputValues);
		props.setSensorSettingDialogState((oldState) => {
			return { ...oldState, open: false };
		});
	};

	const handleInputValue = (e) => {
		const value = e.target.value;
		setInputValue({
			...inputValue,
			[e.target.name]: value,
		});
		props.setIsButtonDisabled(false);
	};

	const handleSwitchValue = (e) => {
		setInputValue({
			...inputValue,
			[e.target.name]: e.target.checked,
		});
		props.setIsButtonDisabled(false);
	};

	useEffect(() => {
		if (props.sensorSettingDialogState.open) {
			fetchSettingData();
		}
	}, [props.sensorSettingDialogState.open]);

	// post settings data
	const saveButtonClicked = () => {
		let isThresholdCorrect = true;
		// if (props.sensorSettingDialogState?.isRealSensor == true) {
		const token = `Bearer ${localStorage.getItem("token")}`;
		const requestBody = {
			...inputValue,
			airMinTemperature: Number(inputValue.airMinTemperature),
			airMaxTemperature: Number(inputValue.airMaxTemperature),
			airMinHumidity: Number(inputValue.airMinHumidity),
			airMaxHumidity: Number(inputValue.airMaxHumidity),
			soilMinHumidity: Number(inputValue.soilMinHumidity),
			soilMaxHumidity: Number(inputValue.soilMaxHumidity),
			id: props.deviceData.id,
		};

		fetchData(URLs.main + "CusDeviceSetting/save", {
			method: "POST",
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
			body: JSON.stringify(requestBody),
		})[0]
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.success) {
					setFetchDevices(true);
					enqueueSnackbar(`${t("device page:Device settings updated successfully")}`, {
						variant: "success",
					});
					closeDialog();
				} else {
					enqueueSnackbar(`${t("device page:Thresholds are set incorrectly")}`, { variant: "error" });
				}
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: "error" });
				console.log("Request failed", error);
			});
		// }

		// if (
		// 	inputValue.airMinTemperature == "" ||
		// 	inputValue.airMaxTemperature == "" ||
		// 	inputValue.airMinHumidity == "" ||
		// 	inputValue.airMaxHumidity == "" ||
		// 	parseFloat(inputValue.airMinTemperature) > parseFloat(inputValue.airMaxTemperature) ||
		// 	parseFloat(inputValue.airMinTemperature) > parseFloat(inputValue.airMaxHumidity)
		// )
		// 	isThresholdCorrect = false;
		// else {
		// 	let newFakeDevicesWithSensors = [...globalState.devicesWithSensors];
		// 	console.log(
		// 		"newFakeDevicesWithSensors=",
		// 		newFakeDevicesWithSensors,
		// 		"globalState.devicesWithSensors=",
		// 		globalState.devicesWithSensors
		// 	);
		// 	newFakeDevicesWithSensors[props.sensorSettingDialogState.deviceIdx].sensors[
		// 		props.sensorSettingDialogState.sensorIdx
		// 	] = {
		// 		...newFakeDevicesWithSensors[props.sensorSettingDialogState.deviceIdx].sensors[
		// 			props.sensorSettingDialogState.sensorIdx
		// 		],
		// 		airMinTemperature: inputValue.airMinTemperature,
		// 		airMaxTemperature: inputValue.airMaxTemperature,
		// 		airMinHumidity: inputValue.airMinHumidity,
		// 		airMaxHumidity: inputValue.airMaxHumidity,
		// 		phone1: inputValue.phone1,
		// 		phone2: inputValue.phone2,
		// 		phone3: inputValue.phone3,
		// 		isCall: inputValue.isCall,
		// 		isSms: inputValue.isSms,
		// 	};

		// 	globalDispatch({ type: actionTypes.setDevicesWithSensors, payload: newFakeDevicesWithSensors });
		// }

		// if (isThresholdCorrect == false)
		// 	enqueueSnackbar(`${t("device page:Thresholds are set incorrectly")}`, { variant: "error" });
		// else {
		// 	enqueueSnackbar(`${t("device page:Device settings updated successfully")}`, {
		// 		variant: "success",
		// 	});
		// 	closeDialog();
		// }
	};

	// fetch settings data
	const fetchSettingData = useCallback(() => {
		const token = `Bearer ${localStorage.getItem("token")}`;
		const promise = fetchData(URLs.main + `CusDeviceSetting/GetById/${props.deviceData.id}`, {
			method: "GET",
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
		})[0];
		promise
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.success) {
					setInputValue({
						...inputValue,
						airMinTemperature: data.value.airMinTemperature != null ? data.value.airMinTemperature : "",
						airMaxTemperature: data.value.airMaxTemperature != null ? data.value.airMaxTemperature : "",
						airMinHumidity: data.value.airMinHumidity != null ? data.value.airMinHumidity : "",
						airMaxHumidity: data.value.airMaxHumidity != null ? data.value.airMaxHumidity : "",
						soilMinHumidity: data?.value?.soilMinHumidity ?? "",
						soilMaxHumidity: data?.value?.soilMaxHumidity ?? "",
						phone1: data.value.phone1 || "",
						phone2: data.value.phone2 || "",
						phone3: data.value.phone3 || "",
						isCall: data.value.isCall,
						isSms: data.value.isSms,
						id: props.deviceData.id,
					});
				}
			})
			.catch((error) => {
				console.log("Request failed", error);
			});
	}, []);

	// fetch devices list again when updated and closed dialog
	useEffect(() => {
		if (fetchDevices && !props.settingDialogState) {
			props.fetchDeviceList();
		}
	}, [fetchDevices, props.settingDialogState]);

	return (
		<MyDialog
			disableBackdropClick
			open={props.sensorSettingDialogState.open}
			onClose={closeDialog}
			TransitionComponent={TransitionUp}
			aria-labelledby="settings"
			fullWidth
		>
			<DialogTitle id="settings">
				<div className="d-flex justify-content-between align-items-center">
					<span>
						{t(
							props?.sensorSettingDialogState?.sensorData?.type === constants.sensorTypes.airTempHum
								? "sensors:Air sensor"
								: "sensors:Torpaq sensor"
						)}
						- {t("translation:Settings")}
					</span>
					<MyIconButton aria-label="Close" onClick={closeDialog}>
						<Close />
					</MyIconButton>
				</div>
			</DialogTitle>
			<DialogContent>
				<Grid container>
					{/* <Grid item xs={12} md={12} lg={6}>
						{props.deviceData.activatedDt && (
							<Typography variant="subtitle2" gutterBottom>
								{t("device page:Activated date")}:&nbsp;
								{moment(props.deviceData.activatedDt).format("DD-MM-YYYY")}
							</Typography>
						)}
					</Grid> */}
					<Grid item xs={12} md={12} lg={6}>
						{props.deviceData.deactivatedDt && (
							<Typography variant="subtitle2" gutterBottom>
								{t("device page:Deactivated date")}:&nbsp;
								{moment(props.deviceData.deactivatedDt).format("DD-MM-YYYY")}
							</Typography>
						)}
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs={12} className="mt-4">
						<Typography variant="body2" gutterBottom>
							{t(
								props.sensorSettingDialogState?.sensorData?.type === constants.sensorTypes.airTempHum
									? "device page:Determine the temperature and humidity limits"
									: "device page:Determine the soil humidity limits"
							)}
						</Typography>
					</Grid>
					{props.sensorSettingDialogState?.sensorData?.type === constants.sensorTypes.airTempHum && (
						<>
							<Grid item xs={12} md={12} lg={6}>
								<FormControl className="w-100 mb-4">
									<MyInputLabel shrink htmlFor="min-temperature">
										{t("device page:Minimum temperature")}
									</MyInputLabel>
									<MyFilledInput
										required
										id="min-temperature"
										name="airMinTemperature"
										value={inputValue.airMinTemperature}
										onChange={handleInputValue}
										className="text-dark"
										disabled={globalState.isFetching}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={12} lg={6}>
								<FormControl className="w-100 mb-4">
									<MyInputLabel shrink htmlFor="max-temperature">
										{t("device page:Maximum temperature")}
									</MyInputLabel>
									<MyFilledInput
										required
										id="max-temperature"
										name="airMaxTemperature"
										value={inputValue.airMaxTemperature}
										onChange={handleInputValue}
										className="text-dark"
										disabled={globalState.isFetching}
									/>
								</FormControl>
							</Grid>

							<Grid item xs={12} md={12} lg={6}>
								<FormControl className="w-100 mb-4">
									<MyInputLabel shrink htmlFor="min-humidity">
										{t("device page:Minimum humidity")}
									</MyInputLabel>
									<MyFilledInput
										required
										id="min-humidity"
										name="airMinHumidity"
										value={inputValue.airMinHumidity}
										onChange={handleInputValue}
										className="text-dark"
										disabled={globalState.isFetching}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={12} lg={6}>
								<FormControl className="w-100 mb-4">
									<MyInputLabel shrink htmlFor="max-temperature">
										{t("device page:Maximum humidity")}
									</MyInputLabel>
									<MyFilledInput
										required
										id="max-humidity"
										name="airMaxHumidity"
										value={inputValue.airMaxHumidity}
										onChange={handleInputValue}
										className="text-dark"
										disabled={globalState.isFetching}
									/>
								</FormControl>
							</Grid>
						</>
					)}
					{props.sensorSettingDialogState?.sensorData?.type === constants.sensorTypes.soilHum && (
						<>
							<Grid item xs={12} md={12} lg={6}>
								<FormControl className="w-100 mb-4">
									<MyInputLabel shrink htmlFor="soil-min-humidity">
										{t("device page:Minimum humidity")}
									</MyInputLabel>
									<MyFilledInput
										required
										id="soil-min-humidity"
										name="soilMinHumidity"
										value={inputValue.soilMinHumidity}
										onChange={handleInputValue}
										className="text-dark"
										disabled={globalState.isFetching}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={12} lg={6}>
								<FormControl className="w-100 mb-4">
									<MyInputLabel shrink htmlFor="soil-max-temperature">
										{t("device page:Maximum humidity")}
									</MyInputLabel>
									<MyFilledInput
										required
										id="max-humidity"
										name="soilMaxHumidity"
										value={inputValue.soilMaxHumidity}
										onChange={handleInputValue}
										className="text-dark"
										disabled={globalState.isFetching}
									/>
								</FormControl>
							</Grid>
						</>
					)}
				</Grid>
			</DialogContent>
			<DialogActions>
				<MyButton
					className="mr-4 mb-2"
					variant="contained"
					style={{ fontSize: "0.7rem" }}
					onClick={saveButtonClicked}
					disabled={globalState.isFetching || props.isButtonDisabled}
				>
					{t("translation:Save")}
				</MyButton>
			</DialogActions>
		</MyDialog>
	);
};

export default React.memo(SensorSettingDialog);
