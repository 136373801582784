import React from "react";
import { MyCard, MyFab } from "components/Functional/material-ui-styled-Components";
import { Box, Button, CardContent, Grid, IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CircleRemove from "assets/img/icons/circle-remove.svg";
import IconEdit from "assets/img/icons/icon_edit.svg";
import IconSetting from "assets/img/icons/icon_setting.svg";
import Battery30 from "@material-ui/icons/Battery30";
import Battery60 from "@material-ui/icons/Battery60";
import BatteryFull from "@material-ui/icons/BatteryFull";
import BatteryChargingFull from "@material-ui/icons/BatteryChargingFull";
import SignalCellular1Bar from "@material-ui/icons/SignalCellular1Bar";
import SignalCellular2Bar from "@material-ui/icons/SignalCellular2Bar";
import SignalCellular3Bar from "@material-ui/icons/SignalCellular3Bar";
import SignalCellular4Bar from "@material-ui/icons/SignalCellular4Bar";
import SignalCellular0Bar from "@material-ui/icons/SignalCellular0Bar";
import SignalWifiOff from "@material-ui/icons/SignalWifiOff";
import SignalWifi4Bar from "@material-ui/icons/SignalWifi4Bar";
import SettingDialog from "./SettingDialog";
import EditDialog from "./EditDialog";
import DeleteDialog from "./DeleteDialog";
import moment from "moment";
import BatteryEmpty from "assets/img/icons/icon_battery_aler.svg";
import LocationIcon from "assets/img/icons/Location.svg";
import { colors } from "assets/config/theme";
import AddIcon from "@material-ui/icons/Add";
import Sensor from "./Sensor";
import sensorIcon from "assets/img/icons/sensor.svg";
import videoCameraIcon from "assets/img/icons/video-camera.svg";
import AddSensorDialog from "./AddSensorDialog";
import LocationDialog from "./LocationDialog";
import { constants } from "assets/config/constants";
import SensorSettingDialog from "./SensorSettingDialog";
import CameraDialog from "./CameraDialog";

const DeviceCard = (props) => {
	const [t, i18n] = useTranslation(["translation", "device page"]);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [anchorSensor, setAnchorSensor] = React.useState(null);
	const [showImeiInfo, setShowImeiInfo] = React.useState(null);
	const [locationDialogState, setLocationDialogState] = React.useState(false);
	const [settingDialogState, setSettingDialogState] = React.useState(false);
	const [editDialogState, setEditDialogState] = React.useState(false);
	const [deleteDialogState, setDeleteDialogState] = React.useState(false);
	const [checkDeviceReceivesInformation, setCheckDeviceReceivesInformation] = React.useState(true);
	const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
	const [addSensorDialogState, setAddSensorDialogState] = React.useState({
		open: false,
		type: constants.sensorTypes.airTempHum,
	});
	const [sensorSettingDialogState, setSensorSettingDialogState] = React.useState({
		open: false,
		sensorData: {},
		// isRealSensor: false,
		deviceIdx: -1,
		sensorIdx: -1,
	});

	const [cameraDialogState, setCameraDialogState] = React.useState({
		open: false,
	});

	const deviceData = props.deviceData;

	// right menu open
	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
		setIsButtonDisabled(true);
	};

	// right menu close
	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleAddSensorMenuClick = (event) => {
		setAnchorSensor(event.currentTarget);
		setIsButtonDisabled(true);
	};

	const handleAddSensorMenuClose = () => {
		setAnchorSensor(null);
	};

	const openSensorSettingDialog = (sensorData, isRealSensor, deviceIdx, sensorIdx) => () => {
		setSensorSettingDialogState({
			...sensorSettingDialogState,
			open: true,
			sensorData: sensorData,
			// isRealSensor: isRealSensor,
			deviceIdx: deviceIdx,
			sensorIdx: sensorIdx,
		});
	};

	const openLocation = () => {
		setLocationDialogState(true);
	};

	const openAddSensorDialog = (type) => () => {
		console.log("type=", type);
		setAddSensorDialogState({ open: true, type: type });
		handleAddSensorMenuClose();
	};

	// setting dialog open
	const openSettingDialog = () => {
		setSettingDialogState(true);
		handleMenuClose(false);
	};

	// delete dialog open
	const openDeleteDialog = () => {
		setDeleteDialogState(true);
		handleMenuClose(false);
	};

	// edit dialog open
	const openEditDialog = () => {
		setEditDialogState(true);
		handleMenuClose(false);
	};

	const isDeviceReceivesInformation = () => {
		let TEN_MINUTE = 10 * 60 * 1000; /* ms */
		const aTenMinAgo = Date.now() - TEN_MINUTE;
		let lastSentDate = new Date(deviceData.lastSentDate);
		setCheckDeviceReceivesInformation(lastSentDate > aTenMinAgo);
	};

	const openCameraDialog = () => {
		setCameraDialogState({
			...cameraDialogState,
			open: true,
		});
	};

	React.useEffect(() => {
		isDeviceReceivesInformation();
	}, [props.deviceData]);

	return (
		<>
			<LocationDialog
				latitude={deviceData.latitude}
				longitude={deviceData.longitude}
				setLocationDialogState={setLocationDialogState}
				locationDialogState={locationDialogState}
			/>

			<AddSensorDialog
				deviceData={props.deviceData}
				fetchDeviceList={props.fetchDeviceList}
				setAddSensorDialogState={setAddSensorDialogState}
				addSensorDialogState={addSensorDialogState}
			/>

			<SensorSettingDialog
				setSensorSettingDialogState={setSensorSettingDialogState}
				sensorSettingDialogState={sensorSettingDialogState}
				deviceData={deviceData}
				fetchDeviceList={props.fetchDeviceList}
				setIsButtonDisabled={setIsButtonDisabled}
				isButtonDisabled={isButtonDisabled}
			/>
			<CameraDialog
				setCameraDialogState={setCameraDialogState}
				cameraDialogState={cameraDialogState}
				deviceData={deviceData}
				fetchDeviceList={props.fetchDeviceList}
				setIsButtonDisabled={setIsButtonDisabled}
				isButtonDisabled={isButtonDisabled}
			/>

			<SettingDialog
				setSettingDialogState={setSettingDialogState}
				settingDialogState={settingDialogState}
				deviceData={deviceData}
				fetchDeviceList={props.fetchDeviceList}
				setIsButtonDisabled={setIsButtonDisabled}
				isButtonDisabled={isButtonDisabled}
			/>

			<EditDialog
				setEditDialogState={setEditDialogState}
				editDialogState={editDialogState}
				deviceData={deviceData}
			/>

			<DeleteDialog
				setDeleteDialogState={setDeleteDialogState}
				deleteDialogState={deleteDialogState}
				deviceData={deviceData}
			/>

			<Grid item xs={12} sm={12} md={6}>
				<MyCard
					wide
					minHeight="300px"
					className="position-relative d-flex flex-column"
					style={{ overflow: "visible" }}
				>
					<Box mt={2.25} mx={2} display="flex">
						<Box width="50%" borderRight="1px solid #EBEBF2">
							<ButtonStyled
								variant="contained"
								backgroundColor="#EFEFF4"
								startIcon={<img src={LocationIcon} alt="Location Icon" />}
								onClick={openLocation}
							>
								<Box fontSize="12px" lineHeight="15px">
									{t("translation:Location")}
								</Box>
							</ButtonStyled>
						</Box>
						<Box width="50%" display="flex" justifyContent="flex-end">
							<Box display="flex" flexDirection="column" alignItems="center" mx={0.5}>
								<Box component="span" height="1.5rem">
									{checkDeviceReceivesInformation && deviceData.paymentStatus !== 1 ? (
										deviceData.wifiStatus == constants.WiFiStatuses?.off ? (
											<SignalWifiOff fontSize="small" style={{ color: colors.grayMain }} />
										) : (
											<SignalWifi4Bar fontSize="small" style={{ color: colors.grayMain }} />
										)
									) : (
										<SignalWifiOff fontSize="small" style={{ color: colors.grayMain }} />
									)}
								</Box>
								{checkDeviceReceivesInformation &&
									deviceData.paymentStatus !== 1 &&
									deviceData.wifiStatus == constants.WiFiStatuses?.on ? (
									<PercentText fontSize="10px" style={{ color: colors.black, marginTop: "4px" }}>
										On
									</PercentText>
								) : (
									<PercentText fontSize="10px" style={{ color: colors.black, marginTop: "4px" }}>
										Off
									</PercentText>
								)}
							</Box>
							<Box display="flex" flexDirection="column" alignItems="center" mx={0.5}>
								<Box component="span" height="1.5rem">
									{checkDeviceReceivesInformation && deviceData.paymentStatus !== 1 ? (
										deviceData.newElectricity === 101 ? (
											<BatteryChargingFull fontSize="small" style={{ color: colors.grayMain }} />
										) : deviceData.newElectricity > 74 ? (
											<BatteryFull fontSize="small" style={{ color: colors.grayMain }} />
										) : deviceData.newElectricity > 49 ? (
											<Battery60 fontSize="small" style={{ color: colors.grayMain }} />
										) : deviceData.newElectricity > 10 ? (
											<Battery30 fontSize="small" style={{ color: colors.grayMain }} />
										) : (
											<img src={BatteryEmpty} alt="BatteryEmpty" />
										)
									) : (
										<img src={BatteryEmpty} alt="BatteryEmpty" />
									)}
								</Box>
								{checkDeviceReceivesInformation && deviceData.paymentStatus !== 1 ? (
									<PercentText fontSize="10px" style={{ color: colors.black, marginTop: "4px" }}>
										{deviceData.newElectricity === 101 ? "100%" : `${deviceData.newElectricity}%`}
									</PercentText>
								) : (
									<PercentText fontSize="10px" style={{ color: colors.black, marginTop: "4px" }}>
										0%
									</PercentText>
								)}
							</Box>
							<Box display="flex" flexDirection="column" alignItems="center" mx={0.5}>
								<Box component="span" height="1.5rem">
									{checkDeviceReceivesInformation && deviceData.paymentStatus !== 1 ? (
										deviceData.newMobileSignal === 4 ? (
											<SignalCellular4Bar fontSize="small" style={{ color: colors.grayMain }} />
										) : deviceData.newMobileSignal === 3 ? (
											<SignalCellular3Bar fontSize="small" style={{ color: colors.grayMain }} />
										) : deviceData.newMobileSignal === 2 ? (
											<SignalCellular2Bar fontSize="small" style={{ color: colors.grayMain }} />
										) : deviceData.newMobileSignal === 1 ? (
											<SignalCellular1Bar fontSize="small" style={{ color: colors.grayMain }} />
										) : (
											<SignalCellular0Bar fontSize="small" style={{ color: colors.grayMain }} />
										)
									) : (
										<SignalCellular0Bar fontSize="small" style={{ color: colors.grayMain }} />
									)}
								</Box>
								{checkDeviceReceivesInformation && deviceData.paymentStatus !== 1 ? (
									<PercentText
										fontSize="10px"
										style={{
											color: colors.black,
											marginTop: deviceData.newMobileSignal != 0 ? "4px" : null,
										}}
									>
										{deviceData.newMobileSignal === 4
											? "100%"
											: deviceData.newMobileSignal === 3
												? "75%"
												: deviceData.newMobileSignal === 2
													? "50%"
													: deviceData.newMobileSignal === 1
														? "25%"
														: "0%"}
									</PercentText>
								) : (
									<PercentText fontSize="10px" style={{ color: colors.black, marginTop: "4px" }}>
										0%
									</PercentText>
								)}
							</Box>
							<div>
								<IconButtonStyled aria-haspopup="true" onClick={handleMenuClick}>
									<MoreVertIcon style={{ color: colors.orange }} />
								</IconButtonStyled>
							</div>
							<Menu
								id={`menu-${deviceData.deviceId}`}
								anchorEl={anchorEl}
								open={Boolean(anchorEl)}
								onClose={handleMenuClose}
								className="positionMenuItemForDeviceCard"
							>
								<MenuItemForDevice onClick={openSettingDialog}>
									<img src={IconSetting} />
									{t("translation:Settings")}
								</MenuItemForDevice>
								{/* <MenuItemForDevice disabled onClick={openSettingDialog}>
									<SvgIcon fontSize="inherit">
										{deviceData.wifiStatus == constants.WiFiStatuses?.off ? (
											<SignalWifi4Bar />
										) : (
											<SignalWifiOff />
										)}
									</SvgIcon>
									<Box ml={1.25} component="span">
										{deviceData.wifiStatus == constants.WiFiStatuses?.off ? "WiFi on" : "WiFi off"}
									</Box>
								</MenuItemForDevice> */}
								<MenuItemForDevice disabled onClick={openEditDialog}>
									<img src={IconEdit} />
									{t("translation:Edit")}
								</MenuItemForDevice>
								<MenuItemForDevice disabled onClick={openDeleteDialog}>
									<img src={CircleRemove} />
									{t("translation:Delete")}
								</MenuItemForDevice>
							</Menu>
						</Box>
					</Box>
					<Box mx={2}>
						<Box fontWeight="700" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
							{deviceData.deviceName}
						</Box>
						<Box style={{ color: colors.black, display: "inline-block" }}>ID: {deviceData.deviceId}</Box>
					</Box>

					<CardContent className="pt-0 flex-grow-1 px-2 px-md-3 mt-2">
						{checkDeviceReceivesInformation && deviceData.paymentStatus !== 1 ? (
							props.deviceData.sensors.length > 0 ? (
								props.deviceData.sensors.map((x, idx) => {
									return <Sensor
										sensor={x}
										openSensorSettingDialog={openSensorSettingDialog(
											x,
											// idx == 0 ? true : false,
											props.deviceIdx,
											idx
										)}
										openCameraDialog={openCameraDialog}
									/>
								})
							) : (
								<Box
									mt="70px"
									display="flex"
									justifyContent="center"
									alignItems="center"
									color="#CECFD0"
								>
									{t("device page:No sensors found")}
								</Box>
							)
						) : deviceData.paymentStatus === 1 ? (
							<Box mt="70px" display="flex" justifyContent="center" alignItems="center" color="#CECFD0">
								&nbsp;
								{t("device page:The device is inactive")}
							</Box>
						) : (
							<Box mt="70px" display="flex" justifyContent="center" alignItems="center" color="#CECFD0">
								&nbsp;
								{t("device page:No information from device")}
								&nbsp;
							</Box>
						)}
					</CardContent>

					<Box display="flex" justifyContent="space-between" alignItems="flex-end" pb={1} px={2}>
						<div>
							<Box fontSize="13px">
								{checkDeviceReceivesInformation && deviceData.paymentStatus !== 1 && (
									<>
										<Box
											className={`text-success ${deviceData.temperaturStatus === 2 ? "text-danger" : ""
												}`}
										>
											&nbsp;
											{deviceData.temperaturStatus === 2
												? t("device page:Critical temperature")
												: t("device page:Normal temperature")}
											&nbsp;
										</Box>

										<Box
											className={`text-success ${deviceData.hummidityStatus === 2 ? "text-danger" : ""
												}`}
										>
											&nbsp;
											{deviceData.humidityStatus === 2
												? t("device page:Critical humidity")
												: t("device page:Normal humidity")}
											&nbsp;
										</Box>
									</>
								)}
							</Box>
							<Box display="flex" className="text-muted" marginTop={1} fontSize="11px">
								{t("device page:Last update")}:
								{deviceData.paymentStatus === 1 ? (
									<div>
										&nbsp;
										<span>{moment(deviceData.deactivatedDt).format("DD-MM-YYYY HH:mm:ss")}</span>
										&nbsp;
									</div>
								) : deviceData.lastSentDate ? (
									<>
										<div>
											&nbsp;
											<span>{moment(deviceData.lastSentDate).format("DD-MM-YYYY")}</span>
											&nbsp;
										</div>
										<div>
											&nbsp;
											<span>{moment(deviceData.lastSentDate).format("HH:mm:ss")}</span>
										</div>
									</>
								) : (
									<div>
										&nbsp;
										{t("device page:No information")}
									</div>
								)}
							</Box>
						</div>
						<Tooltip title={t("device page:Add new device")}>
							<MyFab disabled size="small" color="primary" onClick={handleAddSensorMenuClick}>
								<AddIcon />
							</MyFab>
						</Tooltip>
						<Menu
							id={`add-sensor-menu-${deviceData.deviceId}`}
							anchorEl={anchorSensor}
							open={Boolean(anchorSensor)}
							onClose={handleAddSensorMenuClose}
							className="positionMenuItemForDeviceCard"
						>
							<MenuItemForDevice onClick={openAddSensorDialog(constants.sensorTypes.airTempHum)}>
								<img width="16" src={sensorIcon} />
								{t("device page:Add sensor")}
							</MenuItemForDevice>
							<MenuItemForDevice onClick={openAddSensorDialog(constants.sensorTypes.camera)}>
								<img width="16" src={videoCameraIcon} />
								{t("device page:Add camera")}
							</MenuItemForDevice>
						</Menu>
					</Box>
				</MyCard>
			</Grid>
		</>
	);
};

const MenuItemForDevice = styled(MenuItem)`
	margin-left: 30px;
	margin-right: 30px;
	border-bottom: 1px solid ${colors.grayLightNineth};
	padding-top: 10px;
	padding-bottom: 10px;
	color: ${colors.grayLightSixth};
	:first-child {
		margin-top: 10px;
	}
	:last-child {
		margin-bottom: 10px;
		border-bottom: none;
	}
	img {
		margin-right: 10px;
	}
`;

const PercentText = styled("span")`
	font-size: ${(prop) => (prop.fontSize ? prop.fontSize : "0.9rem;")};
	color: white;
	display: flex;
	justify-content: center;
`;

export default React.memo(DeviceCard);

export let ButtonStyled = styled(Button).attrs({
	classes: { root: "root", contained: "contained", disabled: "disabled" },
})`
	&.root {
	}

	&.contained {
		text-transform: none;
		background-color: ${(prop) => {
		return prop.backgroundColor;
	}};
		transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		padding-left: 6px;
		padding-right: 6px;
		padding-top: 4px;
		padding-bottom: 4px;
	}
`;

export let IconButtonStyled = styled(IconButton).attrs({
	classes: { root: "root" },
})`
	&.root {
		transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		font-size: ${(prop) => (prop.fontSize ? prop.fontSize : "4rem")};
		color: ${(prop) =>
		prop.color === "primary"
			? prop.theme.activeTheme.IconButton.primaryColor
			: prop.theme.activeTheme.IconButton.color};
		padding: 6px;
	}
	&.root:hover {
		font-size: ${(prop) => (prop.fontSize ? prop.fontSize : "4rem")};
		color: ${(prop) =>
		prop.color === "primary"
			? prop.theme.activeTheme.IconButton.primaryColorHovered
			: prop.theme.activeTheme.IconButton.colorHovered};
	}
`;
